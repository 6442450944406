import { Box, Typography } from "@mui/material";
import { Oopps } from "@platformx/shared/static-assets";

const NoDataFound = ({ message }) => {
  return (
    <Box className='nodataOrError'>
      <Typography variant='h5regular' className='title'>
        {message}
      </Typography>
      <img src={Oopps} alt='no data found' />
    </Box>
  );
};

export default NoDataFound;
