import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHorizIcon } from "@platformx/shared/static-assets";
import { TitleSubTitle } from "@platformx/utilities";
import { useEffect, useState } from "react";
import SelectPageList from "./SelectPageList";

const PagePicker = ({ state, setState, field }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPage, setOpenPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState<any>(null);
  const open = Boolean(anchorEl);
  const handleEditClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBackPage = () => {
    setOpenPage(false);
    handleClose();
  };

  const onSelectPage = (page) => {
    setSelectedPage(page);
  };

  const onClickPageDone = () => {
    setOpenPage(false);
    handleClose();
    setState({
      ...state,
      [field.name]: selectedPage,
    });
  };

  const onOpenPage = () => {
    setOpenPage(true);
  };

  const onEditItem = () => {
    setOpenPage(true);
  };
  const onDeleteItem = () => {
    setSelectedPage(null);
    setState({
      ...state,
      [field.name]: "",
    });
  };

  useEffect(() => {
    if (state?.servicepage !== "") {
      setSelectedPage(state?.servicepage);
    }
  }, [state]);

  return (
    <>
      {selectedPage ? (
        <Box
          className='category-container'
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            border: "solid 1px #ced3d9",
            borderRadius: "5px",
            padding: "13px 14px",
            width: "100%",
          }}>
          <Box>
            <TitleSubTitle
              title={selectedPage?.Title}
              subTitle=''
              titleVariant='p4regular'
              subTitleVariant='h6regular'
            />
          </Box>
          <Box>
            <IconButton
              onClick={handleEditClick}
              data-testid='page-click'
              sx={{ width: "32px", height: "32px" }}>
              <img
                src={MoreHorizIcon}
                style={{ objectFit: "cover", transform: "rotate(90deg)" }}
                alt='more'
              />
            </IconButton>
            <Menu
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                ".Platform-x-Menu-paper": {
                  boxShadow: "0 3px 7px 0 rgba(0, 0, 0, 0.03)",
                  borderRadius: "7px",
                  marginTop: "5px",
                },
                ".Platform-x-Menu-list": {
                  borderRadius: "4px",
                  boxShadow: "0 0 2px 0 rgba(115, 114, 114, 0.14)",
                  border: "solid 1px rgba(112, 112, 112, 0.1)",
                },
                ".Platform-x-MenuItem-root": {
                  ".Platform-x-SvgIcon-root": {
                    fontSize: 20,
                    marginRight: "10px",
                  },
                  paddingLeft: "18px",
                  fontSize: "16px",
                  zIndex: 999,
                },
                textTransform: "capitalize",
              }}>
              <MenuItem onClick={onEditItem}>
                <EditIcon /> Edit
              </MenuItem>
              <MenuItem onClick={onDeleteItem}>
                <DeleteIcon /> Delete
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      ) : (
        <Button variant='primaryButton' onClick={onOpenPage}>
          {field?.placeholder}
        </Button>
      )}

      {openPage ? (
        <SelectPageList
          handleBack={handleBackPage}
          selectedPage={selectedPage}
          onClickDone={onClickPageDone}
          onSelectPage={onSelectPage}
        />
      ) : null}
    </>
  );
};

export default PagePicker;
