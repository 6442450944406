import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TagIcon from "@mui/icons-material/Tag";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getMyStoryContentListApiCall } from "@platformx/utilities";
import { useEffect, useState } from "react";
import XCard5 from "../../components/Cards/XCard5/XCard5";
import { useCustomStyle } from "./MyStory.style";
import MyStorySkeleton from "./MyStorySkelaton";

const MyStory = ({ content, authoringHelper, secondaryArgs = {}, showTags = false }: any) => {
  const classes = useCustomStyle();
  const [loading, setLoading] = useState(false);
  const [contentList, setContentList] = useState<any[]>([]);
  const [start, setStart] = useState(0);
  const [hasNext, setHasNext] = useState(false); // New state to handle the next button

  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up("md"));
  const isEM = useMediaQuery(theme.breakpoints.up("em"));

  const numberOfRows = isEM ? 3 : isMD ? 2 : 1;

  const getContentList = async () => {
    setLoading(true);
    try {
      const contentListFromAPI = await getMyStoryContentListApiCall({
        params: content?.QueryParam,
        secondaryArgs: secondaryArgs,
        start,
        numberOfRows,
      });
      if (contentListFromAPI?.length > 0) {
        setContentList(contentListFromAPI);
        // Check if there are more items beyond the current batch
        setHasNext(contentListFromAPI?.length === numberOfRows);
      } else {
        setContentList([]);
        setHasNext(false);
      }
    } catch (err: any) {
      setContentList([]);
      setHasNext(false);
    } finally {
      setLoading(false);
    }
  };

  const getNextList = () => {
    if (hasNext) {
      setStart((prevState) => prevState + numberOfRows);
    }
  };

  const getPreviousList = () => {
    if (start !== 0) {
      setStart((prevState) => prevState - numberOfRows);
    }
  };

  useEffect(() => {
    getContentList();
  }, [start, content?.QueryParam]);

  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.dynamicPrelemWithCarousel2} dynamicPrelemWithCarousel2Wrapper`}>
      <Container
        className={
          authoringHelper?.isEditPage ? `grid_full_width prelem-py` : `grid_container prelem-py`
        }>
        <Box className='textWrapper'>
          <Typography variant='h2medium' id='Title' color='tertiaryTitle'>
            {content?.Title}
          </Typography>
          {showTags ? (
            <IconButton
              onClick={secondaryArgs?.handleOpenModal}
              size='medium'
              className='rpitagpopup'
              aria-label='RPI Tag Popup'>
              <TagIcon htmlColor='#000000' />
            </IconButton>
          ) : null}
          <Box className='navigationWrapper'>
            <ArrowBackIcon
              onClick={getPreviousList}
              className={`${start === 0 ? "arrowIconsDisabled" : ""} arrowIcons`}
              sx={{
                pointerEvents: start === 0 ? "none" : "",
                cursor: "pointer",
              }}
            />
            <ArrowForwardIcon
              onClick={getNextList}
              className={`${!hasNext ? "arrowIconsDisabled" : ""} arrowIcons`}
              sx={{
                pointerEvents: !hasNext ? "none" : "",
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
        <Grid
          container
          sx={{
            margin: "8px 0 0 0px",
            position: "relative",
            "&:hover": {
              ".add-content-overlay": {
                display: authoringHelper?.authoringHoverShow ? "flex !important" : "none",
              },
            },
          }}>
          {loading ? (
            <MyStorySkeleton count={numberOfRows} />
          ) : contentList.length > 0 ? (
            <Box sx={{ marginLeft: "-15px", marginRight: "-15px", width: "calc(100% + 30px)" }}>
              <Grid container>
                {contentList.map((item: any, index: any) => {
                  return (
                    <Grid item xs={12} md={6} lg={4} px='15px' key={index}>
                      <XCard5 content={item} secondaryArgs={secondaryArgs} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ) : (
            <Grid container justifyContent='center' alignItems='center'>
              <Typography variant='h6' color='textSecondary'>
                Data Not Found
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default MyStory;
