import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import "./Confirmation.css";
import { useCustomStyle } from "./Confirmation.style";

type ConfirmationProps = {
  img?: string;
  text?: string;
  title?: string;
  open?: boolean;
  buttonOne?: string;
  buttonTwo?: string;
  width?: string;
  handleClose?: (e?: any) => void;
  buttonOneFunc?: (e?: any) => void;
  buttonTwoFunc?: (e?: any) => void;
};
export default function Confirmation(props: ConfirmationProps) {
  const {
    img = "",
    text = "",
    title = "",
    open = false,
    buttonOne = "",
    buttonTwo = "",
    handleClose = () => {},
    buttonOneFunc = () => {},
    buttonTwoFunc = () => {},
    width = "800px",
  } = props;

  const classes = useCustomStyle();
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        "& .Platform-x-Dialog-paper": {
          maxWidth: `${width} !important`,
          width: "100%",
        },
      }}
      className={`order-confirmation-wrapper ${classes.dialogWrapper} orderConfirmationPlaced`}>
      <Box className='modal-close'>
        <CloseIcon
          onClick={handleClose}
          data-testid='confirmation-close'
          className={`close-icon closeIcon`}
        />
      </Box>
      {img && (
        <Box className='closeWrapper'>
          <img src={img} alt='Delete Icon' width={120} />
        </Box>
      )}

      {title && (
        <Box
          className='dialogTitle'
          id='alert-dialog-title'
          sx={{ marginTop: img ? "0px" : "20px" }}>
          <Typography variant='h2medium'>{title}</Typography>
        </Box>
      )}

      {text && (
        <DialogContent className='dialogContent'>
          <Box className='dialogContentText' id='alert-dialog-description'>
            <Typography variant='p3regular'>{text}</Typography>
          </Box>
        </DialogContent>
      )}

      <Box style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            {buttonOne && (
              <Button
                variant='primaryButton2'
                onClick={buttonOneFunc}
                data-testid='confirmation-btn1'>
                {buttonOne}
              </Button>
            )}
            {buttonTwo && (
              <Button
                variant='primaryButton1'
                onClick={buttonTwoFunc}
                data-testid='confirmation-btn2'>
                {buttonTwo}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
