import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatTimestamp, getFullAddress } from "../../helperEcommerce";
import { useCustomStyle } from "./OrderHighlights.style";

const OrderHighlights = ({ orderDetail }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();

  const url = new URL(window.location.href);
  const orderNumber = url.searchParams.get("order_number");

  const orderData = [
    { name: t("order_number"), value: `#${orderNumber}` || "-" },
    { name: t("order_date"), value: formatTimestamp(orderDetail?.lastModifiedAt) || "N/A" },
    // { name: t("delivery_date"), value: "NA" },
    { name: t("email1"), value: orderDetail?.shippingAddress?.email || "NA" },
    {
      name: t("delivery_address"),
      value: getFullAddress(orderDetail?.shippingAddress) || "NA",
    },
    { name: t("mode_of_payment"), value: "COD" },
  ];

  return (
    <Box className={`${classes.orderHighlights} orderHighlightsWrapper`}>
      <Box>
        <Grid container>
          {orderData?.map((column, index) => {
            const isFirstThree = index < 3;
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                em={isFirstThree ? 2 : 3}
                lg={isFirstThree ? 2 : 3}
                key={index}>
                <Box className={index + 1 !== orderData?.length ? "seprator gap" : "gap"}>
                  <Typography variant='labelregular' className='noPadding'>
                    {column.name}
                  </Typography>
                  <Typography variant='h6bold' className='noMargin highlightValue'>
                    {column.value}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderHighlights;
