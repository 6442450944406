import { Box, Container, Grid, Typography } from "@mui/material";
import { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import prelemTypes from "../../globalStyle";
import "../../Style.css";
import { useCustomStyle } from "./LivestreamFeed.style";

interface IVideoContent {
  name?: string;
}

const LivestreamFeed = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const BrightcovePlayer: any = lazy(
    () => import("../../components/BrightcovePlayer/BrightcovePlayer"),
  );
  const [videoContent, setVideoContent] = useState<IVideoContent>({});
  const firstRender = useRef(true);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const defaultStructureData = () => {
    let livestreamFeedStructureData;
    try {
      livestreamFeedStructureData = {
        "@context": "https://schema.org/",
        "@type": "VideoObject",
        name: videoContent?.name,
        publication: {
          "@type": "BroadcastEvent",
          name: "X broadcast",
          isLiveBroadcast: true,
        },
      };
    } catch (e) {
      livestreamFeedStructureData = {};
    }

    return livestreamFeedStructureData;
  };
  const generateStructureData = () => {
    let livestreamFeedStructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current === true) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        livestreamFeedStructureData = JSON.parse(tempSD);
      } else {
        livestreamFeedStructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      livestreamFeedStructureData = defaultStructureData();
    }
    return livestreamFeedStructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = generateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoContent?.name]);

  usePrelemImpression(analytics, inView, secondaryArgs);
  /* AnalyticsEnabled dependency added as many times we are not getting analytics provider*async call)
  1. we are first checking in publish app if analytics provider is avaiable or not
  2. if its available we are setting AnalyticsEnabled to true
  3. if its not available we are setting false
  */
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.LivestreamFeedWrapper} ${globalClasses.prelemType1} prelem prelemType1 outer-row LivestreamFeed`}>
      <Container
        className={
          authoringHelper?.isEditPage ? `grid_full_width prelem-py` : `grid_container prelem-py`
        }
        ref={ref}>
        <Grid container className='LivestreamWrapper' ref={ref}>
          <Grid xs={12} sm={12} md={6}>
            <Box className='LivestreanLeft'>
              <Suspense fallback={"Loading..."}>
                {typeof window !== "undefined" &&
                  content?.VideoID &&
                  content?.PlayerID &&
                  content?.AccountID && (
                    <BrightcovePlayer
                      VideoData={{
                        VideoID: content?.VideoID,
                        PlayerID: content?.PlayerID,
                        AccountID: content?.AccountID,
                      }}
                      setVideoContent={setVideoContent}
                      analytics={analytics}
                      prelemTitle='Livestream Feed'
                      analyticsEnable={analytics?.isAnalyticsEnabled}
                      secondaryArgs={secondaryArgs}
                    />
                  )}
              </Suspense>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Box className='LivestreanRight'>
              <Typography variant='h2semibold' id={"Title"}>
                {content?.Title}
              </Typography>
              <Typography variant='p3regular' id={"Description"}>
                {content?.Description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default LivestreamFeed;
