/* eslint-disable @typescript-eslint/no-empty-function */
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Modal, Typography } from "@mui/material";
import { formCroppedUrlString, getFormattedImageUrl } from "@platformx/utilities";

import React from "react";
import "../../Style.css";
import Slider from "../Slider/Slider";
import { useCustomStyle } from "./CustomModalSlider.style";

const CustomModalSlider = ({
  sliderData,
  indexPos,
  handleClose,
  openModal,
  secondaryArgs,
}: ModalSliderProps) => {
  const classes = useCustomStyle();
  const thumbImg =
    sliderData &&
    sliderData.map((item: any) => {
      return formCroppedUrlString(
        secondaryArgs?.gcpUrl,
        secondaryArgs?.bucketName,
        item.Thumbnail ? item.Thumbnail : item.Url,
        item.ext,
      ).src;
    });
  const settings = {
    sliderName: ".slider-nav",
    infinite: true,
    initialIndex: indexPos ? (indexPos > 0 ? indexPos : 0) : 0,
    showThumb: true,
  };
  return (
    <Box>
      <Modal hideBackdrop open={openModal}>
        <Box
          className={`${classes.customModalSliderWrapper} customModalSliderWrapperBg image-video-gallery-modal-wrapper overlayModal`}>
          <Slider {...settings} thumbImg={thumbImg} sliderName='slider-container'>
            {sliderData?.map((slide: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  {!slide?.Thumbnail ? (
                    <Box className='slider-container-wrapper'>
                      <Typography variant='h3regular' className='oneLineEllipsis' color='textColor'>
                        {slide?.Title}
                      </Typography>
                      {/* <img
                        alt='gallery1'
                        className='img'
                        src={getFormattedImageUrl(slide?.Url, slide?.ext, secondaryArgs)}
                      /> */}
                      <img
                        alt='gallery1'
                        src={getFormattedImageUrl(slide?.Url, slide?.ext, secondaryArgs)}
                      />
                    </Box>
                  ) : (
                    <Box className='slider-container-wrapper'>
                      <Typography variant='h3regular' className='oneLineEllipsis' color='textColor'>
                        {slide?.Title}
                      </Typography>
                      <Typography>VideoPlayer has been commented</Typography>
                      {/* <VideoPlayer
                        playerProp={{
                          posterImg: getFormattedImageUrl(
                            slide?.Thumbnail,
                            slide?.ext,
                            secondaryArgs,
                          ),
                          videoUrl: slide.Url,
                          classname: "react-player-wrap img",
                        }}
                      /> */}
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
          </Slider>
          <Button
            onClick={handleClose}
            data-testid='custom-modal-slider-close'
            className='closeButton'>
            <ClearIcon />
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

interface SecondaryArgs {
  prelemBaseEndpoint?: PrelemBaseEndpoint;
  gcpUrl?: string;
  bucketName?: string;
}
interface PrelemBaseEndpoint {
  device?: string;
}
interface ModalSliderProps {
  openModal?: any;
  indexPos?: number;
  sliderData?: any;
  contentType?: string;
  handleClose?: () => void;
  secondaryArgs?: SecondaryArgs;
}

export default CustomModalSlider;
