import { Box, Grid, Skeleton } from "@mui/material";

const CarousalSkeltonLoader = () => {
  return (
    <Grid container spacing={2} justifyContent='center'>
      {Array.from({ length: 4 }).map((_, index) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            display: {
              xs: index === 0 ? "block" : "none",
              sm: index === 1 || index === 2 ? "block" : "none",
              md: index === 1 || index === 2 || index === 3 ? "block" : "none",
              lg: "block",
            },
          }}
          className='slidesCard'>
          <Box className='image-container'>
            <Box className='imgWrapper'></Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Skeleton width='80%' height={30} />
            <Skeleton width='60%' height={30} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CarousalSkeltonLoader;
