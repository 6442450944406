import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";
import { RectangleBgIcon } from "@platformx/shared/static-assets";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    profileBannerSection: {
      "&.profileBannerWrapper": {
        background: `url(${RectangleBgIcon}) left center no-repeat, linear-gradient(82.84deg, #572AC2 1.73%, #7747D5 27.13%, #4080F5 58.6%, #7ABEF7 107.74%)`,
        alignItems: "center",
        backgroundPosition: "top center",
        padding: "10px",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
          padding: "20px",
        },
        [theme.breakpoints.up("lg")]: {
          backgroundPosition: "top left",
          display: "flex",
          minHeight: "310px",
          height: "310px",
          padding: 0,
          textAlign: "left",
        },
        "& .leftImg": {
          height: "100%",
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "end",
          // transform: `translate(50%, 10px)`,
          [theme.breakpoints.up("lg")]: {
            width: "340px",
          },
          "& img": {
            width: "100%",
            height: "291px",
            // position: "absolute",
            // bottom: 0,
            [theme.breakpoints.up("lg")]: {
              maxWidth: "270px",
              height: "auto",
            },
          },
        },
        "& .rightPanel": {
          flexGrow: 1,
          height: "100%",
          [theme.breakpoints.up("lg")]: {
            padding: "0 24px",
            paddingLeft: 0,
            width: `calc(100% - 340px)`,
            height: "100%",
          },
          "& .keyHighlight": {
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            scrollbarWidth: "none",
            cursor: { xs: "move", em: "default" },
            "&::-webkit-scrollbar": {
              display: "none",
            },
            [theme.breakpoints.up("lg")]: {
              textAlign: "left",
            },
            [theme.breakpoints.between("sm", "lg")]: {
              width: "90%",
              margin: "16px auto",
            },
            "& .columnItem": {
              borderRight: `1px solid ${theme.palette.textColor}`,
              marginRight: "15px",
              paddingLeft: 0,
              minWidth: "100px",
              "&:last-child": {
                borderRight: "none",
                marginRight: 0,
              },
            },
          },
        },
      },
    },
  };
});
