import { Box, Button, Typography } from "@mui/material";
import { useCustomStyle } from "./InfoCard.style";

const InfoCard = ({ secondaryArgs }) => {
  const classes = useCustomStyle();
  const navigateToPage = () => {
    const page = JSON?.parse(secondaryArgs?.globalSettings?.loyalty_explained_cta) || null;
    if (page)
      window.open(`/${secondaryArgs?.prelemBaseEndpoint?.language || "en"}/${page?.Page}`, "_self");
  };
  return (
    <Box className={`${classes.infoWrapper} infoWrapperSection`}>
      <Box className='headingWrapper'>
        <Box className='title'>
          <Typography variant='h3regular' id='Title1'>
            How It Works
          </Typography>
        </Box>
      </Box>
      <Box className='paragraphWrapper'>
        <Typography variant='p3regular' id='Description'>
          {secondaryArgs?.globalSettings?.loyalty_explained_text}
        </Typography>
      </Box>
      {secondaryArgs?.globalSettings?.loyalty_explained_cta ? (
        <Button variant='primaryButton1' className='button' onClick={navigateToPage}>
          Learn More
        </Button>
      ) : null}
    </Box>
  );
};

export default InfoCard;
