import { Skeleton } from "@mui/material";

const AvailablePointsSeleton = () => {
  return (
    <Skeleton
      variant='rectangular'
      width='100%'
      height={120}
      sx={{ marginBottom: 2, marginTop: 2, borderRadius: "8px" }}
    />
  );
};

export default AvailablePointsSeleton;
