import { Box, Button, Grid, Typography } from "@mui/material";
import { PlatFormXDateTimeFormat } from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import Slider from "../../../Slider/Slider";
import { useCustomStyle } from "./OrderCard.style";

const OrderCard = ({ order, clickHandler, secondaryArgs }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();

  const ShowDetail = () => {
    clickHandler(true);
  };

  const { orderNumber = "", createdAt = "", id = "" } = order || {};

  const sliderSettings = {
    speed: 6000,
    mobileFirst: true,
    arrows: false,
    dots: false,
    autoplay: true,
    infinite: true,
  };

  const onCardClick = () => {
    window.location.href = `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/ecommerce/order-success?order_number=${orderNumber}&order_id=${id}`;
  };

  return (
    <Box className={`${classes.OrderCardWrapper} OrderCardWrapperSection`} onClick={onCardClick}>
      <Box className='cardheader' sx={{ display: "flex" }}>
        <Grid item>
          <Typography variant='p3medium' color='tertiaryTitle' className='noMargin productOrder'>
            Order
            <Typography
              variant='p3medium'
              color='tertiaryTitle'
              component='span'
              className='noMargin OrderNumber'>
              {`#${orderNumber}`}
            </Typography>
          </Typography>
          <Typography variant='p4regular' className='labelColor itemStatus'>
            {PlatFormXDateTimeFormat(createdAt)}
            <Typography
              variant='p4regular'
              color='tertiaryTitle'
              component='span'
              className='noMargin Productstatus'>
              {` | ${order?.lineItem?.length} ${order?.lineItem?.length > 1 ? "items" : "item"} in this order`}
            </Typography>
          </Typography>
        </Grid>
        {/* <Grid item xs sx={{ textAlign: "right", alignSelf: "center" }}>
          <Typography variant='h4bold' className='noTopMargin' sx={{ textAlign: "right" }}>
            $304.00
          </Typography>
        </Grid> */}
      </Box>
      <Box className='cardDetail'>
        <Slider {...sliderSettings}>
          {order?.lineItem?.map((item, index) => (
            <Box key={index} sx={{ display: "flex", width: "100%", margin: "0 40px" }}>
              <Grid item>
                <Box className='orderedProduct'>
                  <img src={item.lineItemImageUrl} alt='product' style={{ maxWidth: "100%" }} />
                </Box>
              </Grid>
              <Grid item xs>
                <Box sx={{ marginLeft: 2 }}>
                  <Typography
                    variant='p3medium'
                    className='noTopMargin lessGap'
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      width: "50%",
                    }}>
                    {item?.name}
                  </Typography>
                  {/* <Typography variant='p4regular' className='noTopMargin lessGap labelColor'>
                  Size: 37 | Color: Beige
                </Typography> */}
                  <Typography variant='p4regular' className='noTopMargin lessGap labelColor'>
                    {`Quantity: ${item?.quantity}`}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          ))}
        </Slider>
        <Grid item className='buttonWrapper'>
          <Box className='orderActionColumn'>
            <Button
              variant='tertiaryButton1'
              onClick={ShowDetail}
              className='sm'
              type='button'
              sx={{ width: "100%" }}>
              {t("track_order")}
            </Button>
            <Button
              variant='tertiaryButton2'
              className='sm'
              disabled={true}
              type='button'
              sx={{ width: "100%", marginBottom: 0 }}>
              {t("download_invoice")}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderCard;
