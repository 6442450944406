import { Box, Button, Grid, Typography } from "@mui/material";
import { PagesListIcon, PublishedStatusIcon } from "@platformx/shared/static-assets";
import { PlatFormXDateTimeFormat } from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import { useStyles } from "./PageList.styles";

const PageList = ({ item, index, onSelectPage, selectedPage }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isSelected = selectedPage?.CurrentPageUrl === item?.CurrentPageUrl;
  return (
    <Grid key={index} container className={classes.cardContainer}>
      <Grid item sm={5} em={6} lg={7.5} className={classes.item1Container}>
        <Box className={classes.imgContainer}>
          <img src={PagesListIcon} alt='Page Icon' />
        </Box>
        <Box className={classes.titleContainer}>
          <Typography variant='h5medium' className={classes.ellipsisText}>
            {item?.Title}
          </Typography>
          {/* <Typography variant='p3regular' className={classes.ellipsisText}>
            {article?.Description}
          </Typography> */}
        </Box>
      </Grid>
      <Grid item sm={1} className={classes.publishIcon}>
        <img src={PublishedStatusIcon} alt='publish' />
      </Grid>
      <Grid item sm={3} em={3} lg={2} className={classes.userContainer}>
        <Box>
          <Typography className={classes.ellipsisText} variant='p4regular'>
            {item.LastModifiedBy}
          </Typography>
          <Typography variant='p4regular'>
            {PlatFormXDateTimeFormat(item?.LastModificationDate)}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={3} em={2} lg={1.5} className={classes.buttonContainer}>
        <Button
          variant={isSelected ? "primaryButton" : "secondaryButton"}
          className='sm'
          disableElevation
          onClick={() => onSelectPage(item)}
          data-testid='menuPage'>
          {isSelected ? t("selected") : t("select")}
        </Button>
      </Grid>
    </Grid>
  );
};
export default PageList;
