import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    challangesWrapper: {
      "&.challangesWrapperSection": {
        background: theme.palette.prelemType1.BACKGROUND,
        border: `solid 1px ${theme.palette.prelemType1.LINE}`,
        height: "415px",
        "& .challangesList": {
          height: "330px",
          overflowX: "auto",
        },
        "& .challangeItem": {
          marginBottom: "12px",
          cursor: "pointer",
          "&:last-child": {
            marginBottom: 0,
          },
        },
        "& .challangeImage": {
          background: "#ccc",
          width: "68px",
          height: "68px",
          // "& img": {
          //   width: 100,
          //   height: 100,
          // },
        },
        "& .customchip": {
          height: "25px",
          cursor: "pointer",
          marginBottom: "8px",
          "& span": {
            paddingLeft: 0,
          },
          "& .coinImage": {
            width: "18px",
            height: "18px",
            marginLeft: "6px",
            marginRight: "3px",
          },
          "& .offerImage": {
            marginLeft: "8px",
            marginRight: "5px",
          },
          "& .couponImage": {
            marginLeft: "8px",
            marginRight: "5px",
            minHeight: "12px",
          },
        },
      },
      "& .noresultFound": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "100%",
        "& .message": {
          textAlign: "center",
        },
      },
    },
  };
});
