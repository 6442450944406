import { Box, Typography } from "@mui/material";
import { useCustomStyle } from "./PaymentDetail.style";

const PaymentDetail = () => {
  const classes = useCustomStyle();
  return (
    <Box className={`${classes.paymentDetailWrapper} paymentDetailSection`}>
      <Box className='paymentDetailCard'>
        <Typography variant='h5regular' className='noTopMargin'>
          Payment Info
        </Typography>
        <Box className='paymentDetailInfo'>
          <Box className='rowItem'>
            <Typography
              variant='p4regular'
              component='span'
              sx={{ width: "80%" }}
              className='noMargin labelColor'>
              Payment Method :
            </Typography>
            <Typography variant='h6regular' component='span' className='noMargin'>
              Visa ending in 1700
            </Typography>
          </Box>

          <Box className='rowItem'>
            <Typography
              variant='p4regular'
              component='span'
              className='noMargin labelColor'
              sx={{ width: "80%" }}>
              Card Type :
            </Typography>
            <Typography variant='h6regular' component='span' className='noMargin'>
              Credit Card
            </Typography>
          </Box>
          <Box className='rowItem'>
            <Typography
              variant='p4regular'
              component='span'
              className='noMargin labelColor'
              sx={{ width: "80%" }}>
              Transaction ID :
            </Typography>
            <Typography variant='h6regular' component='span' className='noMargin'>
              MMKDSKFKKK1212121212
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentDetail;
