import { CouponOff } from "@platformx/shared/static-assets";

const OfferCard = ({ imageUrl }) => {
  return imageUrl ? (
    <img src={imageUrl} alt='coupoan' className='coupanImage' />
  ) : (
    <img src={CouponOff} alt='coupon' className='coupanImage' style={{ width: "70px" }} />
  );
};

export default OfferCard;
