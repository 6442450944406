import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MobileMenuIconSvg, SearchBlackSvg, SearchIconSvg } from "@platformx/shared/static-assets";
import { useState } from "react";
import ExpiringRewards from "../Component/RewardCards/ExpiringReward/ExpiringReward";
import { FILTER_OPTION, REWARDS_TABS } from "../helperProfile";
import AvailableRewardContainer from "./AvailableRewardsContainer";
import MyRewardsContainer from "./MyRewardsContainer";
import { useCustomStyle } from "./Rewards.style";

const Rewards = ({ secondaryArgs, profile }) => {
  const classes = useCustomStyle();
  const [value, setValue] = useState("1");
  const [searchVisible, setSearchVisible] = useState(false); // To toggle search input
  const [searchTerm, setSearchTerm] = useState(""); // Store the search query
  const [searchText, setSearchText] = useState("");
  const [filterVisible, setFilterVisible] = useState(false); // To toggle search input
  const [selectedFilter, setSelectedFilter] = useState("");
  const isMobile = useMediaQuery("(max-width:1023px)");

  const tabs = [
    { id: "1", label: REWARDS_TABS.AVAILABLE_REWARDS },
    { id: "2", label: REWARDS_TABS.MY_REWARDS },
    { id: "3", label: REWARDS_TABS.USED_REWARDS },
    { id: "4", label: REWARDS_TABS.EXPIRING_POINTS },
  ];
  const filter = [
    { id: "0", label: FILTER_OPTION.DEFAULT, value: "" },
    // { id: "1", label: FILTER_OPTION.LATEST, value: "latest" },
    // { id: "2", label: FILTER_OPTION.POPULAR, value: "popular" },
    { id: "3", label: FILTER_OPTION.ASCENDING, value: "asc" },
    { id: "4", label: FILTER_OPTION.DESCENDING, value: "desc" },
  ];

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const handleDropdownChange = (event) => {
    setValue(event.target.value);
  };

  // Toggle search field visibility
  const handleSearchIconClick = () => {
    setSearchVisible(!searchVisible);
  };

  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Clear the search input
  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchText("");
  };

  // Toggle filter field visibility
  const handlefilterIconClick = () => {
    setFilterVisible(!filterVisible);
  };
  const handleFilterClick = (filterValue) => {
    setSelectedFilter(filterValue);
    setFilterVisible(false);
  };

  return (
    <Box className={`${classes.RewardsWrapper} rewardsWrapperSection`}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {!isMobile ? (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='Rewards menus'
            variant='scrollable'
            scrollButtons='auto'
            className='rewardsMenu'>
            {tabs.map((tab) => (
              <Tab key={tab.id} label={tab.label} value={tab.id} className='rewardsMenuTab' />
            ))}
          </Tabs>
        ) : (
          <FormControl sx={{ width: "265px" }}>
            <Select
              value={value}
              onChange={handleDropdownChange}
              label='Rewards Menu'
              className='selectFilter'>
              {tabs.map((tab) => (
                <MenuItem key={tab.id} value={tab.id}>
                  {tab.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Search and Filter Icons */}
        <Box className='searchAndFilter' sx={{ display: value === "4" ? "none" : "flex" }}>
          <Box sx={{ position: "relative" }} className='searchSection'>
            <IconButton onClick={handleSearchIconClick} sx={{ width: "40px", height: "40px" }}>
              <img src={SearchBlackSvg} alt='Search Icon' />
              {searchText && <span className='filterApplied'></span>}
            </IconButton>
            {searchVisible && (
              <Box className='searchWrapper'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={searchTerm}
                  sx={{ textAlign: "left" }}
                  onChange={handleSearchChange}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setSearchText(searchTerm);
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIconSvg} alt='Search Icon' />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        {searchTerm && (
                          <ClearIcon onClick={handleClearSearch} sx={{ cursor: "pointer" }} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
          </Box>
          <Box className={`filterSection ${filterVisible ? "active" : ""}`}>
            <IconButton onClick={handlefilterIconClick} sx={{ width: "40px", height: "40px" }}>
              <img src={MobileMenuIconSvg} alt='filter Icon' />
              {selectedFilter && <span className='filterApplied'></span>}
            </IconButton>
            <Box className='filterOptions'>
              <List>
                {filter.map((filteritem) => (
                  <ListItem disablePadding key={filteritem?.id}>
                    <ListItemButton
                      sx={{ paddingTop: "2px", paddingBottom: "2px" }}
                      onClick={() => handleFilterClick(filteritem?.value)}>
                      <Typography
                        variant={selectedFilter === filteritem?.value ? "p3semibold" : "p3regular"}
                        sx={{
                          marginTop: "4px",
                          marginBottom: "4px",
                        }}>
                        {filteritem?.label}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Tab Content */}
      <Box className='rewardsDetails'>
        {value === "1" && (
          <AvailableRewardContainer
            secondaryArgs={secondaryArgs}
            memberId={profile?.member_id}
            searchText={searchText}
            filterText={selectedFilter}
          />
        )}
        {value === "2" && (
          <MyRewardsContainer
            secondaryArgs={secondaryArgs}
            memberId={profile?.member_id}
            type={REWARDS_TABS.MY_REWARDS}
            searchText={searchText}
            filterText={selectedFilter}
          />
        )}
        {value === "3" && (
          <MyRewardsContainer
            secondaryArgs={secondaryArgs}
            memberId={profile?.member_id}
            type={REWARDS_TABS.USED_REWARDS}
            searchText={searchText}
            filterText={selectedFilter}
          />
        )}
        {value === "4" && (
          <ExpiringRewards secondaryArgs={secondaryArgs} memberId={profile?.member_id} />
        )}
      </Box>
    </Box>
  );
};

export default Rewards;
