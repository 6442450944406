//ECOMERCE TRACKING CONSTANTS
const ADD_TO_CART = {
  eventName: "add_to_cart", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom add to cart Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};
const PRODUCT_VIEW = {
  eventName: "product_view", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom product view Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};
const CART_CHECKOUT = {
  eventName: "cart_checkout", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom cart checkout Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};
const SHIPPING_ADDRESS = {
  eventName: "shipping_address", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom shipping address Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};
const PAYMENT_DETAIAL = {
  eventName: "make_payment", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom payment Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};

//RPI TRACKING CONSTANTS
const RPI_TAG_SELECTION = {
  eventName: "button_click", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "selected tag for rpi", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};

//Rendering user profile update event
const USER_PROFILE_UPDATE = {
  eventName: "button_click", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "button_click", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};

const BRIGHTCOVE_VIDEO_DETAILS = {
  eventName: "video_tracking",
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "video_tracking", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};

const BRIGHTCOVE_VIDEO_ACTION = [
  "video_ad_start",
  "video_ad_complete",
  "video_play",
  "video_pause",
  "video_play_25",
  "video_play_50",
  "video_play_75",
  "video_stop",
  "video_complete",
];

const PAGE_VIEW_ACTION = {
  eventName: "page_view", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "page impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};

const ECOM = "ecom";
const CHANNEL = "web";
const BUTTON_CLICK = "button_click";
const MY_STORIES_FEED = "my_stories_feed";
const UPDATE_PROFILE = "update_profile";
export {
  ADD_TO_CART,
  BRIGHTCOVE_VIDEO_ACTION,
  BRIGHTCOVE_VIDEO_DETAILS,
  BUTTON_CLICK,
  CART_CHECKOUT,
  CHANNEL,
  ECOM,
  MY_STORIES_FEED,
  PAGE_VIEW_ACTION,
  PAYMENT_DETAIAL,
  PRODUCT_VIEW,
  RPI_TAG_SELECTION,
  SHIPPING_ADDRESS,
  UPDATE_PROFILE,
  USER_PROFILE_UPDATE,
};
