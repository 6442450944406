import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Divider, Typography } from "@mui/material";
import { PlatFormXDateTimeFormat } from "@platformx/utilities";
import { useEffect, useState } from "react";
import graphQlList from "../../graphql/fetch";
import ToastService from "../ToastContainer/ToastService";
import CouponBox from "./CouponBox";
import "./CouponPopup.css";
import { useCustomStyle } from "./CouponPopup.styles";
import CouponSkeleton from "./CouponSkeleton";

function CouponPopup({ memberId, handleClickClose, onCouponApply, secondaryArgs }: CouponProps) {
  const [open] = useState(true);
  const classes = useCustomStyle();
  const [coupons, setCoupons] = useState<any>([]);
  const [loading, setLoader] = useState(true);

  const ROWS = 40;

  const getCouponData = async (start = 0) => {
    try {
      const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "" } = {}, sitename } =
        secondaryArgs;
      const { data, errors } = await graphQlList?.fetchMyRewardAndUsedRewardData(
        {
          memberId: memberId, //"ee4ec084-c35d-4568-939d-d615684c1e81", // Replace with dynamic memberId if needed
          pagination: {
            start: start, // Set start to current page
            rows: ROWS, // Number of items per page
          },
          sort: "desc",
          filter: {
            search: "",
            status: "issued",
            reward_type: "static_coupon",
            category: secondaryArgs?.prelemBaseEndpoint?.olKey,
          },
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      const updatedCoupons = data?.users_getMemberIssuedRewardList?.rewardList.map((item) => {
        return {
          CouponCode: item?.issued_coupon?.coupon_code || "",
          Title: item?.reward_name || "",
          Description: "",
          Expiry: PlatFormXDateTimeFormat(item.issued_coupon.active_to),
          RewardId: item?.reward_id || "",
          Value: item?.issued_coupon?.value || 0,
          ValueType: item?.issued_coupon?.value_type || "",
          IssuedRewardId: item?.issued_reward_id || "",
        };
      });
      if (errors) {
        setCoupons([]);
      } else {
        // setCoupons((prevCoupon) => [...prevCoupon, ...updatedCoupons]);
        setCoupons(updatedCoupons);
      }
    } catch (err) {
      setCoupons([]);
      ToastService.failToast("Not able to load coupons at this momemnt.");
    } finally {
      setLoader(false);
    }
  };

  // const hasMore = coupons.length % ROWS > 0 ? false : true;
  // const loadMoreData = () => {
  //     const start = coupons?.length || 0;
  //     getCouponData(start);
  //   }
  // };

  useEffect(() => {
    getCouponData();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClickClose}
        fullWidth
        sx={{
          ".Platform-x-Dialog-paper": {
            maxWidth: { xs: "100%", sm: "50%", em: "33%" },
            minWidth: { xs: "100%", sm: "50%", em: "33%" },
            width: { xs: "100%", sm: "50%", em: "33%" },
            backgroundColor: "white",
            maxHeight: "100%",
            height: "100%",
            position: "fixed",
            top: 0,
            right: 0,
            boxShadow: "none",
            margin: "0px",
            borderRadius: "0px",
          },
        }}>
        <DialogContent className={`${classes.mainDivCouponPopup} mainDivPopup`}>
          {/* Content of your popup */}
          <Box className='ApplyCouponMainBox'>
            <Box className='ApplyCouponBox'>
              <Typography className='TypographyApplyCouponBox' variant='h3semibold'>
                Apply Coupon
              </Typography>
              <Box
                className='BoxCursorPointer'
                onClick={handleClickClose}
                data-testid='coupon-popup-close'>
                <CloseIcon />
              </Box>
            </Box>
            {/* <Box className='Textbox'>
              <Box className='TextFieldbox'>
                <TextBox placeholder={"Enter coupon code"} />
              </Box>

              <Box>
                <Button className='md' variant='primaryButton1'>
                  Apply
                </Button>
              </Box>
            </Box> */}
            <Divider sx={{ marginTop: "10px" }} className='dividerColor' />
            <Box
              className='scrollright Coupons'
              sx={{
                maxHeight: "85vh",
                minHeight: "85vh",
              }}>
              {loading ? <CouponSkeleton /> : null}

              {/* <InfiniteScroll
                dataLength={coupons?.length || 0} // Length of current rewards list
                next={loadMoreData} // Function to load more data
                hasMore={hasMore} // Whether there are more items to load
                loader={loading ? <CouponSkeleton /> : null} // Loader to show while loading
                // endMessage={<Typography variant='p3regular'> No more rewards to show</Typography>} // Message when all rewards are loaded
                scrollThreshold={0.7} // Trigger load more when 90% of the page is scrolled
                scrollableTarget='Coupons'> */}
              {coupons?.map((item: any, key: any) => {
                return (
                  <CouponBox
                    key={key}
                    CouponCode={item.CouponCode}
                    Title={item.Title}
                    Description={item.Description}
                    Description2={item.Description2}
                    Expiry={item.Expiry}
                    onCouponApply={() => onCouponApply(item)}
                  />
                );
              })}

              {coupons.length === 0 && !loading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "inherit",
                  }}>
                  <Typography variant='p3regular'>No Coupons available.</Typography>
                </Box>
              ) : null}
              {/* </InfiniteScroll> */}
            </Box>
          </Box>
          {/* bottom section
          <Divider />
          <Box className='BoxMaximumSavings'>
            <Box>
              <Typography className='TypographyMarginZero' variant='h6medium'>
                Maximum savings
              </Typography>
              <Typography className='TopBottomMarginZero' variant='p2semibold'>
                $299.00
              </Typography>
            </Box>
          </Box> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

interface CouponProps {
  memberId: string;
  handleClickClose: () => void;
  onCouponApply: (code) => void;
  secondaryArgs: any;
}

export default CouponPopup;
