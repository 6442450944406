import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
  Analytics,
  AuthoringHelper,
  SecondaryArgs,
  completeButtonUrl,
  formCroppedUrl,
} from "@platformx/utilities";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import "./Banner5.css";
import { useCustomStyle } from "./Banner5.style";
import Banner5Slide from "./Banner5Slide";

const Banner5 = ({ content, analytics, authoringHelper, secondaryArgs }: Banner5Props) => {
  const classes = useCustomStyle();
  const [currentSlide, setCurrentSlide] = useState(0);
  const isPlaying = !authoringHelper?.isEditing;
  const selectedOne = useRef(0);
  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };
  const ButtonDataObj1 = {
    Button_Name: content?.Button1_Name,
    Button_RedirectURL: content?.Button1_RedirectURL,
    Button_Type: content?.Button1_Type,
    Button_Value: content?.Button1_Value,
    Button_Action: content?.Button1_Action,
    Button_Content: content?.Button1_Content,
  };
  const ButtonObj2 = {
    Button_Name: "Button2_Name",
    Button_RedirectURL: "Button2_RedirectURL",
    Button_Type: "Button2_Type",
    Button_Value: "Button2_Value",
    Button_Action: "Button2_Action",
    Button_Content: "Button2_Content",
  };
  const ButtonDataObj2 = {
    Button_Name: content?.Button2_Name,
    Button_RedirectURL: content?.Button2_RedirectURL,
    Button_Type: content?.Button2_Type,
    Button_Value: content?.Button2_Value,
    Button_Action: content?.Button2_Action,
    Button_Content: content?.Button2_Content,
  };
  const ButtonObj3 = {
    Button_Name: "Button3_Name",
    Button_RedirectURL: "Button3_RedirectURL",
    Button_Type: "Button3_Type",
    Button_Value: "Button3_Value",
    Button_Action: "Button3_Action",
    Button_Content: "Button3_Content",
  };
  const ButtonDataObj3 = {
    Button_Name: content?.Button3_Name,
    Button_RedirectURL: content?.Button3_RedirectURL,
    Button_Type: content?.Button3_Type,
    Button_Value: content?.Button3_Value,
    Button_Action: content?.Button3_Action,
    Button_Content: content?.Button3_Content,
  };
  const ButtonObj4 = {
    Button_Name: "Button4_Name",
    Button_RedirectURL: "Button4_RedirectURL",
    Button_Type: "Button4_Type",
    Button_Value: "Button4_Value",
    Button_Action: "Button4_Action",
    Button_Content: "Button4_Content",
  };
  const ButtonDataObj4 = {
    Button_Name: content?.Button4_Name,
    Button_RedirectURL: content?.Button4_RedirectURL,
    Button_Type: content?.Button4_Type,
    Button_Value: content?.Button4_Value,
    Button_Action: content?.Button4_Action,
    Button_Content: content?.Button4_Content,
  };

  const firstRender = useRef(true);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const getImg = (index: number) => {
    const ImageCompound_index = `ImageCompound_${index}`;
    const { original_image_relative_path, ext }: any =
      (content?.ImageCompound && content?.ImageCompound[ImageCompound_index]?.original_image) || {};
    const img = formCroppedUrl(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    );
    return img;
  };
  const defaultStructureData = () => {
    let Banner5StructureData;
    try {
      Banner5StructureData = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement:
          content?.ImageCompound &&
          Object.entries(content?.ImageCompound).map(([,], index) => {
            return {
              "@type": "ListItem",
              position: index + 1,
              item: {
                "@type": "ImageObject",
                image: getImg(index + 1),
                description: content[`${"Title_" + (index + 1)}`],
                contenturl: completeButtonUrl(
                  content["Button" + (index + 1) + "_Action"],
                  content["Button" + (index + 1) + "_RedirectURL"],
                  secondaryArgs?.prelemBaseEndpoint?.buttonBaseUrl,
                ),
              },
            };
          }),
      };
    } catch (e) {
      Banner5StructureData = {};
    }

    return Banner5StructureData;
  };

  const generateStructureData = () => {
    let Banner5StructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current === true) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        Banner5StructureData = JSON.parse(tempSD);
      } else {
        Banner5StructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      Banner5StructureData = defaultStructureData();
    }
    return Banner5StructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = generateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  usePrelemImpression(analytics, inView, secondaryArgs);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isPlaying) {
        selectedOne.current = currentSlide;
        setCurrentSlide((currentSlide + 1) % Object.entries(content?.ImageCompound).length);
      }
    }, 7000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentSlide, isPlaying]);

  const handleSlideChange = (index: number) => {
    selectedOne.current = currentSlide;
    setCurrentSlide(index);
  };

  return (
    <div ref={authoringHelper?.innerRef}>
      <Box ref={ref} className={`banner5-slideshow ${classes.slideShowWrapper} banner5Bgwrapper`}>
        <Box className='slides prelem-py'>
          <Banner5Slide
            idfortitle='Title1'
            title={content?.Title1}
            img={content?.ImageCompound?.ImageCompound_1}
            analytics={analytics}
            authoringHelper={authoringHelper}
            secondaryArgs={secondaryArgs}
            ButtonObj={ButtonObj1}
            buttonDataObj={ButtonDataObj1}
            showSlide={currentSlide === 0}
          />
          <Banner5Slide
            idfortitle='Title2'
            title={content?.Title2}
            img={content?.ImageCompound?.ImageCompound_2}
            analytics={analytics}
            authoringHelper={authoringHelper}
            secondaryArgs={secondaryArgs}
            ButtonObj={ButtonObj2}
            buttonDataObj={ButtonDataObj2}
            showSlide={currentSlide === 1}
          />
          <Banner5Slide
            idfortitle='Title3'
            title={content?.Title3}
            img={content?.ImageCompound?.ImageCompound_3}
            analytics={analytics}
            authoringHelper={authoringHelper}
            secondaryArgs={secondaryArgs}
            ButtonObj={ButtonObj3}
            buttonDataObj={ButtonDataObj3}
            showSlide={currentSlide === 2}
          />
          <Banner5Slide
            idfortitle='Title4'
            title={content?.Title4}
            img={content?.ImageCompound?.ImageCompound_4}
            analytics={analytics}
            authoringHelper={authoringHelper}
            secondaryArgs={secondaryArgs}
            ButtonObj={ButtonObj4}
            buttonDataObj={ButtonDataObj4}
            showSlide={currentSlide === 3}
          />
        </Box>
        <Box className='controls'>
          <Box className='slider-nav'>
            {Object.entries(content?.ImageCompound).map(([key], index) => (
              <Box
                sx={{ width: { em: "120px !important" }, position: "relative" }}
                key={`image${key}`}
                className={`slider-dot ${currentSlide === index ? "active" : ""}`}
                onClick={() => handleSlideChange(index)}
                data-testid='banner-slide-change'>
                <Typography
                  className={`itemText activeSlide ${currentSlide === index ? "active" : ""}`}
                  variant='h6medium'>
                  {`0${index + 1}`}
                </Typography>
                <Box
                  className={`hor-line ${currentSlide === index ? "active" : ""} ${
                    index === selectedOne.current ? "prviousSelect" : ""
                  }`}></Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

interface Banner5Props {
  content: Content;
  analytics: Analytics;
  authoringHelper: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Content {
  TagName?: string;

  Title1: string;
  Button1_Action?: string;
  Button1_Content?: string;
  Button1_Name?: string;
  Button1_RedirectURL?: string;
  Button1_RestEndPonit?: string;
  Button1_Type?: string;
  Button1_Value?: string;

  Title2: string;
  Button2_Action?: string;
  Button2_Content?: string;
  Button2_Name?: string;
  Button2_RedirectURL?: string;
  Button2_RestEndPonit?: string;
  Button2_Type?: string;
  Button2_Value?: string;

  Title3: string;
  Button3_Action?: string;
  Button3_Content?: string;
  Button3_Name?: string;
  Button3_RedirectURL?: string;
  Button3_RestEndPonit?: string;
  Button3_Type?: string;
  Button3_Value?: string;

  Title4: string;
  Button4_Action?: string;
  Button4_Content?: string;
  Button4_Name?: string;
  Button4_RedirectURL?: string;
  Button4_RestEndPonit?: string;
  Button4_Type?: string;
  Button4_Value?: string;

  ImageCompound: {
    ImageCompound_1: ImageCompound;
    ImageCompound_2: ImageCompound;
    ImageCompound_3: ImageCompound;
    ImageCompound_4: ImageCompound;
  };
}
interface ImageCompound {
  original_image: object;
  published_images: PublishedImages[];
}

interface PublishedImages {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

export default Banner5;
