import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { Box, Card, CardHeader, Chip, Divider, Grid, Typography } from "@mui/material";
import { PlatFormXDateTimeFormat, formRelativeURL } from "@platformx/utilities";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import VideoPlayer from "../../components/VideoPlayers/VideoPlayer";
import { useCustomStyle } from "./videoLandingPage.style";

const Share = lazy(() => import("../../components/Share/Share"));
const RecentCarousel = lazy(() => import("../../components/RecentCarousel/RecentCarousel"));

const BrightcovePlayer: any = React.lazy(
  () => import("../../components/BrightcovePlayer/BrightcovePlayer"),
);
const VideoLandingPage = (props: any) => {
  const { content, analytics, authoringHelper = {}, secondaryArgs = {} } = props;
  const { isAuthoring = false } = authoringHelper;
  const classes = useCustomStyle();
  const getVodUrl = () => {
    let id = content?.current_page_url;
    if (id && id.charAt(0) === "/") {
      id = id.substring(1);
    }
    if (secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint) {
      return `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/video/${id}`;
    } else {
      return `/video${id}`;
    }
  };
  const { ref } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [playVideo, setPlayVideo] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (typeof window !== "undefined") {
      i18n.changeLanguage(secondaryArgs?.prelemBaseEndpoint?.language);
    }
  }, []);

  const capitalizeFirstLetter = (str: string) => {
    try {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } catch (e) {
      return "";
    }
  };
  const showPublishedDate = () => {
    let showDate = "";
    if (!isAuthoring) {
      showDate = content?.publishedDate;
    } else if (content?.modificationDate) {
      showDate = content?.modificationDate;
    } else {
      showDate = new Date().toISOString();
    }
    if (showDate) {
      return PlatFormXDateTimeFormat(showDate);
    } else {
      return "";
    }
  };
  const playVodEnable = () => {
    if (!isAuthoring) setPlayVideo(true);
  };
  const publishUrl = secondaryArgs?.prelemBaseEndpoint?.buttonBaseUrl;
  const language = secondaryArgs?.prelemBaseEndpoint?.language;

  const embedPageURL = publishUrl + language + "/embed/video" + content?.current_page_url;
  const landingPageURL = publishUrl + language + "/video" + content?.current_page_url;

  const embedData = {
    Title: content?.title,
    Description: content?.description,
    Thumbnail: formRelativeURL(secondaryArgs?.gcpUrl, secondaryArgs?.bucketName, content.Thumbnail),
    Author: content?.Page_CreatedBy,
    creationDate: content?.lastModificationDate,
    Page: embedPageURL,
    LandingPage: landingPageURL,
    ContentURL: content?.PageSettings?.socialog_url,
  };
  return (
    <div ref={authoringHelper?.innerRef}>
      <Grid
        container
        ref={ref}
        className={`${classes.videoLandingPageWrapper} videoLandingPage prelem-pb`}>
        <Grid container>
          <Grid item xs={12}>
            {content?.PlayerType === "AWS" ? (
              <Box
                sx={{
                  height: {
                    md: "500px",
                    sm: "500px",
                    xs: "321px",
                    lg: "500px",
                  },
                }}>
                <VideoPlayer
                  playerProp={{
                    posterImg: content?.LatestVods[0]?.thumbnail,
                    videoUrl: content?.StreamingURL,
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: { xs: "15px", sm: "25px" },
                    margin: { xs: "0 4%", em: "0" },
                    width: { sm: "100%" },
                  }}>
                  <Typography sx={{ margin: { sm: "0 auto" }, width: { sm: "700px" } }}>
                    <PlayArrowRoundedIcon
                      onClick={playVodEnable}
                      data-testid='play-vod-enable'
                      style={{
                        background: "white",
                        borderRadius: "100px",
                        color: "black",
                        padding: "16px",
                        cursor: "pointer",
                      }}
                      sx={{
                        fontSize: { xs: "57px", sm: "77px" },
                      }}
                    />
                  </Typography>
                </Box>
                <Box
                  className='gradient'
                  sx={{
                    height: {
                      md: "500px",
                      sm: "500px",
                      xs: "321px",
                      lg: "500px",
                    },
                  }}>
                  <img
                    src={formRelativeURL(
                      secondaryArgs?.gcpUrl,
                      secondaryArgs?.bucketName,
                      content.Thumbnail,
                    )}
                    alt={"Thumbnail_Image"}
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                  {playVideo && typeof window !== "undefined" && (
                    <Box
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                      }}>
                      <Suspense fallback={"Loading..."}>
                        <BrightcovePlayer
                          // options={{ mute: true }}
                          analytics={analytics}
                          analyticsEnable={content?.analytics_enable}
                          prelemTitle=''
                          VideoData={{
                            VideoID: content?.VodVideoId,
                            PlayerID: content?.PlayerID,
                            AccountID: content?.VodAccountId,
                            PlaylistID: content?.Poster,
                          }}
                          secondaryArgs={secondaryArgs}
                          tags={content?.tags}
                          author={content?.author}
                        />
                      </Suspense>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Card
              className='videoPageCard'
              style={{ boxShadow: "none", borderRadius: "0px" }}
              sx={{
                margin: { xs: "0", md: "0 auto" },
                padding: { xs: "0px 3% 0px 3%", md: "0px" },
                width: { sm: "700px" },
              }}>
              <Box sx={{ padding: { xs: "6% 0", sm: "3% 0 2%" } }}>
                <Typography variant='h1bold' className='noMarginBoth heading'>
                  {capitalizeFirstLetter(content?.title)}
                </Typography>
                {content?.description && (
                  <Typography
                    variant='h6regular'
                    className='noMarginBottom'
                    sx={{
                      marginTop: { xs: "4%", sm: "2%" },
                      whiteSpace: "pre-wrap",
                    }}
                    // dangerouslySetInnerHTML={{ __html: content?.Description }}
                  >
                    {content?.description}
                  </Typography>
                )}
              </Box>
              <Divider />
              <Box
                sx={{
                  padding: "12px 0px 12px 0px",
                  display: "inline-block",
                  width: "100%",
                }}>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "68%" },
                    float: { sm: "left" },
                  }}>
                  <CardHeader
                    sx={{ padding: "0" }}
                    titleTypographyProps={{
                      variant: "h6bold",
                      sx: { float: "left", paddingRight: "5px", width: "auto" },
                    }}
                    title={`${capitalizeFirstLetter(content?.Page_CreatedBy) || capitalizeFirstLetter(content?.createdBy)} |`}
                    subheaderTypographyProps={{
                      variant: "h6medium",
                      className: "publishTime",
                    }}
                    subheader={showPublishedDate()}
                  />
                  <Box sx={{ marginTop: "1%" }}>
                    {content.tags?.length &&
                      content.tags.slice(0, 3)?.map((tag: string) => {
                        return (
                          <Chip
                            label={tag}
                            key={tag}
                            variant='outlined'
                            style={{
                              marginTop: "1%",
                              marginRight: "6px",
                              backgroundColor: "#e6eaed",
                              borderRadius: "3px",
                              border: "none",
                              fontSize: "12px",
                              fontWeight: "normal",
                            }}
                          />
                        );
                      })}
                    {content.tags?.length > 3 && (
                      <Typography variant='h6regular' component='span' className='counter'>
                        + {content.tags?.length - 3}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: { xs: "18px", sm: "14px" },
                    width: { xs: "100%", sm: "26%" },
                    float: { sm: "right" },
                  }}>
                  <Typography variant='subtitle2'>{t("share_text")}</Typography>
                  <Suspense fallback={<div></div>}>
                    <Share
                      domainUrl={getVodUrl()}
                      shareUrl={content?.PageSettings?.socialog_url}
                      embedData={embedData}
                      whiteIcon={false}
                      border='1px solid black'
                    />
                  </Suspense>
                </Box>
              </Box>
              <Suspense fallback={<div></div>}>
                <Box
                  sx={{
                    margin: { xs: "0 -3%", md: "0" },
                    padding: { xs: "20px 5%", md: "0" },
                  }}>
                  {!isAuthoring && content?.LatestVods?.length > 0 && (
                    <RecentCarousel
                      heading={t("related_videos")}
                      filter='Vod'
                      secondaryArgs={
                        isAuthoring ? { ...secondaryArgs, platform: "isAuthoring" } : secondaryArgs
                      }
                      // tags={content?.tags}
                    />
                  )}
                </Box>
              </Suspense>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default VideoLandingPage;
