// import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  // const theme = useTheme();
  return {
    OrderWrapper: {
      "&.OrderWrapperSection": {
        width: "100%",
      },
    },
  };
});
