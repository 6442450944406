import { gql } from "@apollo/client";

export const GET_PRODUCT_DETAILS = gql`
  query publish_fetchEcomProductDetails($productId: String!, $filterAttr: publish_JSON) {
    publish_fetchEcomProductDetails(productId: $productId, filterAttr: $filterAttr)
  }
`;

export const GET_ORDER_DETAILS = gql`
  query publish_fetchEcomOrderDetails($orderId: String!) {
    publish_fetchEcomOrderDetails(orderId: $orderId)
  }
`;

export const GET_ORDER_HISTORY = gql`
  query publish_getOrderHistory(
    $customerEmail: String
    $pagination: publish_Paginate
    $searchTerm: String
    $sort: publish_sortEcomOption
    $purchasedFrom: String
  ) {
    publish_getOrderHistory(
      customerEmail: $customerEmail
      pagination: $pagination
      searchTerm: $searchTerm
      sort: $sort
      purchasedFrom: $purchasedFrom
    )
  }
`;

export const GET_TRANSACTIONAL_REWARDS = gql`
  query users_getTransactionRewards($documentId: String!) {
    users_getTransactionRewards(documentId: $documentId) {
      rewardList {
        reward_name
        reward_id
        issued_reward_id
        issued_coupon {
          value_type
          coupon_code
          value
          active_from
          active_to
          used_at
        }
      }
      pointsList {
        points
        type
        comment
      }
    }
  }
`;
