import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    trackingDetailWrapper: {
      "&.trackingDetailWrapperSection": {
        width: "100%",
        "& .trackingDetailInner": {
          padding: "20px",
          border: `solid 1px ${theme.palette.prelemType1.LINE}`,
          marginBottom: "16px",
          "& .orderHeading": {
            marginBottom: "24px",
            paddingBottom: "8px",
            marginTop: 0,
          },
          "& .steps": {
            position: "relative",
            paddingBottom: "8px",
            marginBottom: "16px",
          },
        },
      },
    },
  };
});
