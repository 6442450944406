import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  SelectPageListWp: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& .headerWarp": {
      borderBottom: `1px solid ${theme.palette.customColor.LINE}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "11.1px 15px",
    },
    "& .leftHeaderContent": {
      display: "flex",
      alignItems: "center",
    },
  },
}));
