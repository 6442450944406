import EastIcon from "@mui/icons-material/East";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import WestIcon from "@mui/icons-material/West";
import { Box, Button, Grid, Typography } from "@mui/material";
import { fallBackImage } from "@platformx/shared/static-assets";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import CarousalSkeltonLoader from "../../../components/SkeletonLoader/CarousalSkeltonLoader";
import "../../../prelems/DynamicEcommercePrelem/DynamicEcommercePrelem.css";
import { useCustomStyle } from "../../../prelems/DynamicEcommercePrelem/DynamicEcommercePrelem.style";
import { getProductList } from "../../../prelems/DynamicEcommercePrelem/helper";
import { addToCartGetCartId } from "../helperEcommerce";
import EcomProductPrice from "./SharedComponents/ProductPrice";

const ProductListSlider = ({
  title = "Products",
  productId = null,
  secondaryArgs,
  selectedPersonalisation = null,
}: any) => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [slots, setSlots] = useState<any>(null);
  const [queryParamfordata, setQueryParamfordata] = useState<any>({});
  const fromBack = useRef(false);
  const { t } = useTranslation();
  const classes = useCustomStyle();

  const start = 0;
  const sliderSettings = {
    slidesToShow: 4,
    infinite: false,
    initialSlide: 0,
    autoplaySpeed: 500,
    slidesToScroll: 4,
    speed: 500,
    mobileFirst: true,
    afterChange: (current: number) => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          arrows: false,
          infinite: false,
        },
      },
    ],
  };

  const sliderEndCheck =
    (sliderRef?.state?.breakpoint !== 920 &&
      sliderRef?.state?.breakpoint !== 600 &&
      activeSlide >= sliderRef?.props.children.length - 4) ||
    (sliderRef?.state?.breakpoint === 920 &&
      activeSlide >= sliderRef?.props.children.length - 2.5) ||
    (sliderRef?.state?.breakpoint === 600 && activeSlide >= sliderRef?.props.children.length - 1.5);
  const isNext = (slots?.length < 20 && sliderEndCheck) || slots?.length === 0 ? false : true;
  const isPrev = start === 0 && activeSlide === 0 ? false : true;
  const getProducts = async (
    data = {
      pagination: {
        start: 0,
        rows: 20,
      },
      searchTerm: "",
      tags: [],
      filter: "Ecommerce",
      isSuggestive: false,
      ecommerceRequest: {
        filter: [],
      },
    },
  ) => {
    setLoading(true);
    const fetchEcomProducts: any = await getProductList(
      data,
      secondaryArgs,
      null,
      selectedPersonalisation,
      productId,
    );
    setLoading(false);
    //const { data: { data: { fetchEcomProducts = [] } = {} } = {} } = response;
    if (fetchEcomProducts?.length >= 0) {
      setSlots(fetchEcomProducts);
    } else {
      setQueryParamfordata((prevState) => {
        const { pagination: { start: prevStateStart = 0 } = {} } = prevState;
        return {
          ...prevState,
          pagination: {
            ...prevState?.pagination,
            start: prevStateStart - 20,
          },
        };
      });
      fromBack.current = true;
    }
  };

  const handlePrevClick = () => {
    if (start !== 0 && activeSlide === 0) {
      getProducts({
        ...queryParamfordata,
        pagination: {
          ...queryParamfordata?.pagination,
          start: start - 20,
        },
      });
      setQueryParamfordata((prevState) => {
        const { pagination: { start: prevStateStart = 0 } = {} } = prevState;
        return {
          ...prevState,
          pagination: {
            ...prevState?.pagination,
            start: prevStateStart - 20,
          },
        };
      });
      fromBack.current = true;
    } else {
      sliderRef?.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderEndCheck && sliderRef?.props.children.length === 20) {
      getProducts({
        ...queryParamfordata,
        pagination: {
          ...queryParamfordata?.pagination,
          start: start + 20,
        },
      });
      setQueryParamfordata((prevState) => {
        const { pagination: { start: prevStateStart = 0 } = {} } = prevState;
        return {
          ...prevState,
          pagination: {
            ...prevState?.pagination,
            start: prevStateStart + 20,
          },
        };
      });
      fromBack.current = false;
      setActiveSlide(0);
    } else {
      sliderRef?.slickNext();
    }
  };

  const onViewDetails = (selected_productId: string) => {
    window.location.href = `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/ecommerce/product-detail?productId=${selected_productId}`;
  };

  const addtoCartAndRedirect = (card) => {
    addToCartGetCartId(secondaryArgs, card.id, 1, true, card, t("errorRequest"));
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Grid
      xs={12}
      className={`${classes.dynamicEcommercePrelem} dynamicEcommercePrelemWrapper productListingWrapper`}>
      <Grid className='dynamic-ecommerce-prelem-slider sliderOverFlow' container xs={12} md={12}>
        <Grid item xs={9} md={8} className='headingRow'>
          <Typography id='Title' variant='h2regular' className='title'>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={3} md={4} className='navigationWrapper'>
          <Box className='navigationWrapper'>
            <Box
              className={`transparentButton ${!isPrev ? "disablebutton" : ""}`}
              onClick={handlePrevClick}
              sx={{
                cursor: isPrev ? "pointer" : "default",
                pointerEvents: isPrev ? "auto" : "none",
              }}>
              <WestIcon
                className={`extPreviousButton previousBtn ${!isPrev ? "decreaseOpacity" : ""}`}
              />
            </Box>
            <Box
              className={`transparentButton ${!isNext ? "disablebutton" : ""}`}
              onClick={handleNextClick}
              sx={{ pointerEvents: isNext ? "auto" : "none" }}>
              <EastIcon className={`nextPreviousButton ${!isNext ? "decreaseOpacity" : ""}`} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          {loading ? (
            <CarousalSkeltonLoader />
          ) : !loading && slots && slots?.length === 0 ? (
            <Box className='noProductWrapper'>
              <HighlightOffRoundedIcon className='iconSize' />
              <Typography variant='h5' color='lightDarkText'>
                {t("no_products")}
              </Typography>
            </Box>
          ) : slots && slots?.length > 0 ? (
            <Slider ref={setSliderRef} {...sliderSettings}>
              {slots &&
                slots?.length > 0 &&
                slots?.map((card: any, index: number) => (
                  <Grid
                    item
                    key={`${card?.ecomx_name}_${index.toString()}`}
                    className='article pointer'
                    onClick={() => onViewDetails(card.id)}
                    data-testid='view-details-card'>
                    <Box className='image-container'>
                      <Box className='imgWrapper'>
                        <img
                          className='image'
                          src={card?.attr_images?.length > 0 ? card?.attr_images[0] : fallBackImage}
                          alt='background'
                          height='100%'
                          onError={(e: any) => {
                            if (e.target.src !== fallBackImage) {
                              e.target.onerror = null;
                              e.target.src = fallBackImage;
                            }
                          }}
                        />
                      </Box>
                      <Box className='hidden-button buttonItem'>
                        <Button
                          variant='primaryButton1'
                          onClick={(event) => {
                            event.stopPropagation();
                            if (!JSON.parse(card?.ecomx_in_stock)) return;
                            addtoCartAndRedirect(card);
                          }}
                          data-testid='addToCart-and-redirect'>
                          {JSON.parse(card?.ecomx_in_stock) ? t("add_to_cart") : t("out_of_stock")}
                        </Button>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        className='ellipsisTextof1line noGapBottom topheading'
                        variant='p4medium'>
                        {card?.attribute?.brand}
                      </Typography>
                      <Typography className='ellipsisTextof2lines noGapBottom' variant='p3semibold'>
                        {card?.ecomx_name}
                      </Typography>
                      <EcomProductPrice productFullDetails={card} />
                    </Box>
                  </Grid>
                ))}
            </Slider>
          ) : (
            <Box className='noProductWrapper'>
              <HighlightOffRoundedIcon className='iconSize' />
              <Typography variant='h5' color='lightDarkText'>
                {t("no_products")}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductListSlider;
