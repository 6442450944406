import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCustomStyle } from "./DeliveryAddressDetail.style";

const DeliveryAddressDetail = () => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  return (
    <Box className={`${classes.deliveryAddressWrapper} deliveryAddressDetail`}>
      <Box className='OrderShippingDetail'>
        <Typography variant='h5regular' className='noTopMargin'>
          Delivery Detail
        </Typography>
        <Box className='shippingAddress'>
          <Typography variant='labelregular' className='subheading'>
            {t("shipping_details")}
          </Typography>
          <Typography variant='p3bold' className='noMargin'>
            Alexandra Daddario
          </Typography>
          <Typography variant='p4medium' className='noMargin description'>
            1520 Pacific Ave, Santa Cruz, CA 95060, United States
          </Typography>
          <Typography variant='p4medium' className='noMargin'>
            {`LandMark: Behind the The Last book store`}
          </Typography>
          <Typography variant='p4medium' className='noMargin'>
            {`Contact Number: 8314230911`}
          </Typography>
        </Box>
        <Box className='billingAddress'>
          <Typography variant='labelregular' className='subheading'>
            {t("billing_details")}
          </Typography>
          <Typography variant='p3bold' className='noMargin'>
            Alexandra Daddario
          </Typography>
          <Typography variant='p4medium' className='noMargin description'>
            1520 Pacific Ave, Santa Cruz, CA 95060, United States
          </Typography>
          <Typography variant='p4medium' className='noMargin'>
            {`LandMark: Behind the The Last book store`}
          </Typography>
          <Typography variant='p4medium' className='noMargin'>
            {`Contact Number: 8314230911`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DeliveryAddressDetail;
