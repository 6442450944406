import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Chip,
  Tooltip as MuiTooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { graph } from "../Constants";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const mappedData = array?.map((el, index) => [el, index]);
  mappedData.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return mappedData.map((el) => el[0]);
};

const DynamicTable = ({ itemData }: any) => {
  const { chartData: data, title, column_names: columns, graph_type } = itemData;
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState(columns[0]);
  const theme = useTheme();
  const customfield = ["Order Status"];
  const getStatusChip = (status) => {
    let color;
    let bg;
    switch (status) {
      case graph.Status[0]:
        color = theme.palette.notification.WARNING.LIGHT_TEXT;
        bg = theme.palette.notification.WARNING.LIGHT_BACKGROUND;
        break;
      case graph.Status[1]:
        color = theme.palette.notification.SUCCESS.LIGHT_TEXT;
        bg = theme.palette.notification.SUCCESS.LIGHT_BACKGROUND;
        break;
      case graph.Status[2]:
        color = theme.palette.notification.ERROR.LIGHT_TEXT;
        bg = theme.palette.notification.ERROR.LIGHT_BACKGROUND;
        break;
      default:
        color = theme.palette.customColor.BGLIGHT;
        bg = theme.palette.customColor.PRIMARY;
    }
    return (
      <Chip
        label={status}
        variant='filled'
        sx={{
          background: bg,
          color: color,
          borderRadius: theme.borderRadius.value1,
          minWidth: "93px",
        }}
      />
    );
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const renderColumnData = (row, column: any) => {
    const isTimestamp = graph.timeStampFormates.includes(column);
    if (column === customfield[0]) {
      return getStatusChip(row[column]);
    } else if (isTimestamp && row[column]) {
      return format(new Date(row[column]), "dd/MM/yyyy");
    } else {
      return row[column];
    }
  };

  return (
    <Box className={`${graph_type} barChartHorizontal pageGraph`}>
      <MuiTooltip title={title} placement='top-start'>
        <Typography variant='h5semibold' className='heading'>
          {title}
        </Typography>
      </MuiTooltip>
      <TableContainer className='chartTable' sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  className='tableHeadCustomCell'
                  sortDirection={orderBy === column ? order : false}
                  style={{ minWidth: 155, maxWidth: 230 }}>
                  <TableSortLabel
                    active={orderBy === column}
                    direction={orderBy === column ? order : "asc"}
                    onClick={() => handleRequestSort(column)}
                    data-testid={`table-sort-label-${column}`}>
                    <Box sx={{ display: "flex" }}>
                      {column}
                      <Box className='iconsWrapper'>
                        <KeyboardArrowUpIcon
                          sx={{
                            fontSize: "14px",
                            opacity: orderBy === column && order === "asc" ? "1" : "0.4",
                          }}
                        />
                        <KeyboardArrowDownIcon
                          sx={{
                            fontSize: "14px",
                            marginTop: "-4px",
                            opacity: orderBy === column && order === "desc" ? "1" : "0.4",
                          }}
                        />
                      </Box>
                    </Box>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 &&
              stableSort(data, getComparator(order, orderBy)).map((row, rowIndex) => (
                <TableRow hover key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell key={column}>{renderColumnData(row, column)}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DynamicTable;
