import { Box, Container, Grid } from "@mui/material";
import { lazy, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CarousalSkeltonLoader from "../../../components/SkeletonLoader/CarousalSkeltonLoader";
import { SkeletonLoader } from "../../../components/SkeletonLoader/SkeletonLoader";
import ToastService from "../../../components/ToastContainer/ToastService";
import { graphQlEcommerceList } from "../../../graphql/Ecommerce/fetch";
import { getUserInfo } from "../../../utils/helper";
import OrderActions from "./OrderActions/OrderActions";
import OrderDetails from "./OrderDetails/OrderDetails";
import OrderHighlights from "./OrderHighlights/OrderHighlights";
import { useCustomStyle } from "./OrderPlaced.style";
import OrderRecived from "./OrderRecived/OrderRecived";
import OrderRewards from "./OrderRewards/OrderRewards";

const OrderPlaced = ({ secondaryArgs }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const [orderDetail, setOrderDetail] = useState(null);
  const [loader, setLoader] = useState(true);
  const [personaliseItem, setPersonaliseItem] = useState({ product_id: "", email: "" });

  const userId = getUserInfo("user_id");

  useEffect(() => {
    const url = new URL(window.location.href);
    const orderId = url.searchParams.get("order_id");

    localStorage.removeItem("ecommerceCartId");
    if (orderId) {
      cartItemDetails(orderId);
    } else {
      setOrderDetail(null);
      ToastService.failToast(t("errorRequest"));
    }
  }, []);

  const cartItemDetails = async (orderId = "") => {
    try {
      const {
        prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" },
        sitename = "",
      } = secondaryArgs;
      const { data, errors } = await graphQlEcommerceList.fetchOrderDetails(
        {
          orderId,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      const {
        publish_fetchEcomOrderDetails: { statusCode, msg, data: orderData },
      } = data;
      if (errors) {
        ToastService.failToast(msg || t("errorRequest"));
      }
      if (statusCode === 200) {
        setOrderDetail(orderData?.[0]);
        setPersonaliseItem({
          product_id: orderData?.[0]?.lineItem?.[0]?.productId,
          email: orderData?.[0]?.shippingAddress?.email,
        });
        setLoader(false);
      } else {
        setOrderDetail(null);
        ToastService.failToast(t("errorRequest"));
      }
    } catch (err) {
      setOrderDetail(null);
      ToastService.failToast(t("errorRequest"));
    }
  };

  const ProductListSlider = lazy(() => import("../ProductDetail/ProductListSlider"));

  return (
    <Box className={`${classes.orderPlaced} orderPlacedSection`}>
      {loader ? (
        <Box sx={{ minHeight: "100vh" }}>
          <SkeletonLoader />
        </Box>
      ) : (
        <Container className='grid_container prelem-py grid_container_nopadding'>
          {orderDetail ? (
            <>
              <Grid item xs={12} alignItems='stretch' columnSpacing={2} className='orderPlacedRow'>
                <Grid container>
                  <Grid item xs={12} md={12} em={5} lg={6}>
                    <OrderRecived orderDetail={orderDetail} secondaryArgs={secondaryArgs} />
                  </Grid>
                  <Grid item xs={12} md={6} em={4} lg={4}>
                    <OrderRewards secondaryArgs={secondaryArgs} />
                  </Grid>
                  <Grid item xs={12} md={6} em={3} lg={2}>
                    <OrderActions />
                  </Grid>
                </Grid>
                <OrderHighlights orderDetail={orderDetail} />
              </Grid>
              <Grid item xs={12} className='orderDetailRow'>
                <OrderDetails orderDetail={orderDetail} />
              </Grid>
              <Grid item xs={12} className='crosSellRow'>
                <Suspense fallback={<CarousalSkeltonLoader />}>
                  <ProductListSlider
                    title={t("you_might_be_also_interested_in")}
                    productId={personaliseItem?.product_id}
                    secondaryArgs={secondaryArgs}
                    selectedPersonalisation={{ "personalisation-value": "crosssellproducts" }}
                  />
                </Suspense>
                <Suspense fallback={<CarousalSkeltonLoader />}>
                  <ProductListSlider
                    title={t("you_may_also_like")}
                    productId={personaliseItem?.product_id}
                    secondaryArgs={secondaryArgs}
                    selectedPersonalisation={{ "personalisation-value": "upsellproducts" }}
                  />
                </Suspense>
                {userId ? (
                  <Suspense fallback={<CarousalSkeltonLoader />}>
                    <ProductListSlider
                      title={t("recent_viewed")}
                      secondaryArgs={secondaryArgs}
                      selectedPersonalisation={{ "personalisation-value": "recentlyviewed" }}
                    />
                  </Suspense>
                ) : (
                  <Suspense fallback={<CarousalSkeltonLoader />}>
                    <ProductListSlider
                      title={t("related_products")}
                      productId={personaliseItem?.product_id}
                      secondaryArgs={secondaryArgs}
                      selectedPersonalisation={{ "personalisation-value": "relatedproducts" }}
                    />
                  </Suspense>
                )}
              </Grid>
            </>
          ) : null}
        </Container>
      )}
    </Box>
  );
};

export default OrderPlaced;
