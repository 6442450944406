import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ToastService from "../../../components/ToastContainer/ToastService";
import { graphQlEcommerceList } from "../../../graphql/Ecommerce/fetch";
import { getUserInfo } from "../../../utils/helper";
import { trackProductView } from "../../../utils/tracking/rpiTracking";
import "./ProductDetail.css";
import { useCustomStyle } from "./ProductDetail.style";
import ProductListSlider from "./ProductListSlider";
import ProductDisplay from "./SharedComponents/ProductDisplay";
import TabDocument from "./SharedComponents/TabDocument";

const ProductDetail = ({
  productId = "",
  secondaryArgs = {},
  cartCountUpdate = () => {},
  showAdditionalDetails = true,
}: any) => {
  const userEmail = getUserInfo("email_id");
  const userId = getUserInfo("user_id");
  const { t, i18n } = useTranslation();
  const classes = useCustomStyle();

  const [loading, setLoading] = useState(false);
  const [variantsDetails, set_VariantsDetails] = useState<any>([]);
  const [productFullDetails, setProductFullDetails] = useState<any>({});

  /**
   * id based get product details get
   */
  const getProductDetails = async () => {
    try {
      setLoading(true);
      const {
        prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {},
        sitename = "",
      } = secondaryArgs;
      const filterAttr: any = variantsDetails?.length > 0 ? variantsDetails : [];
      const { errors, data } = await graphQlEcommerceList.fetchProductDetails(
        {
          productId,
          filterAttr,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      const { publish_fetchEcomProductDetails = {} } = data;
      if (errors) {
        ToastService.failToast(t("errorRequest"));
        setProductFullDetails({});
      } else {
        setProductFullDetails(publish_fetchEcomProductDetails);
        trackProductView(publish_fetchEcomProductDetails, secondaryArgs);
      }
    } catch {
      ToastService.failToast(t("errorRequest"));
      setProductFullDetails({});
    } finally {
      setLoading(false);
    }
  };

  /**
   * size variant handle
   * @param item
   */
  const sizeVariantsHandle = (item: any) => {
    const newArray = [...variantsDetails, item];
    const ids = newArray.map(({ key }) => key);
    const filtered = newArray.filter(({ key }: any, index) => !ids.includes(key, index + 1));
    set_VariantsDetails(filtered);
  };

  useEffect(() => {
    getProductDetails();
  }, [productId, variantsDetails]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      i18n.changeLanguage(secondaryArgs?.prelemBaseEndpoint?.language);
    }
  }, []);

  return (
    <Box className={`${classes.productDetailWrapper} productDetailpage`}>
      <ProductDisplay
        secondaryArgs={secondaryArgs}
        loading={loading}
        productFullDetails={productFullDetails}
        productId={productId}
        sizeVariantsHandle={sizeVariantsHandle}
        t={t}
        cartCountUpdate={cartCountUpdate}
      />

      <Container className='grid_container prelem-py'>
        <ProductListSlider
          title='Often Purchased Together'
          productId={productId}
          secondaryArgs={secondaryArgs}
          selectedPersonalisation={{ "personalisation-value": "crosssellproducts" }}
        />
        <ProductListSlider
          title='Similar Products'
          productId={productId}
          secondaryArgs={secondaryArgs}
          selectedPersonalisation={{ "personalisation-value": "upsellproducts" }}
        />
        {userEmail ? (
          <ProductListSlider
            title='Related Products'
            productId={productId}
            secondaryArgs={secondaryArgs}
            selectedPersonalisation={{ "personalisation-value": "relatedproducts" }}
          />
        ) : null}
      </Container>
      {showAdditionalDetails && (
        <Grid xs={12} className='prelem-py'>
          <TabDocument productFullDetails={productFullDetails} />
          <Container className='grid_container prelem-py'>
            {userId ? (
              <ProductListSlider
                title={t("recent_viewed")}
                secondaryArgs={secondaryArgs}
                selectedPersonalisation={{ "personalisation-value": "recentlyviewed" }}
              />
            ) : (
              <ProductListSlider
                title='Related Products'
                productId={productId}
                secondaryArgs={secondaryArgs}
                selectedPersonalisation={{ "personalisation-value": "relatedproducts" }}
              />
            )}
          </Container>
        </Grid>
      )}
    </Box>
  );
};

export default ProductDetail;
