import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    radialCharts: {
      "&.radialChartsSection": {
        background: theme.palette.prelemType1.BACKGROUND,
        "& .radialChartWrapper": {
          border: `1px solid ${theme.palette.prelemType1.LINE}`,
          padding: "20px",
          [theme.breakpoints.up("lg")]: {
            height: "444px",
          },
          "& .radialChartTextWrapper": {
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            scrollbarWidth: "none",
            width: "100%",
            cursor: "move",
            height: "70px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "& .events": {
              minWidth: "120px",
              height: "70px",
              border: `1px solid ${theme.palette.prelemType1.LINE}`,
              display: "inline-block",
              marginRight: "10px",
              padding: "8px",
              "& .points": {
                display: "flex",
                "& .subpoints": {
                  display: "flex",
                  alignItems: "end",
                  paddingLeft: "4px",
                },
              },
              "& .labelcolor": {
                color: theme.palette.prelemType1.LABEL,
              },
            },
          },
          "& .noresultFound": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            "& .message": {
              textAlign: "center",
            },
          },
        },
      },
    },
  };
});
