import { Box, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { styled } from "@mui/system";
import { useCustomStyle } from "./TrackingDetail.style";

const steps = [
  {
    label: "Order Placed",
    date: "October 12, 2024",
    place: "",
  },
  {
    label: "Shipped",
    date: "October 12, 2024 | 14:16",
    place: "Denver, Colorado",
  },
  {
    label: "Out for Delivery",
    date: "October 14, 2024",
    place: "Santa Cruz, CA",
  },
  {
    label: "Delivered",
    date: "Expected 11AM - 9PM",
    place: "",
  },
];

const StepCircle = styled(Box)<{ active: boolean }>(({ active, theme }) => ({
  width: "16px",
  height: "16px",
  borderRadius: "50%",
  backgroundColor: active
    ? theme?.palette?.prelemType1?.NOTIFICATION?.SUCCESS?.BACKGROUND
    : theme?.palette?.prelemType1?.LINE,
  position: "absolute",
  left: -3,
  top: 9,
  zIndex: 1,
}));

const StepConnector = styled(Box)<{ active: boolean }>(({ active, theme }) => ({
  height: "100%",
  width: 1,
  backgroundColor: active
    ? theme?.palette?.prelemType1?.NOTIFICATION?.SUCCESS?.BACKGROUND
    : theme?.palette?.prelemType1?.LINE,
  position: "absolute",
  left: "5px",
  top: "25px",
  zIndex: 0,
}));

const TrackingDetail = () => {
  const theme = useTheme();
  const classes = useCustomStyle();
  const ActiveItem = 2;
  const getActiveStep = (index, active) => {
    return index <= active;
  };
  return (
    <Box className={`${classes.trackingDetailWrapper} trackingDetailWrapperSection`}>
      <Box className='trackingDetailInner'>
        <Typography variant='h5medium' className='orderHeading'>
          Tracking Detail
        </Typography>

        {steps.map((step, index) => (
          <Box key={step.label} className='steps'>
            <StepCircle active={getActiveStep(index, ActiveItem)} theme={theme} />
            {index < steps.length - 1 && (
              <StepConnector active={getActiveStep(index, ActiveItem - 1)} theme={theme} />
            )}

            {/* Step content */}
            <Box sx={{ paddingLeft: 4 }}>
              <Typography variant='h6medium' className='noMargin'>
                {step.label}
              </Typography>
              {step?.place && (
                <Typography variant='p4regular' className='labelColor noMargin'>
                  {step.place}
                </Typography>
              )}
              {step?.date && (
                <Typography variant='p4regular' className='labelColor noMargin'>
                  {step.date}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TrackingDetail;
