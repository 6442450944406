import { Box, Container, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { formCroppedUrlString } from "@platformx/utilities";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import ContentReplace from "../../components/ContentReplace/ContentReplace";
import { useClickImpression } from "../../components/ImpressionHooks/ClickImpressionHook";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import prelemTypes from "../../globalStyle";
import "../../Style.css";
import useContentData from "../../utils/DynamicSchema/useContentData";
import "./ServiceCard1.css";
import { useCustomStyle } from "./ServiceCard1.style";

const ServiceCard1 = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
          infinite: true,
          centerMode: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          centerMode: true,
        },
      },
    ],
  };

  usePrelemImpression(analytics, inView, secondaryArgs);
  const { triggerClickAnalytics } = useClickImpression();
  const { contentValue, loader } = useContentData(content, secondaryArgs);

  const classes = useCustomStyle();
  const globalClasses = prelemTypes();
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.serviceCard1Wrapper} ${globalClasses.prelemType1} prelem prelemType1 serviceCard1WrapperBg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? `grid_full_width prelem-py` : `grid_container prelem-py`
        }
        ref={ref}>
        <Typography variant='h2semibold' textAlign='center' id='title'>
          {content?.title}
        </Typography>

        {loader ? (
          <Box className='skeletonbox' sx={{ width: "100%", height: "100%", minHeight: "500px" }}>
            <SkeletonLoader />
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
              "&:hover": {
                ".add-content-overlay": {
                  display: authoringHelper?.authoringHoverShow ? "flex !important" : "none",
                },
              },
            }}>
            {/* For Desktop and TAB*/}
            <Box ref={ref} className='service-card1'>
              <Slider {...settings} className='service-cards1-container'>
                {contentValue &&
                  Object.entries(contentValue).map(([key, value]: [string, any], index) => (
                    <Grid
                      item
                      key={key}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      justifyContent='center'
                      alignItems='center'
                      paddingBottom={2}
                      paddingTop={2}
                      spacing={10}>
                      <Card
                        className='cardBoxWrapper'
                        onClick={() => {
                          triggerClickAnalytics(
                            value?.serviceurl,
                            index,
                            analytics,
                            secondaryArgs,
                            content && content?.Slots && content?.Slots[index]?.title,
                            "Service Card",
                          );
                          if (value?.Internal) {
                            window.location.assign(value.serviceurl);
                          } else {
                            window.open(value.serviceurl, "_self");
                          }
                        }}
                        sx={{
                          cursor: value?.serviceurl ? "pointer" : "auto",
                        }}>
                        <Box className='cardMediaWrapper'>
                          <CardMedia
                            component='img'
                            image={
                              formCroppedUrlString(
                                secondaryArgs?.gcpUrl,
                                secondaryArgs?.bucketName,
                                value?.serviceicon?.original_image?.original_image_relative_path,
                                value?.serviceicon?.original_image?.ext,
                              ).src
                            }
                            sx={{
                              width: "60px",
                              height: "60px",
                              objectFit: "contain",
                            }}
                            alt='image'
                          />
                        </Box>
                        <Typography variant='p3regular' className='description'>
                          {value?.title}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
              </Slider>
            </Box>
            <ContentReplace
              onReplaceClick={() => secondaryArgs?.multiSlot?.onToggleContentGallery()}
            />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default ServiceCard1;
