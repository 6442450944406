import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import { DialogCloseIcon } from "@platformx/shared/static-assets";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CouponPopup from "../../../../components/CouponPopup/CouponPopup";
import { SkeletonLoader } from "../../../../components/SkeletonLoader/SkeletonLoader";
import "./Coupon.css";
import { useCustomStyle } from "./Coupon.style";

const Coupon = ({
  memberId,
  couponLoader,
  selectedCoupon,
  onCouponApply,
  onRemoveCoupon,
  secondaryArgs,
  finalCartPrice,
}) => {
  const classes = useCustomStyle();
  const [showCoupon, setShowCoupon] = useState(false);
  const { t } = useTranslation();
  const handleClick = () => {
    if (finalCartPrice > 0) {
      if (memberId) setShowCoupon(true);
      else
        window.location.href = `${secondaryArgs?.prelemBaseEndpoint?.keycloakUrl}${window.location.href}`;
    }
  };
  const handleClickClose = () => {
    setShowCoupon(false);
  };

  const couponApply = (coupon) => {
    onCouponApply(coupon);
    setShowCoupon(false);
  };

  return (
    <Box className={`ecom-coupon-code ${classes.ecomCoupanCodeWrapper} ecomCoupanCode`}>
      {couponLoader ? (
        <Box sx={{ height: "30px", width: "100%", margin: "9px 0" }}>
          <SkeletonLoader />
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              variant='p3semibold'
              className='coupon-code-text'
              color={selectedCoupon ? "primaryAnchorLink" : "tertiaryTitle"}
              onClick={handleClick}
              data-testid='coupon-code'>
              {selectedCoupon ? selectedCoupon?.CouponCode : `${t("have_a_coupon_code")}?`}
            </Typography>
            {!memberId ? (
              <Tooltip title='Login to view available Coupons.'>
                <InfoOutlinedIcon />
              </Tooltip>
            ) : null}
            {memberId && finalCartPrice === "0.00" && !selectedCoupon ? (
              <Tooltip title='Your cart value is 0, add more products to use Coupons.'>
                <InfoOutlinedIcon />
              </Tooltip>
            ) : null}
          </Box>
          {selectedCoupon ? (
            <img
              style={{ cursor: "pointer" }}
              src={DialogCloseIcon}
              alt='close'
              onClick={onRemoveCoupon}
            />
          ) : null}
        </Box>
      )}
      {showCoupon && (
        <Box className='coupon-box' mt={1} gap={1}>
          <CouponPopup
            memberId={memberId}
            handleClickClose={handleClickClose}
            secondaryArgs={secondaryArgs}
            onCouponApply={couponApply}
          />
        </Box>
      )}
    </Box>
  );
};

export default Coupon;
