import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    OrderSummaryWrapper: {
      "&.OrderSummaryWrapperSection": {
        width: "100%",
        "& .orderCardDetail": {
          padding: "20px",
          border: `solid 1px ${theme.palette.prelemType1.LINE}`,
          marginBottom: "16px",
          "& .orderHeading": {
            borderBottom: `solid 1px ${theme.palette.prelemType1.LINE}`,
            marginBottom: "24px",
            paddingBottom: "8px",
            marginTop: 0,
          },
          "& .orderProductList": {
            display: "flex",
            width: "100%",
            borderBottom: `solid 1px ${theme.palette.prelemType1.LINE}`,
            marginBottom: "16px",
            paddingBottom: "16px",
          },
          "& .orderedProduct": {
            width: "72px",
            height: "72px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#EAEAEA",
            // padding: "10px",
            "& img": {
              mixBlendMode: "multiply",
              maxHeight: "60px",
            },
          },
        },
      },
    },
  };
});
