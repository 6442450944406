import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    OrderCardWrapper: {
      "&.OrderCardWrapperSection": {
        width: "100%",
        padding: "24px",
        background: theme.palette.prelemType1.BACKGROUND,
        border: `solid 1px ${theme.palette.prelemType1.LINE}`,
        marginBottom: "16px",
        cursor: "pointer",
        "&:hover": {
          border: `solid 1px ${theme.palette.prelemType1.TITLE}`,
        },
        "& .cardDetail": {
          display: "block",
          [theme.breakpoints.up("md")]: {
            display: "flex",
          },
          "& .buttonWrapper": {
            [theme.breakpoints.down("md")]: {
              marginTop: "20px",
            },
          },
        },
        "& .orderedProduct": {
          width: 92,
          height: 92,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#EAEAEA",
          // padding: "10px",
          "& img": {
            mixBlendMode: "multiply",
            maxHeight: "80px",
          },
        },
        "& .OrderNumber": {
          color: theme.palette.prelemType1.LINK,
          paddingLeft: "4px",
        },
        "& .Productstatus": {
          fontWeight: "500",
          fontSize: theme.fontSize?.fontSize_12,
        },
        "& .itemStatus": {
          fontSize: theme.fontSize?.fontSize_12,
          marginTop: "4px",
          marginBottom: "20px",
        },
        "& .orderActionColumn": {
          // maxWidth: 200,
        },
        "& .lessGap": {
          marginBottom: "6px",
        },
        // "& .noMargin": {
        //   margin: 0,
        // },
        // "& .noTopMargin": {
        //   marginTop: 0,
        // },
        // "& .noBottomMargin": {
        //   marginTop: 0,
        // },
      },
    },
  };
});
