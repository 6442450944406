import { Box, Button, Dialog, Typography } from "@mui/material";
import { BackArrow } from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import { PageListing } from "./PageListing";
import { useStyles } from "./SelectPageList.style";

export default function SelectPageList({
  handleBack,
  selectedPage = null,
  onClickDone,
  onSelectPage,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={true} fullScreen>
      <Box className={classes.SelectPageListWp}>
        <Box className='headerWarp'>
          <Box className='leftHeaderContent'>
            <BackArrow handleReturn={handleBack} />
            <Typography variant='h4semibold'>{t("choose_page")}</Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              variant='secondaryButton'
              disableElevation
              onClick={handleBack}
              data-testid='canel-page-select'
              className='sm'
              sx={{ marginRight: "12px" }}>
              {t("cancel")}
            </Button>

            <Button
              className='sm addnewpage'
              variant='primaryButton'
              disabled={selectedPage === null}
              onClick={onClickDone}
              data-testid='page-select-done'>
              {t("done")}
            </Button>
          </Box>
        </Box>
        <PageListing selectedPage={selectedPage} onSelectPage={onSelectPage} />
      </Box>
    </Dialog>
  );
}
