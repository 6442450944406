import { Box, Grid, Skeleton } from "@mui/material";
import { useCustomStyle } from "./MyRewards.style";

const MyRewardsSkeleton = () => {
  const classes = useCustomStyle();
  return (
    <>
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <Box
            className={`${classes.MyRewardsCardWrapper} MyRewardsCardWrapperSection`}
            key={index}>
            <Box className='cardDetail' display='flex'>
              <Box width='100%' display='flex'>
                <Grid item>
                  <Box className='rewardProduct'>
                    {/* Skeleton for the image */}
                    <Skeleton
                      variant='rectangular'
                      width={109}
                      height={109}
                      sx={{ borderRadius: "8px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs ml={2}>
                  <Box>
                    {/* Skeleton for the product name */}
                    <Skeleton variant='text' width='70%' />
                    {/* Skeleton for the reward description */}
                    <Skeleton variant='text' width='50%' />
                  </Box>
                  <Box mt={2} display='flex'>
                    {/* Skeleton for points */}
                    <Skeleton
                      variant='text'
                      width='70px'
                      sx={{
                        marginRight: "50px",
                        height: "40px",
                        borderRadius: "12px",
                      }}
                    />
                    {/* Skeleton for expiration date */}
                    <Skeleton
                      variant='text'
                      width='70px'
                      sx={{ height: "20px", marginTop: "10px" }}
                    />
                  </Box>
                </Grid>
              </Box>
              <Grid item className='buttonWrapper'>
                <Box className='orderActionColumn' sx={{ minWidth: "150px" }}>
                  {/* Skeleton for buttons */}
                  <Skeleton
                    variant='rectangular'
                    width='100%'
                    height={40}
                    sx={{ marginBottom: 2, borderRadius: "8px" }}
                  />
                  <Skeleton
                    variant='rectangular'
                    width='100%'
                    height={40}
                    sx={{ marginBottom: 2, borderRadius: "8px" }}
                  />
                </Box>
              </Grid>
            </Box>
          </Box>
        ))}
    </>
  );
};

export default MyRewardsSkeleton;
