import { useEffect, useState } from "react";
import getContentData from "./getContentData";

const useContentData = (content, secondaryArgs) => {
  const [contentValue, setContentValue] = useState<any>(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        const data = await getContentData(content, secondaryArgs);
        if (data) setContentValue(data);
      } catch {
        console.error("FAQFailed");
      } finally {
        setLoader(false);
      }
    }
    if (content?.selected_items?.length > 0) {
      setLoader(true);
      getData();
    } else {
      setLoader(false);
    }
  }, [content?.selected_items]);

  return {
    contentValue,
    loader,
  };
};

export default useContentData;
