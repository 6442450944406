import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    OrderDetailWrapper: {
      "&.OrderDetailWrapperSection": {
        width: "100%",
        "& .leftSection": {
          [theme.breakpoints.up("lg")]: {
            paddingRight: "8px",
          },
        },
        "& .rightSection": {
          [theme.breakpoints.up("lg")]: {
            paddingLeft: "8px",
          },
        },
        "& .buttonWrapper": {
          marginBottom: "4px",
        },
      },
    },
  };
});
