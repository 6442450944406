import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    paymentDetailWrapper: {
      "&.paymentDetailSection": {
        marginBottom: "16px",
        "& .paymentDetailCard": {
          border: `solid 1px ${theme.palette.prelemType1.LINE}`,
          padding: "16px",
          "& .paymentDetailInfo": {
            // marginTop: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            "& .rowItem": {
              // paddingBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
          },
        },
      },
    },
  };
});
