import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ecomCartIdBasedGetItem, getFinalPrice } from "../../helperEcommerce";
import ActualPrice from "../../ProductDetail/SharedComponents/ActualPrice";
import "./SubTotal.css";
import { useCustomStyle } from "./SubTotal.style";

type ecomSubTotalProps = {
  selectedCoupon?: any;
  secondaryArgs?: any;
  isWalletChecked?: boolean;
  points?: any;
  convertedPoints?: any;
};

const SubTotal = (_props: ecomSubTotalProps) => {
  const classes = useCustomStyle();
  const { selectedCoupon, isWalletChecked, points, convertedPoints, secondaryArgs = {} } = _props;
  const [addedCartDetails, setAddedCartDetails] = useState<any>({});
  const { total_price = "", currency_code = "" } = addedCartDetails;
  const { t } = useTranslation();

  const pointsToRupees = points * convertedPoints;

  /**
   * cardId based get full add to card details
   * @param cartId string
   */
  const getCartIdUsedFullAddedItem = async (cartId: string | number) => {
    const response = await ecomCartIdBasedGetItem({
      cartId: cartId,
      secondaryArgs: secondaryArgs,
    });
    const {
      data: { data: { publish_getCartItems: { statusCode = 0, data = {} } = {} } = {} } = {},
    } = response;

    if (statusCode === 200) {
      setAddedCartDetails(data);
    } else {
      setAddedCartDetails({});
    }
  };

  useEffect(() => {
    const getCartIdFromLocal = localStorage.getItem("ecommerceCartId");
    if (getCartIdFromLocal) {
      getCartIdUsedFullAddedItem(getCartIdFromLocal);
    }
  }, []);

  return (
    <Box className={`cart-sub-total-section ${classes.subTotalwrapper} subTotalSection`}>
      <Box className='subtotal-row'>
        <Typography variant='p3regular' color='tertiaryParagraph'>{`${t("subtotal")}:`}</Typography>
        <ActualPrice
          variant='p3regular'
          price={total_price}
          currency={currency_code}
          color='tertiaryTitle'
        />
        {/* <Typography variant="p3regular">$ {sum}</Typography> */}
      </Box>
      {selectedCoupon ? (
        <Box className='subtotal-row'>
          <Typography variant='p3regular' color='tertiaryParagraph'>
            {`Coupon (${selectedCoupon.CouponCode})`}:
          </Typography>
          <ActualPrice
            variant='p3regular'
            price={selectedCoupon?.discount_on_total_price || 0}
            currency={currency_code}
            color='tertiaryTitle'
            isNegative={true}
          />
        </Box>
      ) : null}
      {isWalletChecked ? (
        <Box className='subtotal-row'>
          <Typography variant='p3regular' color='tertiaryParagraph'>
            Points:
          </Typography>
          <ActualPrice
            variant='p3regular'
            price={pointsToRupees}
            currency={currency_code}
            color='tertiaryTitle'
            isNegative={true}
          />
        </Box>
      ) : null}
      <Box className='subtotal-row'>
        <Typography variant='p3regular' color='tertiaryParagraph'>{`${t("shipping")}:`}</Typography>
        <Typography variant='p3regular' color='tertiaryParagraph'>
          $ 0
        </Typography>
      </Box>
      <Box className='subtotal-row'>
        <Typography variant='p2semibold' color='tertiaryTitle'>{`${t("total")}:`}</Typography>
        <ActualPrice
          variant='p2semibold'
          price={getFinalPrice(isWalletChecked, pointsToRupees, total_price, selectedCoupon)}
          currency={currency_code}
          color='tertiaryTitle'
        />
        {/* <Typography variant="p2semibold">$ {sum}</Typography> */}
      </Box>
    </Box>
  );
};

export default SubTotal;
