import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Slider from "../../../../components/Slider/Slider";
import { getCurrency } from "../../helperEcommerce";

const OrderRecived = ({ orderDetail, secondaryArgs }) => {
  const { t } = useTranslation();
  const settings = {
    autoplay: true,
    showThumb: false,
    animationType: "slideIn",
    arrowPosition: "inside",
    arrows: true,
    dots: false,
    speed: 10000,
    infinite: false,
  };
  const renderSlider = () => {
    return orderDetail?.lineItem?.map((item, i) => (
      <Box
        className='orderProductWrapper'
        key={i}
        component='a'
        href={`${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/ecommerce/product-detail?productId=${item?.productId}`}>
        <Grid item>
          <Box className='orderedProduct'>
            <img
              src={item?.attr_images?.[0]}
              alt='product'
              width='92'
              height='92'
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Box sx={{ ml: 1.2, mr: 4.5 }}>
            <Typography variant='p3medium' className='noMargin ellipsis-two-lines'>
              {item?.name}
            </Typography>
            <Typography variant='p4regular' className='noMargin labelColor'>
              {item?.ecomx_attributes_brand}
              {/* Size: 37 | Color: Beige */}
            </Typography>
            <Typography variant='p2semibold'>
              {getCurrency(orderDetail?.currency_code)}
              {item?.price}
            </Typography>
          </Box>
        </Grid>
      </Box>
    ));
  };
  return (
    <Box className='orderRecivedColumn'>
      <Typography variant='h2bold' color='tertiaryTitle' className='noMargin'>
        {t("order_received")}
      </Typography>
      <Typography variant='h5medium' sx={{ marginTop: "4px" }}>
        {t("thank_you")}
      </Typography>
      {orderDetail && orderDetail?.lineItem && orderDetail?.lineItem?.length > 1 ? (
        <Slider {...settings}>{renderSlider()}</Slider>
      ) : (
        renderSlider()
      )}
    </Box>
  );
};

export default OrderRecived;
