import InfoIcon from "@mui/icons-material/Info";
import { Button, styled, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

export const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    padding: "12px 20px",
    borderRadius: "4px",
    marginBottom: "14px",
    border: `1px solid ${theme.palette.customColor.LINE}`,
    "&:hover": {
      border: `1px solid ${theme.palette.customColor.PRIMARY}`,
    },
  },
  item1Container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "46px",
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
  },
  imgContainer: {
    marginRight: "18px",
    display: "flex",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "20px",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  ellipsisText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  },
  publishIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
    height: "46px",
  },
  userContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
    height: "46px",
    paddingLeft: "10px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const FlexBox = styled(Box)({
  display: "flex",
});
export const NavIconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "0",
});

export const NavGuideButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  marginTop: "25px",
});

export const NavMenuCreationContainer = styled(Box)({
  marginBottom: "14px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const MenuGuideButtonContainer = styled(Box)({
  width: "100%",
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 29px 16px 18px",
});

export const NavInfoIcon = styled(InfoIcon)({
  width: "20px",
  height: "20px",
  mr: "5px",
});

export const GuideButton = styled(Button)({
  minWidth: "219px",
  minHeight: "50px",
  fontSize: "14px",
  fontWeight: 500,
});
