import { Skeleton } from "@mui/material";

const CouponSkeleton = () => {
  return (
    <>
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <Skeleton
            variant='rectangular'
            key={index}
            width='100%'
            height={120}
            sx={{ marginBottom: 2, marginTop: 2, borderRadius: "8px" }}
          />
        ))}
    </>
  );
};

export default CouponSkeleton;
