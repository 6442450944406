import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { graphQlEcommerceList } from "../../../graphql/Ecommerce/fetch";
import { getCustomerEmail } from "../../../prelems/ZaggEcommerce/HelperFunction";
import ToastService from "../../ToastContainer/ToastService";
import OrderCard from "../Component/OrderCard/OrderCard";
import OrderDetail from "../Component/OrderDetail/OrderDetail";
import { useCustomStyle } from "./Order.style";

const Order = ({ secondaryArgs }) => {
  const classes = useCustomStyle();
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [orderHistoryData, setOrderHistoryData] = useState([]);

  const getOrderHistoryData = async () => {
    const {
      prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" },
      sitename = "",
    } = secondaryArgs;
    const { data, errors } = await graphQlEcommerceList.fetchOrderHistoryData(
      {
        customerEmail: getCustomerEmail(),
        pagination: { start: 0, rows: 5 },
        searchTerm: "",
        sort: "desc",
        purchasedFrom: "",
      },
      language,
      sitename,
      deliveryEndPointGq,
    );
    if (errors) {
      ToastService.failToast(errors.msg || "Error fetching order history data");
    } else {
      setOrderHistoryData(data?.publish_getOrderHistory?.data || []);
    }
  };

  useEffect(() => {
    getOrderHistoryData();
  }, []);

  const showOrderDetailHandler = () => {
    setShowOrderDetail(true);
  };

  return (
    <Box className={`${classes.OrderWrapper} OrderWrapperSection`}>
      {showOrderDetail ? (
        <OrderDetail />
      ) : (
        <>
          {orderHistoryData.map((order: any) => (
            <OrderCard
              key={order.orderNumber}
              order={order}
              clickHandler={showOrderDetailHandler}
              secondaryArgs={secondaryArgs}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default Order;
