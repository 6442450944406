import {
  getCartId,
  getCartList,
  getdefaultEventObj,
  getPipedData,
  getPipedFullAddress,
  getTotalQuantity,
  prepareObjectForRPI,
} from "./helper";
import { trackImpression } from "./rpiImpression";
import {
  ADD_TO_CART,
  BRIGHTCOVE_VIDEO_ACTION,
  BRIGHTCOVE_VIDEO_DETAILS,
  BUTTON_CLICK,
  CART_CHECKOUT,
  ECOM,
  MY_STORIES_FEED,
  PAYMENT_DETAIAL,
  PRODUCT_VIEW,
  RPI_TAG_SELECTION,
  UPDATE_PROFILE,
  USER_PROFILE_UPDATE,
} from "./trackingConstants";

/**
 * Tracks the "Product View" event.
 *
 * @param {Object} card - The product card object containing product details.
 */
export const trackProductView = (card, secondaryArgs) => {
  const { id, ecomx_name, ecomx_sale_price, attr_categories, ecomx_currency_code } = card;
  const template = {
    action: PRODUCT_VIEW?.eventName,
    content_type: ECOM,
    product_id: id,
    product_name: ecomx_name,
    product_price_per_unit: parseFloat(ecomx_sale_price)?.toFixed(2) ?? 0,
    product_category: JSON.stringify(attr_categories),
    currency: ecomx_currency_code,
    // quantity: 1,
    // total_price: ecomx_sale_price,
    ...getdefaultEventObj("product-detail", secondaryArgs),
  };

  const eventDetail = {
    ...PRODUCT_VIEW,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail, secondaryArgs, template); // Track the event
};

/**
 * Tracks the "Add to Cart" event.
 *
 * @param {Object} card - The product card object containing product details.
 * @param {string} containerType - The type of container where the event occurred.
 */
export const trackAddToCart = (data, secondaryArgs) => {
  const {
    id = "",
    ecomx_name = "",
    ecomx_sale_price = "",
    ecomx_currency_code = "",
    attr_categories = "",
    cartQuantity = 1,
  } = data;
  const template = {
    action: ADD_TO_CART?.eventName,
    cart_id: getCartId(),
    content_type: ECOM,
    product_id: id,
    product_name: ecomx_name,
    product_quantity: cartQuantity ? cartQuantity : 1,
    product_price_per_unit: parseFloat(ecomx_sale_price)?.toFixed(2) ?? 0,
    currency: ecomx_currency_code,
    total_value: parseFloat((ecomx_sale_price * cartQuantity)?.toFixed(2)) ?? 0,
    product_category: JSON.stringify(attr_categories),
    ...getdefaultEventObj("product-detail", secondaryArgs),
  };

  const eventDetail = {
    ...ADD_TO_CART,
    stateValue: cartQuantity,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail, secondaryArgs, template); // Track the event
};

/**
 * Tracks the "Checkout" event.
 *
 * @param {Object} card - The cart object containing checkout details.
 */
export const trackCheckout = (card, secondaryArgs) => {
  const { line_item, total_price, currency_code } = card;
  const template = {
    action: CART_CHECKOUT?.eventName,
    cart_id: getCartId(),
    content_type: ECOM,
    cart_total_items: getTotalQuantity(line_item) || 0,
    cart_total_amount: parseFloat(total_price)?.toFixed(2) ?? 0,
    currency: currency_code,
    cart_list: getCartList(line_item),
    ...getdefaultEventObj("cart-list", secondaryArgs),
  };

  const eventDetail = {
    ...CART_CHECKOUT,
    stateValue: card?.line_item?.length || 0,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail, secondaryArgs, template); // Track the event
};

export const trackPaymentDetail = (card, secondaryArgs) => {
  const {
    line_items,
    shipping_address,
    billing_address,
    paymentMethod,
    order_id,
    order_number,
    currency_code,
    total_price,
  } = card;
  const template = {
    action: PAYMENT_DETAIAL?.eventName,
    cart_id: getCartId(),
    content_type: ECOM,
    transaction_id: "",
    order_id: order_id,
    order_number: order_number,
    payment_method: paymentMethod,
    shipping_address: getPipedFullAddress(shipping_address),
    shipping_email: shipping_address?.email,
    shipping_phone_number: shipping_address?.mobile,
    billing_address: getPipedFullAddress(billing_address),
    billing_email: billing_address?.email,
    billing_phone_number: billing_address?.contactNumber,
    // shipping_address: getFullAddress(card),
    // billing_aaddress: getFullAddress(card),
    cart_total_items: getTotalQuantity(line_items),
    shipping_cost: 0,
    estimated_tax: 0,
    discount_detail: "",
    //JSON --> points: //[discount_type --> discount_value --> conversion_value --> points ]-->
    //coupon: discount_type, discount_value, issued_reward_id, coupon_code
    cart_total_amount: parseFloat(total_price).toFixed(2) ?? 0, //Full amount of cart
    total_amount: parseFloat(total_price).toFixed(2) ?? 0, //Amount after shipping/discount/tax
    currency: currency_code,
    discount_amount: "", //point + coupon
    cart_list: getCartList(line_items),
    ...getdefaultEventObj("payment", secondaryArgs),
  };

  const eventDetail = {
    ...PAYMENT_DETAIAL,
    stateValue: card?.line_item?.length || 0,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail, secondaryArgs, template); // Track the event
};

//Tag selection tracking
export const trackRPITagSelection = (data, secondaryArgs) => {
  const { container_type, tags } = data;
  const template = {
    action: BUTTON_CLICK,
    button_name: MY_STORIES_FEED,
    container_type: container_type,
    my_story_preferences: getPipedData(tags),
    destination_url: "",
    ...getdefaultEventObj(container_type, secondaryArgs),
  };

  const eventDetail = {
    ...RPI_TAG_SELECTION,
    stateValue: 1,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail, secondaryArgs, template); // Track the event
};

//User save profile - rendering
export const trackUserProfileSaveRendering = (data, secondaryArgs) => {
  const { container_type, pageName } = data;
  const template = {
    action: BUTTON_CLICK,
    button_name: UPDATE_PROFILE,
    container_type: container_type,
    destination_url: "",
    ...getdefaultEventObj(pageName, secondaryArgs),
  };

  const eventDetail = {
    ...USER_PROFILE_UPDATE,
    stateValue: 1,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail, secondaryArgs, template); // Track the event
};

/** Track brightcove video details */

export const trackbrightcove = (
  actionType,
  prelemTitle,
  tags,
  videoId,
  streamTime,
  videoLength,
  author,
  secondaryArgs,
) => {
  const template = {
    action: BRIGHTCOVE_VIDEO_ACTION?.[actionType],
    author: author,
    content_type: prelemTitle ? "Page" : "Video",
    card_name: "",
    tags: getPipedData(tags),
    container_type: prelemTitle ? "Page" : "Video",
    content_status: "free",
    media_id: Array.isArray(videoId) && videoId?.length > 0 ? videoId?.[0] || "" : videoId,
    media_type: "video",
    stream_time: streamTime,
    video_length: videoLength,
    ...getdefaultEventObj(null, secondaryArgs),
  };

  const eventDetail = {
    ...BRIGHTCOVE_VIDEO_DETAILS,
    eventName: BRIGHTCOVE_VIDEO_ACTION?.[actionType],
    stateValue: 0,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail, secondaryArgs, template); // Track the event
};
