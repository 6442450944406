import Timeline from "@mui/lab/Timeline";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import { Button, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { conCatUrlPath, encodeGetParams } from "@platformx/utilities";
import { format } from "date-fns";
import DOMPurify from "isomorphic-dompurify";
import { useTranslation } from "react-i18next";
import "./BlogContent.css";
import "./Blogs.css";
import BlogShareDialogBox from "./BlogShareDialogBox";

const cursorPointHandle = (item: any) => {
  const array = ["video/", "poll/", "quiz/", "article/", "events/", "event/"];
  const { item_path = [] } = item;
  if (item_path?.length > 0) {
    const type = conCatUrlPath(item_path[0]?.content_type);
    if (array.includes(type)) {
      return "pointer";
    }
    return "default";
  }
  return "default";
};

function BlogContent(props: any) {
  const { t } = useTranslation();
  const { getEventUrl = "", secondaryArgs = {}, selectedBlogDetail = [] } = props;

  const timeSince = (indate: any) => {
    const date = new Date(indate);
    const curDate = new Date();

    const seconds = Math.floor((curDate.getTime() - date.getTime()) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + "years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "d ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "h ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "m ago";
    }
    return Math.floor(seconds) + "s ago";
  };

  const handleContentTypeNavigate = (e: any, item: any) => {
    let { target } = e;
    while (target && target !== e.currentTarget) {
      if (target.classList.contains("contentTypeBox")) {
        const { item_path = [] } = item;
        if (item_path?.[0]?.content_type) {
          const type = conCatUrlPath(item_path[0]?.content_type);
          let id = item_path[0]?.path;
          if (id && id.charAt(0) === "/") {
            id = id.substring(1);
          }
          const ContentUrl = `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/${type}${id}`;
          window.open(ContentUrl, "_self");
        }
        break;
      }
      target = target.parentElement;
    }
  };

  return (
    <Box className='blogContent'>
      <Box
        className='contentArea'
        sx={{
          overflowX: "none",
        }}
        id='scrollableDiv'>
        {selectedBlogDetail?.length > 0 ? (
          <Timeline
            sx={{ margin: 0 }}
            className='listItemWrapper'
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}>
            {selectedBlogDetail.map((item: any, index: number) => {
              const fbShareId = encodeGetParams({ blogShareId: item?._id });
              return (
                <>
                  <TimelineItem className='blogTimelineItem' key={item._id} id={item._id}>
                    <TimelineContent sx={{ display: "flex", padding: 0 }}>
                      <Box
                        sx={{
                          flexGrow: 0,
                          width: { xs: "30%", md: "20%", em: "20%" },
                        }}>
                        {item.created_date ? (
                          <>
                            <Typography variant='h5medium' component='p' className='description'>
                              {timeSince(item.created_date)}
                            </Typography>

                            <Typography variant='h7medium' component='p' className='description'>
                              {format(new Date(item.created_date), "MMM d, yyyy")}
                            </Typography>
                          </>
                        ) : null}
                      </Box>
                      <Box
                        sx={{
                          flexGrow: "1",
                          width: { xs: "60%", md: "75%", em: "70%" },
                        }}>
                        {item?.key_highlighter?.[0]?.highlighter ? (
                          <Box
                            sx={{
                              display: "flex",
                              columnGap: "5px",
                              alignItems: "center",
                              paddingBottom: "8px",
                            }}>
                            <>
                              {item.key_highlighter[0].highlighter ? (
                                <Typography
                                  variant='h7medium'
                                  component='p'
                                  className='description'
                                  key={item.key_highlighter[0]._id}>
                                  {item.key_highlighter[0].highlighter}
                                </Typography>
                              ) : null}
                              {item.key_highlighter[0]?.time ? (
                                <>
                                  <span className='dotSeprator'></span>
                                  <Typography
                                    variant='h7medium'
                                    component='p'
                                    className='description'>
                                    {format(
                                      new Date(item.key_highlighter[0]?.time),
                                      "MMM dd, yyyy | H:mm",
                                    )}
                                  </Typography>
                                </>
                              ) : null}
                            </>
                          </Box>
                        ) : null}
                        {item?.title ? (
                          <Typography
                            variant='h5medium'
                            component='p'
                            className='heading'
                            sx={{
                              wordBreak: "break-all",
                              paddingBottom: "8px",
                              // marginTop: { xs: "15px", md: "0" },
                            }}>
                            {item?.title}
                          </Typography>
                        ) : null}
                        {item?.authors[0] ? (
                          <>
                            <Typography
                              variant='p4regular'
                              component='q'
                              className='description'
                              sx={{
                                fontStyle: "italic",
                                wordBreak: "break-all",
                                "&::before": {
                                  content: "open-quote",
                                },
                                span: {
                                  display: "contents",
                                },
                              }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(item?.description),
                              }}></Typography>
                            <Typography
                              variant='h7medium'
                              component='p'
                              className='heading'
                              sx={{
                                wordBreak: "break-all",
                                textAlign: "right",
                                fontStyle: "italic",
                              }}>
                              {`— ${item?.authors[0]}`}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              onClick={(e) => handleContentTypeNavigate(e, item)}
                              variant='p4regular'
                              component='p'
                              className='description'
                              data-testid='contentType-navigate'
                              sx={{
                                cursor: cursorPointHandle(item),
                                // color: "#5c6574",
                                wordBreak: "break-all",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(item?.description),
                              }}></Typography>
                            {item.cta_title && item.cta_url && (
                              <Button href={item.cta_url} variant='tertiaryButton2'>
                                {item.cta_title}
                              </Button>
                            )}
                          </>
                        )}
                        {item?.embeded?.[0]?.code ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.embeded[0].code,
                              // __html: `<iframe src="https://platx-publish-dev.fanuep.com/en/embed/article/crop-final-check" width="360" height="203" style="border:none;overflow:hidden"></iframe>`
                            }}></div>
                        ) : null}
                      </Box>
                      <Box
                        sx={{
                          flexGrow: "0",
                          paddingLeft: "5px",
                          // marginTop: { xs: "25px", md: "0 !important" },
                          width: "10%",
                          textAlign: "right",
                          cursor: "pointer",
                        }}>
                        {/* share */}
                        <BlogShareDialogBox shareUrl={`${getEventUrl}?${fbShareId}`} />
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                  <Divider
                    className={`${
                      selectedBlogDetail?.length - 1 === index ? "lastLine" : ""
                    } horizontalDivider`}
                  />
                </>
              );
            })}
          </Timeline>
        ) : (
          <Box className='noresultFound'>
            <Typography variant='h5regular' className='message'>
              {`${t("no_results_found")}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

BlogContent.defaultProps = {
  secondaryArgs: {
    prelemBaseEndpoint: {
      PublishEndPoint: "https://health.care.hcl-x.com/",
      language: "en",
    },
    gcpUrl: "https://storage.googleapis.com",
    bucketName: "cropped_image_public",
  },
};

export default BlogContent;
