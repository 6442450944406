import { postRestApiCall } from "@platformx/utilities";
import axios from "axios";
import ToastService from "../../components/ToastContainer/ToastService";
import { fetchEcomProducts } from "../../prelems/ZaggEcommerce/HelperFunction";
import { trackAddToCart } from "../../utils/tracking/rpiTracking";

export const getCartId = (secondaryArgs: any = {}) => {
  const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename } =
    secondaryArgs;
  const data = JSON.stringify({
    query: `mutation {publish_addProductToCart(input: {initialize:true})}`,
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
};

/**
 * product add to cart
 */
export const ProductAddOnReplacementToCart = (ele: any) => {
  const { secondaryArgs = {}, cartId = "", id = "", quantity = "", ecomx_variant_id = 1 } = ele;
  const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `mutation { publish_addProductToCart(input: { cart_id: ${JSON.stringify(
      cartId,
    )}, line_item: { product_id: ${JSON.stringify(
      id,
    )}, variant_id: ${ecomx_variant_id}, quantity: ${JSON.stringify(quantity)}}})}`,
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
};

const ecomProductAddToCart = (ele: any) => {
  const { secondaryArgs = {}, cartId = "", id = "", quantity = "", ecomx_variant_id = 1 } = ele;
  const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `mutation { publish_addProductToCart(input: { cart_id: ${JSON.stringify(
      cartId,
    )}, line_item: { product_id: ${JSON.stringify(
      id,
    )}, variant_id: ${ecomx_variant_id}, quantity: ${JSON.stringify(quantity)}}})}`,
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
};

/**
 * add to cart process
 * @param cartId string | number
 */

const addToCartProcess = async (
  cartId: string | number,
  productId: string,
  cartQuantity: string | number,
  secondaryArgs: any,
  productFullDetails: any,
  fromListing = false,
  ecomx_variant_id = 1,
) => {
  const response = await ecomProductAddToCart({
    id: productId,
    cartId: cartId,
    quantity: cartQuantity,
    secondaryArgs: secondaryArgs,
    ecomx_variant_id: ecomx_variant_id,
  });
  const { data: { data: { publish_addProductToCart = {} } = {} } = {} } = response;
  const { statusCode = 0, msg = "" } = publish_addProductToCart;
  if (statusCode === 200) {
    trackAddToCart(productFullDetails, secondaryArgs);
    if (fromListing) {
      window.location.href = `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/ecommerce/cart-list`;
    }
    ToastService.SuccessToast((msg || "").charAt(0).toUpperCase() + msg.slice(1));
    return true;
  } else {
    ToastService.failToast(msg);
    return false;
  }
};

/**
 * generate cartId
 * @param secondaryArgs object
 * @returns object
 */

export const addToCartGetCartId = async (
  secondaryArgs: any,
  productId: string,
  cartQuantity: string | number,
  fromListing?: boolean,
  productFullDetails?: any,
  msg?: string,
) => {
  const { ecomx_variant_id = 1 } = productFullDetails;
  const getCartIdFromLocal = localStorage.getItem("ecommerceCartId");
  if (!getCartIdFromLocal) {
    //no cartId in local generate new one
    const responseCartId = await getCartId(secondaryArgs); //get cartId
    const {
      data: {
        data: { publish_addProductToCart: { cartId = "", statusCode = 0 } = {} } = {},
        error = null,
      } = {},
    } = responseCartId;
    if (statusCode === 200) {
      localStorage.setItem("ecommerceCartId", cartId);
      const res = await addToCartProcess(
        cartId,
        productId,
        cartQuantity,
        secondaryArgs,
        productFullDetails,
        fromListing,
        ecomx_variant_id,
      ); //add to cartProcess
      return res;
    } else if (statusCode === 404 || error) {
      localStorage.removeItem("ecommerceCartId");
      ToastService.failToast(msg);
    } else {
      ToastService.failToast(msg);
    }
  } else {
    const res = await addToCartProcess(
      getCartIdFromLocal,
      productId,
      cartQuantity,
      secondaryArgs,
      productFullDetails,
      fromListing,
      ecomx_variant_id,
    ); //add to cartProcess
    return res;
  }
};

export const getProductDetails = (
  secondaryArgs: any,
  rows: string | number,
  start: string | number,
  value: string[],
  key: string,
  fromListing = false,
) => {
  const { prelemBaseEndpoint: { deliveryEndPointGq = "" } = {}, sitename = "" } = secondaryArgs;

  const getLocalData = localStorage.getItem("ecommerceQuery");
  const {
    searchTerm = "",
    tags = [],
    ecommerceRequest: { filter = [] } = {},
  }: any = getLocalData ? JSON.parse(JSON.parse(getLocalData)) : {};
  const attributevalue = value.map((item) => {
    if (item.includes("#", 0)) {
      return item.replace("#", "%23");
    }
    return item;
  });
  let queryparam;
  const obj1: String = `{pagination:{start:${start},rows:${rows}},searchTerm:${JSON.stringify(
    searchTerm,
  )},tags:${JSON.stringify(
    tags,
  )},filter:"Ecommerce",isSuggestive:false,ecommerceRequest:{filter:${JSON.stringify(
    filter,
  )},attributes:[{key:${JSON.stringify(key)},value:${JSON.stringify(attributevalue)}}]}}`;

  const obj2: String = `{pagination:{start:${start},rows:${rows}},searchTerm:${JSON.stringify(
    searchTerm,
  )},tags:${JSON.stringify(
    tags,
  )},filter:"Ecommerce",isSuggestive:false,ecommerceRequest:{filter:${JSON.stringify(filter)}}}`;

  if (fromListing) {
    queryparam = obj2;
  } else {
    queryparam = obj1;
  }
  // return getRestApiCall(
  //   `${deliveryEndPoint}api/v1/web/en/delivery/getEcomProducts?queryParam=${queryparam}`,
  //   language,
  //   sitename,
  // );
  return fetchEcomProducts(deliveryEndPointGq, queryparam, sitename);
  //Use for local testing.
  // return getRestApiCall(
  //   `https://dev.delivery.hcl-x.com/platform-x/api/v1/web/en/delivery/getEcomProducts?queryParam=${queryparam}`,
  //   "en",
  //   "delhiuniversity",
  // );
};

/**
 * cartId based get full details
 */
export const ecomCartIdBasedGetItem = async (ele: any) => {
  try {
    const { secondaryArgs = {}, cartId = "" } = ele;
    const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename = "" } =
      secondaryArgs;

    const data = JSON.stringify({
      query: `query { publish_getCartItems(cartId:${JSON.stringify(cartId)}) } `,
    });
    return await postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
  } catch (err) {
    console.error(err);
    localStorage.removeItem("ecommerceCartId");
  }
};

/**
 * shipping address
 * @param ele object
 * @returns object
 */
export const proceedToShippingAddress = (ele: any) => {
  const { secondaryArgs = {}, newObj = {} } = ele;
  const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename = "" } =
    secondaryArgs;

  const {
    city = "",
    // state = "",
    email = "",
    cartId = "",
    pincode = "",
    address = "",
    landmark = "",
    lastName = "",
    firstName = "",
    alterNumber = "",
    contactNumber = "",
  } = newObj;
  const additionalAddressInfoData = landmark + " " + alterNumber;
  const addressObj = `{ title: ${JSON.stringify(firstName)}, last_name: ${JSON.stringify(
    lastName,
  )}, street_name: ${JSON.stringify(address)}, postal_code: ${JSON.stringify(
    pincode,
  )}, city: ${JSON.stringify(city)}, state: ${JSON.stringify(
    "Alabama",
  )}, country: "US", mobile: ${JSON.stringify(contactNumber)}, email: ${JSON.stringify(
    email,
  )}, additional_address_info: ${JSON.stringify(additionalAddressInfoData)} }`;
  const data = JSON.stringify({
    query: `mutation { publish_addProductToCart(input: { cart_id: ${JSON.stringify(
      cartId,
    )}, address: {shipping_address:${addressObj}}})}`,
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
};

// place order
export const placeOrder = async (ele: any) => {
  const {
    secondaryArgs = {},
    cartId = "",
    userId = "",
    total_price = "",
    points,
    member_id,
    point_conversion_amount,
  } = ele;
  const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename = "" } =
    secondaryArgs;
  if (userId && total_price) {
    const data = JSON.stringify({
      query: `mutation { publish_addProductToCart(input: { cart_id: ${JSON.stringify(
        cartId,
      )}, user_id: ${JSON.stringify(userId)}, cart_total: ${JSON.stringify(
        total_price,
      )}, points: ${JSON.stringify(points)}, member_id: ${JSON.stringify(member_id)}, point_conversion_amount: ${JSON.stringify(point_conversion_amount)},
       place_order: true })}`,
    });
    return await postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
  } else {
    const data = JSON.stringify({
      query: `mutation { publish_addProductToCart(input: { cart_id: ${JSON.stringify(
        cartId,
      )},  place_order: true })}`,
    });
    return await postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
  }
};

export const addPaymentMethod = async (ele: any) => {
  const { secondaryArgs = {}, cartId = "" } = ele;
  const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename = "" } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `mutation { publish_addProductToCart(input: { cart_id: ${JSON.stringify(
      cartId,
    )}, payment_method:"COD" })}`,
  });
  return await postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
};

export const proceedToBillingAddress = (ele: any) => {
  const { secondaryArgs = {}, newObj = {} } = ele;
  const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename = "" } =
    secondaryArgs;

  const {
    city = "",
    // state = "",
    email = "",
    cartId = "",
    pincode = "",
    address = "",
    lastName = "",
    firstName = "",
    contactNumber = "",
    additionalAddressInfoData = "",
  } = newObj;
  const addressObj = `{ title: ${JSON.stringify(firstName)}, last_name: ${JSON.stringify(
    lastName,
  )}, street_name: ${JSON.stringify(address)}, postal_code: ${JSON.stringify(
    pincode,
  )}, city: ${JSON.stringify(city)}, state: ${JSON.stringify(
    "Alabama",
  )}, country: "US", mobile: ${JSON.stringify(contactNumber)}, email: ${JSON.stringify(
    email,
  )}, additional_address_info: ${JSON.stringify(additionalAddressInfoData)} }`;
  const data = JSON.stringify({
    query: `mutation { publish_addProductToCart(input: { cart_id: ${JSON.stringify(
      cartId,
    )}, address: {billing_address:${addressObj}}})}`,
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
};

export const updateQuantityOfCartItem = (ele: any) => {
  const { secondaryArgs = {}, cartId = "", lineItemId = "", quantity } = ele;
  const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `mutation {publish_updateLineItem(input:{cart_id:${JSON.stringify(
      cartId,
    )}, line_item_id:${JSON.stringify(lineItemId)}, quantity:${quantity}})}`,
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
};

export const removeCartItem = (ele: any) => {
  const { secondaryArgs = {}, cartId = "", lineItemId = "" } = ele;
  const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {}, sitename } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `mutation {publish_removeLineItem(input:{cart_id:${JSON.stringify(
      cartId,
    )}, line_item_id:${JSON.stringify(lineItemId)}})}`,
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, sitename);
};

/**
 *
 * @param lineItems object
 * @returns boolean
 */
export const lineItemsOutOfStockCheck = (lineItems: any) => {
  let inStock = true;
  lineItems.forEach((lineItem: any) => {
    if (!JSON.parse(lineItem?.ecomx_in_stock)) {
      inStock = false;
    }
  });
  return inStock;
};

export const IMPRESSIONS = {
  NA: "NA",
  ZERO: 0,
};

export const stringifyLineItem = (arr: any = []) => {
  const modifiedArray = arr.map((item: any) => {
    return {
      name: item?.ecomx_name,
      quantity: item?.ecomx_quantity,
      price: item?.ecomx_list_price,
    };
  });
  return JSON.stringify(modifiedArray);
};
//Remove After Zagg Demo
export const addLoyaltyPoints = async () => {
  const ApiEndPoint = "https://hcltech.eu-west-1.openloyalty-stage.io/api/DEFAULT/points/add";
  const OauthEndPoint = "https://hcltech.eu-west-1.openloyalty-stage.io/api/admin/login_check";
  const Username = "aatish.koul@hcl.com";
  const Password = "Qwaszx@123.";

  try {
    // Step 1: Get the access token
    const tokenResponse = await axios.post(OauthEndPoint, {
      username: Username,
      password: Password,
    });

    if (tokenResponse.status !== 200) {
      throw new Error("Failed to obtain access token");
    }

    const accessToken = tokenResponse.data.token;

    // Step 2: Prepare input data for loyalty points addition
    const inputDataForLoyalty = {
      transfer: {
        customer: "effe1377-e178-4d81-8349-ae057d65c379",
        comment: "Product Purchase points",
        points: 10,
        walletCode: "default",
      },
    };

    // Step 3: Make the API request to add loyalty points
    const loyaltyResponse = await axios.post(ApiEndPoint, inputDataForLoyalty, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (loyaltyResponse.status !== 200) {
      throw new Error("Failed to add loyalty points");
    }

    // Return points as a string
    return `${inputDataForLoyalty.transfer.points}`;
  } catch (error) {
    console.error("Error:", error);
    return "Error adding loyalty points";
  }
};

export const getCurrency = (type) => {
  if (type === "USD") {
    return "$";
  } else if (type === "INR") {
    return "₹";
  }
};

export const formatTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    month: "short", // 'short' for abbreviated month names (Jan, Feb, etc.)
    day: "2-digit", // '2-digit' for the day part
    year: "numeric", // 'numeric' for the full year (2024)
  };
  return date.toLocaleDateString("en-US", options);
};

export const getFullAddress = (addressObject) => {
  const address = `${addressObject?.street_name || ""},${addressObject?.city || ""},${addressObject?.state || ""},${addressObject?.postal_code || addressObject?.postalCode || ""},${addressObject?.country || ""}`;
  const resultArray = address.split(",").filter((item) => {
    return item.trim() !== "";
  });
  return resultArray.join(", ");
};

export const getFinalPrice = (isWalletChecked, pointsToRupees, total_price, selectedCoupon) => {
  const total = isWalletChecked
    ? total_price - pointsToRupees - (selectedCoupon?.discount_on_total_price || 0)
    : total_price - (selectedCoupon?.discount_on_total_price || 0);
  if (total >= 0) {
    return total;
  } else return 0;
};

export const getPointsUsed = (
  totalPrice,
  availablePoints,
  conversionValue,
  discountOnCoupon = 0,
) => {
  let pointsUsed = 0;
  //Gets points based on cart value and available points
  const pointsRequiredToBuy = Math.ceil((totalPrice - discountOnCoupon) / conversionValue);

  if (pointsRequiredToBuy > availablePoints) {
    pointsUsed = availablePoints;
  } else if (pointsRequiredToBuy <= availablePoints) {
    pointsUsed = pointsRequiredToBuy;
  }
  //Calculate values based on coupon discount
  // if (discountOnCoupon > 0) {
  //   pointsUsed = Math.round((totalPrice - discountOnCoupon) / conversionValue);
  // }
  return pointsUsed;
};

export const getFinalCartPrice = (
  total_price,
  availablePoints,
  conversionValue,
  isWalletChecked,
  discountOnCoupon = 0,
) => {
  const moneyFromWallet = isWalletChecked
    ? getPointsUsed(total_price, availablePoints, conversionValue, discountOnCoupon) *
      conversionValue
    : 0;
  const total = total_price - discountOnCoupon - moneyFromWallet;
  return total < 0 ? parseFloat("0").toFixed(2) : parseFloat(total.toString()).toFixed(2);
};
