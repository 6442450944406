import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RadialBar, RadialBarChart, ResponsiveContainer, Tooltip } from "recharts";
import { EXCLUDE_TRANSACTION, getPointsBreakout, monthNames } from "../../helperProfile";
import { useCustomStyle } from "./RadialChart.style";
import RadialChartskelton from "./RadialChartskelton";

// Define an array of colors
const colors = ["#B4DBFC", "#6EB8F9", "#3C91FF", "#2874F0", "#0F5FDC"];

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  t: (key: string) => string;
}

const CustomTooltip = ({ active, payload, t }: CustomTooltipProps) => {
  if (active && payload?.length) {
    return payload[0]?.payload?.event ? (
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          padding: "10px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}>
        <Typography variant='body2' fontWeight='bold'>
          {t("event")}: {payload[0]?.payload?.event}
        </Typography>
        <Typography variant='body2'>
          {t("point")}: {payload[0]?.payload?.pointsEarned}
        </Typography>
      </Box>
    ) : null;
  }
  return null;
};

const RadialChart = ({ height, secondaryArgs, userTotalEarnedPoints, profile }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const [pointsData, setPointsData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const processEvents = (events) => {
    const itemShown = 5;
    const filteredEvents = events.filter((item) => !EXCLUDE_TRANSACTION.includes(item.event));
    const sortedEvents = [...filteredEvents].sort((a, b) => b.pointsEarned - a.pointsEarned);
    const updatedEvents = sortedEvents.reduce((acc, item, index) => {
      if (index < itemShown - 1) {
        return [...acc, item];
      } else {
        const otherItem = acc[itemShown - 1];
        const updatedPoints =
          parseInt(otherItem?.pointsEarned ?? 0) + parseInt(item?.pointsEarned ?? 0);
        const updatedEventCount =
          parseInt(otherItem?.totalEventCount ?? 0) + parseInt(item?.totalEventCount ?? 0);
        const updatedOtherItem = {
          ...otherItem,
          event: "Others",
          pointsEarned: updatedPoints,
          totalEventCount: updatedEventCount,
          pointsDeducted: 0,
          value: updatedPoints,
        };
        const newArray = Object.assign([], acc, { [itemShown - 1]: updatedOtherItem });
        return newArray;
      }
    }, []);
    // .sort((a, b) => a.pointsEarned - b.pointsEarned);
    // const sorted = updatedEvents.sort((a, b) => a.pointsEarned < b.pointsEarned);
    // const sorted = [...updatedEvents]?.sort((a, b) => {
    //   if (a.event === "Others") return -1;
    //   if (b.event === "Others") return 1;
    //   return a.pointsEarned < b.pointsEarned ? -1 : a.pointsEarned > b.pointsEarned ? 1 : 0;
    // });

    // if (events.length > itemShown) {
    //   const pointEarned = events
    //     .slice(itemShown)
    //     .reduce((sum, event) => sum + event?.pointsEarned, 0);
    //   const totalEventCount = events
    //     .slice(itemShown)
    //     .reduce((sum, event) => sum + event?.totalEventCount, 0);
    //   const othersEvent = {
    //     event: "Others",
    //     pointsEarned: pointEarned,
    //     pointsDeducted: 0,
    //     totalEventCount: totalEventCount,
    //     value: pointEarned,
    //     fill: colors[itemShown + 1],
    //   };
    //   return [...events.slice(0, itemShown), othersEvent];
    // }
    return updatedEvents;
  };
  const getUserOLProfileAPI = async () => {
    try {
      const res = await getPointsBreakout({
        secondaryArgs: secondaryArgs,
        userEmail: profile?.email,
      });
      const { data: { data: { users_fetchMemberTransactions = {} } = {} } = {} } = res;
      if (users_fetchMemberTransactions?.length) {
        const addedOthers = processEvents(users_fetchMemberTransactions);
        const addOuterCircle = {
          event: "",
          pointsEarned: userTotalEarnedPoints,
          value: userTotalEarnedPoints,
          fill: "#fff",
        };
        const radialData = [...addedOthers, ...(addedOthers.length !== 1 ? [addOuterCircle] : [])];
        const dataWithColors = radialData?.map((item, index) => ({
          ...item,
          value: item?.pointsEarned,
          fill: item?.event === "" ? "#fff" : colors[colors.length - 1 - (index % colors.length)], //% colors.length
        }));
        setPointsData(dataWithColors);
        setLoading(false);
      } else {
        setPointsData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching pointsBreakout:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userTotalEarnedPoints >= 0 && profile?.member_id) {
      getUserOLProfileAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTotalEarnedPoints, profile?.member_id]);

  return (
    <Box className={`${classes.radialCharts} radialChartsSection`}>
      <Box className='radialChartWrapper'>
        <Typography variant='h4medium' className='noMargin'>
          {t("my_points_breakout")}
          {`: ${monthNames[new Date().getMonth()]}`}
        </Typography>
        {loading ? (
          <RadialChartskelton />
        ) : pointsData?.length > 0 ? (
          <>
            <Box>
              <ResponsiveContainer width={"100%"} height={height}>
                <RadialBarChart
                  cx='50%'
                  cy='50%'
                  innerRadius={pointsData?.length === 1 ? "70%" : "25%"}
                  outerRadius='100%'
                  barSize={10}
                  startAngle={90} // Start from the top
                  endAngle={-270} // Fill in clockwise direction
                  data={pointsData.slice().reverse()}>
                  <RadialBar
                    startAngle={180}
                    endAngle={360}
                    background
                    dataKey='value'
                    minPointSize={1}
                  />
                  <Tooltip content={<CustomTooltip t={t} />} />
                </RadialBarChart>
              </ResponsiveContainer>
            </Box>
            <Box className='radialChartTextWrapper'>
              {pointsData?.map((item) => {
                if (
                  item?.pointsEarned !== userTotalEarnedPoints ||
                  (pointsData.length === 1 && item?.pointsEarned === userTotalEarnedPoints)
                ) {
                  return (
                    <Box key={item?.event} className='events'>
                      <Typography
                        variant='p4regular'
                        className='noMargin one-line-ellipsis labelcolor'>
                        {item.event}
                      </Typography>
                      <Typography component='span' variant='h4bold' className='noMargin points'>
                        {item.pointsEarned}
                        <Typography
                          component='span'
                          variant='h6bold'
                          className='noMargin subpoints'>
                          Pts
                        </Typography>
                      </Typography>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          </>
        ) : (
          <Box className='noresultFound'>
            <Typography variant='h5regular' className='message'>
              {`${t("no_results_found")}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RadialChart;
