import { Box, Grid, Typography } from "@mui/material";
import { useCustomStyle } from "./OrderSummary.style";

const OrderSummary = () => {
  const classes = useCustomStyle();
  const Summary = () => {
    return (
      <Box className='OrderSummary'>
        {/* Subtotal */}
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1 }}>
          <Typography variant='h6bold' className='noTopMargin lessGap'>
            Subtotal
          </Typography>
          <Typography variant='h6bold' className='noTopMargin lessGap' sx={{ textAlign: "right" }}>
            $178
          </Typography>
        </Box>

        {/* Shipping & Handling Charges */}
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1 }}>
          <Typography variant='p4regular' className='noTopMargin lessGap labelColor'>
            Shipping & Handling Charges
          </Typography>
          <Typography variant='p4regular' className='noTopMargin lessGap'>
            $5
          </Typography>
        </Box>

        {/* Total Tax */}
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1 }}>
          <Typography variant='p4regular' className='noTopMargin lessGap labelColor'>
            Total Tax
          </Typography>
          <Typography variant='p4regular' className='noTopMargin lessGap'>
            $21
          </Typography>
        </Box>

        {/* Other Total */}
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1 }}>
          <Typography variant='h6bold' className='noTopMargin lessGap'>
            Other Total
          </Typography>
          <Typography variant='h6bold' className='noTopMargin lessGap' sx={{ textAlign: "right" }}>
            $304
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box className={`${classes.OrderSummaryWrapper} OrderSummaryWrapperSection`}>
      <Box className='orderCardDetail'>
        <Typography variant='h5medium' className='orderHeading'>
          Order Detail
        </Typography>
        <Box className='orderProductList'>
          <Grid item>
            <Box className='orderedProduct'>
              <img
                src='https://www.netmeds.com/images/product-v1/600x600/838245/vaseline_intensive_care_cocoa_glow_body_lotion_400_ml_105722_0_1.jpg'
                alt='product'
                style={{ maxWidth: "100%" }}
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box sx={{ marginLeft: 2 }}>
              <Typography
                variant='p3medium'
                className='noMargin'
                sx={{ display: "flex", justifyContent: "space-between" }}>
                Galliano Donna Womens Olsen Gem
                <Typography
                  variant='h5medium'
                  component='span'
                  className='noMargin'
                  sx={{ width: "140px", textAlign: "right" }}>
                  $309
                </Typography>
              </Typography>
              <Typography variant='p4regular' className='noMargin labelColor'>
                Size: 37 | Color: Beige
              </Typography>
              <Typography variant='p4regular' className='noMargin labelColor'>
                Quantity : 1
              </Typography>
            </Box>
          </Grid>
        </Box>
        <Box className='orderProductList'>
          <Grid item>
            <Box className='orderedProduct'>
              <img
                src='https://www.netmeds.com/images/product-v1/600x600/838245/vaseline_intensive_care_cocoa_glow_body_lotion_400_ml_105722_0_1.jpg'
                alt='product'
                style={{ maxWidth: "100%" }}
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box sx={{ marginLeft: 2 }}>
              <Typography
                variant='p3medium'
                className='noMargin'
                sx={{ display: "flex", justifyContent: "space-between" }}>
                Galliano Donna Womens Olsen Gem
                <Typography
                  variant='h5medium'
                  component='span'
                  className='noMargin'
                  sx={{ width: "140px", textAlign: "right" }}>
                  $309
                </Typography>
              </Typography>
              <Typography variant='p4regular' className='noMargin labelColor'>
                Size: 37 | Color: Beige
              </Typography>
              <Typography variant='p4regular' className='noMargin labelColor'>
                Quantity : 1
              </Typography>
            </Box>
          </Grid>
        </Box>
        <Summary />
      </Box>
    </Box>
  );
};
export default OrderSummary;
