import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrency, getFullAddress } from "../../helperEcommerce";

const OrderDetails = ({ orderDetail }) => {
  const { t } = useTranslation();
  // const subTotal = orderDetail?.lineItem.reduce((acc, item) => {
  //   return acc + item.total_price;
  // }, 0);

  const total = (
    parseFloat(orderDetail?.total_gross_amount) -
    parseFloat(orderDetail?.point_conversion_amount || 0)
  ).toFixed(2);
  const data = [
    {
      key: "Original Subtotal (Incl. taxes)",
      value: `${getCurrency(orderDetail?.currency_code)}${parseFloat(orderDetail?.original_order_subtotal || 0).toFixed(2)}`,
    },
    {
      key: t("total_tax"),
      value: `${getCurrency(orderDetail?.currency_code)}${orderDetail?.total_tax || 0}`,
    },
    { key: t("shipping_and_handling_charges"), value: "$0" },
    {
      key: "Discount",
      value: `- ${getCurrency(orderDetail?.currency_code)}${parseFloat(orderDetail?.discounted_incl_tax || 0).toFixed(2)}`,
    },
    {
      key: "Price",
      value: `${getCurrency(orderDetail?.currency_code)}${parseFloat(orderDetail?.subtotal_gross || 0).toFixed(2)}`,
    },
    {
      key: "Points Used",
      value: `- ${getCurrency(orderDetail?.currency_code)}${parseFloat(orderDetail?.point_conversion_amount || 0).toFixed(2)}`,
      hidden: orderDetail?.point_conversion_amount ? false : true,
    },
    {
      key: "Discount(Coupon)",
      value: `- ${getCurrency(orderDetail?.currency_code)}${parseFloat(orderDetail?.discount_on_total_price || 0).toFixed(2)}`,
      hidden: orderDetail?.discount_on_total_price ? false : true,
    },
    {
      key: t("other_total"),
      value: `${getCurrency(orderDetail?.currency_code)}${total}`,
    },
  ];
  const isLastItem = (index) => {
    if (index && data && index === data?.length) {
      return true;
    }
    return false;
  };
  const getFormattedAddress = (keyName) => {
    const address = keyName in orderDetail ? orderDetail[keyName] : orderDetail["shipping_address"];
    return (
      <>
        {/* Name */}
        <Typography variant='p3bold' className='noMargin'>
          {address?.title} {address?.last_name}
        </Typography>
        <Typography variant='p4medium' className='noMargin description'>
          {getFullAddress(address)}
        </Typography>
        <Typography variant='p4medium' className='noMargin'>
          {`Landmark: ${address?.additional_address_info?.trim() || "-"}`}
        </Typography>
        <Typography variant='p4medium' className='noMargin'>
          {`Contact Number: ${address?.mobile || "-"}`}
        </Typography>
      </>
    );
  };

  const url = new URL(window?.location?.href);
  const orderId = url.searchParams.get("order_id");

  return (
    <Grid container>
      <Grid item xs={12} md={5} em={7} lg={7}>
        <Typography variant='h2regular' color='tertiaryTitle'>
          {t("order_details")}
        </Typography>
        <Typography variant='p4bold' color='tertiaryAnchorLink' className='noMargin'>
          {t("order_id")} : {orderId}
        </Typography>
        <TableContainer sx={{ mt: 2, mb: 4 }}>
          <Table aria-label='Product Details' size='small' className='productOrderDetail'>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index} sx={{ display: row.hidden ? "none" : "table-row" }}>
                  <TableCell component='th' scope='row' sx={{ paddingLeft: 0 }}>
                    <Typography
                      variant={isLastItem(index + 1) ? "h5bold" : "h5regular"}
                      className='noMargin'>
                      {row.key}
                    </Typography>
                  </TableCell>
                  <TableCell align='right' sx={{ paddingRight: 0 }}>
                    <Typography
                      variant={isLastItem(index + 1) ? "p3bold" : "p3regular"}
                      className='noMargin'>
                      {row.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={7} em={5} lg={5}>
        <Box className='address'>
          <Box className='shippingAddress'>
            <Typography variant='labelregular' className='subheading'>
              {t("shipping_details")}
            </Typography>
            {getFormattedAddress("shippingAddress")}
          </Box>
          <Box className='billingAddress'>
            <Typography variant='labelregular' className='subheading'>
              {t("billing_details")}
            </Typography>
            {getFormattedAddress("billing_address")}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderDetails;
