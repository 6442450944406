import { Box, Typography } from "@mui/material";
import { ErrorFound } from "@platformx/shared/static-assets";

const FetchError = ({ message }) => {
  return (
    <Box className='nodataOrError'>
      <Typography variant='h5regular' color='error' className='title'>
        {message}
      </Typography>
      <img src={ErrorFound} alt='Api failed' style={{ width: "100%" }} />
    </Box>
  );
};

export default FetchError;
