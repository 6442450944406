import { Container } from "@mui/material";
import { lazy, Suspense } from "react";
import ContentSection from "./ContentSection/ContentSection";
import HeroSection from "./HeroSection/HeroSection";

const RecentCarousel = lazy(() => import("../../components/RecentCarousel/RecentCarousel"));
const PlayerDetail = ({ content, analytics, authoringHelper, secondaryArgs = {} }: any) => {
  const settings = {
    dotPosition: "outside",
    arrows: true,
    animationType: "slideIn",
    arrowPosition: "outside",
    itemsPerRow: {
      lg: 4,
      em: 3,
      md: 2,
      sm: 2,
      xs: 1,
    },
  };
  return (
    <>
      <HeroSection
        content={content}
        analytics={analytics}
        authoringHelper={authoringHelper}
        secondaryArgs={secondaryArgs}
      />
      <ContentSection
        content={content}
        analytics={analytics}
        authoringHelper={authoringHelper}
        secondaryArgs={secondaryArgs}
      />
      <Container className='grid_container prelem-py'>
        <Suspense fallback=''>
          <RecentCarousel
            settings={settings}
            heading='Latest Content'
            filter='Article'
            secondaryArgs={secondaryArgs}
            pagingRows={12}
            tags={content?.title ? [content?.title] : []}
          />
        </Suspense>
      </Container>
    </>
  );
};
PlayerDetail.defaultProps = {
  content: {
    title: "George",
  },
  secondaryArgs: {
    gcpUrl: "https://storage.googleapis.com",
    bucketName: "cropped_image_public",
    prelemBaseEndpoint: {
      language: "en",
    },
  },
};
export default PlayerDetail;
