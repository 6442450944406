import { Box, Button } from "@mui/material";

const ActionButtons = () => {
  return (
    <Box className='buttonWrapper'>
      <Button
        variant='tertiaryButton2'
        className='sm'
        type='button'
        sx={{ width: "100%", marginTop: 0 }}>
        Product Review
      </Button>
      <Button
        variant='tertiaryButton2'
        className='sm'
        type='button'
        sx={{ width: "100%", marginTop: 0 }}>
        Return or Replace Item
      </Button>
      <Button
        variant='tertiaryButton2'
        className='sm'
        type='button'
        sx={{ width: "100%", marginTop: 0 }}>
        Leave Seller Feedback
      </Button>
    </Box>
  );
};

export default ActionButtons;
