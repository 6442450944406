import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../../utils/helper";
import { ecomCartIdBasedGetItem } from "../helperEcommerce";
import ProductListSlider from "../ProductDetail/ProductListSlider";
import "./Cart.css";
import { useCustomStyle } from "./Cart.style";
import CartTotal from "./SharedComponent/CartTotal";
import ShoppingList from "./SharedComponent/ShoppingList";

const Cart = ({ secondaryArgs = {}, cartCountUpdate = () => {} }: any) => {
  const classes = useCustomStyle();
  const [addedCartDetails, setAddedCartDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const { t, i18n } = useTranslation();
  /**
   * cardId based get full add to card details
   * @param cartId string
   */
  const getCartIdUsedFullAddedItem = async (cartId: string | number, refetch = false) => {
    if (!refetch) {
      setLoading(true);
    }
    setRefetchLoading(true);
    const response = await ecomCartIdBasedGetItem({
      cartId: cartId,
      secondaryArgs: secondaryArgs,
    });
    setRefetchLoading(false);
    const {
      data: { data: { publish_getCartItems: { statusCode = 0, data = {} } = {} } = {} } = {},
    } = response;

    if (statusCode === 200) {
      setAddedCartDetails(data);
      cartCountUpdate(data);
    } else if (statusCode === 400) {
      localStorage.removeItem("ecommerceCartId");
      setAddedCartDetails({});
    } else {
      setAddedCartDetails({});
    }

    if (!refetch) {
      setLoading(false);
    }
  };

  const backToShopping = () => {
    window.location.href = `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/ecommerce/product-listing`;
  };

  useEffect(() => {
    const getCartIdFromLocal = localStorage.getItem("ecommerceCartId"); //passing to user experince
    if (getCartIdFromLocal) {
      getCartIdUsedFullAddedItem(getCartIdFromLocal);
    } else {
      cartCountUpdate(null);
    }
    if (typeof window !== "undefined") {
      i18n.changeLanguage(secondaryArgs?.prelemBaseEndpoint?.language);
    }
  }, []);

  const productId = addedCartDetails?.line_item?.[0]?.product_id || "";
  const userId = getUserInfo("user_id");

  return (
    <Box
      className={`cart-product-list-wrapper prelem-py ${classes.cartDetailWrapper} cartDetailListPage`}>
      <Container className='grid_container'>
        <Grid container xs={12} em={12}>
          <Grid item xs={12} md={12} em={8} lg={8.5} xl={9}>
            <ShoppingList
              secondaryArgs={secondaryArgs}
              loadCart={getCartIdUsedFullAddedItem}
              loading={loading}
              addedCartDetails={addedCartDetails}
              refetchLoading={refetchLoading}
            />
            <Box className={`button-wrapper buttonWrapper`}>
              <Button
                type='button'
                onClick={() => backToShopping()}
                data-testid='cart-shopping'
                startIcon={<ChevronLeft />}
                variant='ecommerceLinkButton1'
                disableRipple
                className='continueBtn'>
                <Typography variant='p3regular' color='darkText' component='span'>
                  {t("continue_shopping")}
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            em={4}
            lg={3.5}
            xl={3}
            className='cart-total-summary'
            sx={{
              padding: 0,
            }}>
            <CartTotal
              addedCartDetails={addedCartDetails}
              secondaryArgs={secondaryArgs}
              refetchLoading={refetchLoading}
            />
          </Grid>
        </Grid>
        {productId ? (
          <Grid item xs={12} className='crosSellRow'>
            <ProductListSlider
              title={t("you_might_be_also_interested_in")}
              productId={productId}
              secondaryArgs={secondaryArgs}
              selectedPersonalisation={{ "personalisation-value": "crosssellproducts" }}
            />
            <ProductListSlider
              title={t("you_may_also_like")}
              productId={productId}
              secondaryArgs={secondaryArgs}
              selectedPersonalisation={{ "personalisation-value": "upsellproducts" }}
            />
            {userId ? (
              <ProductListSlider
                title={t("recent_viewed")}
                secondaryArgs={secondaryArgs}
                selectedPersonalisation={{ "personalisation-value": "recentlyviewed" }}
              />
            ) : (
              <ProductListSlider
                title={t("related_products")}
                productId={productId}
                secondaryArgs={secondaryArgs}
                selectedPersonalisation={{ "personalisation-value": "relatedproducts" }}
              />
            )}
          </Grid>
        ) : null}
      </Container>
    </Box>
  );
};

export default Cart;
