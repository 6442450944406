/* eslint-disable no-unused-vars */
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Button, CardMedia, Typography } from "@mui/material";
import { PlatFormXDateTimeFormat, getImage, onClickCardUrlNavigate } from "@platformx/utilities";
import { getIcon } from "../../Utils/helperFns";
import { useCustomStyle } from "./XCard4.style";

const XCard4 = ({ content, secondaryArgs }) => {
  const classes = useCustomStyle();

  const onClickCard = (e: any, id: string) => {
    e.preventDefault && e.preventDefault();
    if (typeof window !== "undefined") {
      const url = onClickCardUrlNavigate(id, content, secondaryArgs);
      window.open(url, "_self");
    }
  };

  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;

  return (
    <Box className={`${classes.XCard4Wrapper} XCard4Box`}>
      <Box className='XCard4innderBox'>
        <Box className='imgWrapper'>
          <Box className='IconTopRight'>
            <img
              src={getIcon(
                content?.ContentType,
                content?.Content_icon,
                secondaryArgs?.gcpUrl,
                secondaryArgs?.bucketName,
              )}
              alt='icon'
              height={25}
              width={25}
            />
          </Box>
          <CardMedia
            component={imageUrl ? "img" : "div"}
            image={imageUrl ? imageUrl : ""}
            title={imageUrl ? content?.Thumbnail?.AltText : "image"}
            sx={{ backgroundColor: color ? color : "", height: "100%", width: "100%" }}
          />
          <Box className='mainContentBox'>
            <Box className='InnerWrapper'>
              <Box className='contentWrapperBox'>
                <Typography variant='h4bold'>{content?.Title}</Typography>
                <Box className='dateWrapper'>
                  <Box className='iconWrapper'>
                    <AccessTimeIcon className='accessTime' />
                  </Box>
                  <Typography variant='p4medium' className='publishTime'>
                    {PlatFormXDateTimeFormat(content?.PublishedDate)}
                  </Typography>
                </Box>
              </Box>
              <Box className='ButtonWrapper'>
                <Button
                  variant='primaryButton2'
                  onClick={(e) => onClickCard(e, content?.EditorialItemPath)}>
                  View
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default XCard4;
