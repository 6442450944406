import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import {
  PlatFormXDateTimeFormat,
  getImage,
  handleHtmlTags,
  handleLogout,
  onClickCardUrlNavigate,
} from "@platformx/utilities";
import axios from "axios";
import { useEffect, useState } from "react";
import { useClickImpression } from "../../components/ImpressionHooks/ClickImpressionHook";
import { getIcon } from "../../components/Utils/helperFns";

const DynamicPrelemCard2 = ({ content, secondaryArgs, analytics, cardIndex }) => {
  const { triggerClickAnalytics } = useClickImpression();
  const [modalStatus, setModalStatus] = useState(false);
  const [, setSliderData] = useState([]);
  const styles = `
    .doublebr {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
     }
     .oneline {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
       }
     .threeline {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
       }
     `;
  useEffect(() => {
    if (
      content?.EditorialItemPath &&
      (content.ContentType === "ImageGallery" ||
        content.ContentType === "VideoGallery" ||
        content.ContentType === "Gallery")
    ) {
      axios
        .get(
          `${secondaryArgs?.prelemBaseEndpoint?.deliveryEndPoint}api/v1/web/en/delivery/multi-slot-content?path=${content?.EditorialItemPath}&contentType=${content.ContentType}&documentType=hclplatformx:SiteComponentImageVideoGallery`,
          {
            headers: {
              sitename: secondaryArgs?.sitename,
            },
          },
        )
        .then((res: any) => {
          if (res) {
            let gallery = [];
            if (content.ContentType === "ImageGallery") {
              gallery = res?.data?.data?.fetchMultiSlotContent?.Gallery.map((x: any) => x.Image);
            } else if (content.ContentType === "VideoGallery") {
              gallery = res?.data?.data?.fetchMultiSlotContent?.Gallery.map((x: any) => x.Video);
            } else if (content.ContentType === "Gallery") {
              gallery = res?.data?.data?.fetchMultiSlotContent?.Gallery.map((x: any) => x);
            }
            setSliderData(gallery);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("logout...from axios");
            handleLogout();
          }
        });
    }
  }, []);

  const toggleModalStatus = () => {
    if (!secondaryArgs?.editState) setModalStatus(!modalStatus);
  };

  const onClickCard = (e: any, id: string) => {
    if (secondaryArgs.editState) {
      e.preventDefault();
    } else {
      if (typeof window !== "undefined") {
        const url = onClickCardUrlNavigate(id, content, secondaryArgs);
        triggerClickAnalytics(
          url,
          cardIndex,
          analytics,
          secondaryArgs,
          content?.Title,
          content?.ContentType,
        );
        if (["ImageGallery", "VideoGallery", "Gallery"].includes(content.ContentType)) {
          toggleModalStatus();
        } else if (url) {
          window.open(url, "_self");
        }
      }
    }
  };

  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;

  return (
    <>
      <style>{styles}</style>
      <Box
        sx={{
          "&:hover": {
            ".button-name": {
              display: secondaryArgs?.editState ? "none" : "block",
            },
          },
        }}
        className='overlay-wrapper'>
        <Card
          className='cardContentBox'
          onClick={(e) => onClickCard(e, content?.EditorialItemPath)}>
          <Box
            className='imgBox'
            sx={{
              height: "100% !important",
            }}>
            <CardMedia
              sx={{
                height: "100%",
                backgroundColor: color ? color : "",
              }}
              image={imageUrl ? imageUrl : ""}
              component={imageUrl ? "img" : "div"}
            />
          </Box>
          <CardContent className='card2Content'>
            <Box className='card2ContentInnerWrapper'>
              <img
                alt='DynamicPrelemCard2'
                src={getIcon(
                  content?.ContentType,
                  content?.Content_icon,
                  secondaryArgs?.gcpUrl,
                  secondaryArgs?.bucketName,
                )}
                style={{ width: "100%", filter: "brightness(100)" }}
                width={25}
                height={25}
              />
            </Box>
            <Box>
              <Typography variant='h4semibold' className='cardTitle' color='textColor'>
                {content?.Title}
              </Typography>
              <Typography variant='p3regular' className='cardDescription' color='textColor'>
                {handleHtmlTags(content?.Description)}
              </Typography>
            </Box>
            <Box className='card2bottomButtonBox'>
              <Box className='adminDatdWrapper'>
                <Typography variant='p3semibold' color='textColor'>
                  {content?.Author?.trim() || content?.PublishedBy}
                </Typography>
                <Typography variant='p4regular' color='textColor'>
                  {PlatFormXDateTimeFormat(content?.PublishedDate)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default DynamicPrelemCard2;
