import { Box, Grow, TableContainer, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DropDown from "../../../DropDown/Dropdown";
import {
  EXCLUDE_TRANSACTION,
  getInitials,
  getLeaderBoard,
  getUserCampaignList,
  monthNames,
} from "../../helperProfile";
import { useCustomStyle } from "./LeaderBoard.style";
import SkeletonTable from "./SkeletonTable";

interface Leader {
  email: string;
  userName: string;
  rank: number;
  totalPoints: number;
  isCurrentUser: boolean;
}

const LeaderBoard = ({ secondaryArgs, profile }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const [slectedEvent, setSelectedEvent] = useState<any>({
    campaignName: "",
    campaignId: "",
  });
  const [eventList, setEventList] = useState<any>([]);
  const [leaderBoard, setLeaderBoard] = useState<Leader[]>([]);
  const [loading, setLoading] = useState(true);
  const [isEventLoaded, setIsEventLoaded] = useState(false); // Add a state to track if events are loaded

  const handleChange = (event) => {
    setLoading(true);
    const selectedValue = event?.target?.value;
    const selectedEventObj = eventList?.find((item) => item.name === selectedValue);
    if (selectedEventObj) {
      setSelectedEvent({
        campaignName: selectedEventObj.name,
        campaignId: selectedEventObj.value,
      });
    }
  };

  const getLeaderBoardApi = async () => {
    if (profile?.email && slectedEvent?.campaignId) {
      try {
        const res = await getLeaderBoard({
          secondaryArgs: secondaryArgs,
          userEmail: profile?.email,
          campaignId: slectedEvent?.campaignId,
        });
        const { data: { data: { users_getLeaderBoardList = [] } = {} } = {} } = res;
        if (users_getLeaderBoardList?.length > 0) {
          setLeaderBoard(users_getLeaderBoardList);
        } else {
          setLeaderBoard([]);
        }
      } catch (error) {
        console.error("Error fetching leaderboard:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getChallangeApi = async () => {
    const res = await getUserCampaignList({
      secondaryArgs: secondaryArgs,
      isLeaderBoard: true,
    });
    const { data: { data: { users_getCampaignList = [] } = {} } = {} }: any = res || {};
    if (users_getCampaignList && users_getCampaignList.length > 0) {
      const transformedArray = [
        ...users_getCampaignList.map((item) => ({
          name: item?.campaignName,
          value: item?.campaignId,
        })),
      ];
      const filteredArray = transformedArray?.filter(
        (item) => !EXCLUDE_TRANSACTION?.includes(item.campaignName),
      );
      setEventList(filteredArray);
      setSelectedEvent({
        campaignName: filteredArray?.[0]?.name,
        campaignId: filteredArray?.[0]?.value,
      });
      setIsEventLoaded(true);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEventLoaded) {
      getLeaderBoardApi();
    }
  }, [profile?.email, slectedEvent?.campaignId, isEventLoaded]);

  useEffect(() => {
    profile?.email && getChallangeApi();
  }, [profile?.email]);

  const userIndex = leaderBoard?.findIndex((leader) => leader?.email === profile?.email);
  const getHighlighted = (index) => {
    return userIndex > 3 && index === 3;
  };

  return (
    <Box className={`${classes.leaderBoard} leaderBoardSection gap`}>
      <Box display={"flex"}>
        <Typography variant='h4medium' className='noTopMargin'>
          {`${monthNames[new Date().getMonth()]}. ${t("event_leaderboard")}`}
        </Typography>
        {eventList && eventList?.[0]?.name && (
          <DropDown
            sx={{ maxWidth: "auto" }}
            label={eventList?.[0]?.name}
            name='eventName'
            arrayData={eventList}
            isDisabled={eventList?.length === 0}
            parentHandler={handleChange}
            stateValue={slectedEvent?.campaignName}
            cssClass='input-control-selectbox'
          />
        )}
      </Box>
      {loading ? (
        <SkeletonTable />
      ) : (
        <TableContainer>
          <Table aria-label='custom table'>
            <TableBody className='leaderBoardTable'>
              {leaderBoard?.map((leader, index) => (
                <Grow in={true} timeout={1000 * index} key={index}>
                  <TableRow
                    key={leader?.email}
                    className={getHighlighted(index) ? "lineSeprator" : ""}>
                    <TableCell width={"50px"} sx={{ padding: 1 }}>
                      <Avatar
                        className='avtarName'
                        alt={leader?.userName}
                        sx={{ width: 40, height: 40 }}>
                        {getInitials(leader?.userName, "")}
                      </Avatar>
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      <Typography variant='h5medium'> {leader.userName}</Typography>
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      <Typography variant='h5medium'> {leader.rank}</Typography>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }} align='right'>
                      <Chip
                        label={
                          <Typography variant='p4semibold' className='points'>
                            {leader.totalPoints}
                          </Typography>
                        }
                        className='customChip'></Chip>
                    </TableCell>
                  </TableRow>
                </Grow>
              ))}
              {leaderBoard?.length === 0 && (
                <TableRow>
                  <TableCell sx={{ padding: 1 }}> No Data Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default LeaderBoard;
