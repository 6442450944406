import { Box, Typography } from "@mui/material";
import { CarttonProfile1, CarttonProfile2 } from "@platformx/shared/static-assets";
import { formatPoints } from "../../helperProfile";
import LoyaltyProgressBar from "../LoyaltyProgressBar/LoyaltyProgressBar";
import { useCustomStyle } from "./ProfileBanner.style";
import ProfileBannerSkelton from "./ProfileBannerSkelton";

const ProfileBanner = ({ userOlData, secondaryArgs, profile }) => {
  const classes = useCustomStyle();
  const getFullName = () => {
    return `${userOlData?.userProfileInfo?.firstName} ${userOlData?.userProfileInfo?.lastName}`;
  };
  const getAvatar = () => {
    return profile?.gender?.toLowerCase() === "female" ? CarttonProfile2 : CarttonProfile1;
  };

  return (
    <Box className={`${classes.profileBannerSection} profileBannerWrapper`}>
      {/* Left Image Section */}
      <Box className='leftImg'>
        <Box component='img' src={getAvatar()} alt='Image' />
      </Box>
      {/* Right Text Section */}
      <Box className='rightPanel'>
        <Box sx={{ minHeight: "160px" }}>
          {userOlData ? (
            <>
              <Typography variant='h4regular' color='textColor' className='noBottomMargin'>
                Hi,
              </Typography>
              <Typography variant='h1medium' color='textColor' className='noMargin'>
                {getFullName()}
              </Typography>
              <Box mt={2} mb={2} width={"100%"} className='keyHighlight'>
                {/* <Grid container> */}
                {/* First Column */}
                <Box className='columnItem'>
                  <Typography variant='p4regular' color='textColor' className='noMargin'>
                    Total Pts
                  </Typography>
                  <Typography variant='h3bold' color='textColor' className='noMargin'>
                    {userOlData?.userPointsInfo?.totalEarnedPoints
                      ? formatPoints(userOlData.userPointsInfo.totalEarnedPoints)
                      : 0}
                  </Typography>
                </Box>
                <Box className='columnItem'>
                  <Typography variant='p4regular' color='textColor' className='noMargin'>
                    This Month
                  </Typography>
                  <Typography variant='h3bold' color='textColor' className='noMargin'>
                    {userOlData?.userPointsInfo?.thisMonth
                      ? formatPoints(userOlData.userPointsInfo.thisMonth)
                      : 0}
                  </Typography>
                </Box>
                <Box className='columnItem'>
                  <Typography variant='p4regular' color='textColor' className='noMargin'>
                    Last Month
                  </Typography>
                  <Typography variant='h3bold' color='textColor' className='noMargin'>
                    {userOlData?.userPointsInfo?.lastMonth
                      ? formatPoints(userOlData.userPointsInfo.lastMonth)
                      : 0}
                  </Typography>
                </Box>

                {/* Second Column */}
                <Box className='columnItem' pl={2}>
                  <Typography variant='p4regular' color='textColor' className='noMargin'>
                    Active Pts
                  </Typography>
                  <Typography variant='h3bold' color='textColor' className='noMargin'>
                    {userOlData?.userPointsInfo?.activePoints
                      ? formatPoints(userOlData.userPointsInfo.activePoints)
                      : 0}
                  </Typography>
                </Box>

                {/* Third Column */}
                <Box className='columnItem' pl={2}>
                  <Typography variant='p4regular' color='textColor' className='noMargin'>
                    Spent Pts
                  </Typography>
                  <Typography variant='h3bold' color='textColor' className='noMargin'>
                    {userOlData?.userPointsInfo?.spentPoints
                      ? formatPoints(userOlData.userPointsInfo.spentPoints)
                      : 0}
                  </Typography>
                </Box>
                {/* </Grid> */}
              </Box>
            </>
          ) : (
            <ProfileBannerSkelton />
          )}
        </Box>
        <LoyaltyProgressBar secondaryArgs={secondaryArgs} memberId={profile?.member_id} />
      </Box>
    </Box>
  );
};

export default ProfileBanner;
