import { Box, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { formCroppedUrlString } from "@platformx/utilities";
import { useInView } from "react-intersection-observer";
import ContentReplace from "../../components/ContentReplace/ContentReplace";
import { useClickImpression } from "../../components/ImpressionHooks/ClickImpressionHook";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import prelemTypes from "../../globalStyle";
import "../../Style.css";
import useContentData from "../../utils/DynamicSchema/useContentData";
import { useCustomStyle } from "./ServiceCard3.style";

const ServiceCard3 = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  usePrelemImpression(analytics, inView, secondaryArgs);
  const { triggerClickAnalytics } = useClickImpression();
  const { contentValue, loader } = useContentData(content, secondaryArgs);
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.serviceCard3Wrapper} ${globalClasses.prelemType1} prelem prelemType1 serviceCard3WrapperBg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? `grid_full_width prelem-py` : `grid_container prelem-py`
        }
        ref={ref}>
        <Typography variant='h2semibold' textAlign='center' mb={3} id='title'>
          {content?.title}
        </Typography>
        {loader ? (
          <Box className='skeletonbox' sx={{ width: "100%", height: "100%", minHeight: "500px" }}>
            <SkeletonLoader />
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
              "&:hover": {
                ".add-content-overlay": {
                  display: authoringHelper?.authoringHoverShow ? "flex !important" : "none",
                },
              },
            }}>
            <Grid container item className='gridMainWrapper'>
              {contentValue &&
                Object.entries(contentValue).map(([key, value]: [string, any], index) => (
                  <Grid
                    item
                    key={key}
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    justifyContent='center'
                    alignItems='center'
                    style={{
                      paddingBottom: parseInt(key) === 0 ? "30px" : "0px",
                    }}>
                    <Box
                      onClick={() => {
                        if (value?.URL) {
                          triggerClickAnalytics(
                            value?.URL,
                            index,
                            analytics,
                            secondaryArgs,
                            content?.Slots && content?.Slots[index]?.Title,
                            "Service Card",
                          );
                          if (value?.Internal) {
                            window.location.assign(value.URL);
                          } else {
                            window.open(value.URL, "_self");
                          }
                        }
                      }}
                      sx={{
                        cursor: value?.URL ? "pointer" : "auto",
                      }}
                      className='ServiceCardWrapperBox'>
                      <Box className='imageContentWrapper'>
                        <CardMedia
                          component='img'
                          image={
                            formCroppedUrlString(
                              secondaryArgs?.gcpUrl,
                              secondaryArgs?.bucketName,
                              value?.serviceicon?.original_image?.original_image_relative_path,
                              value?.serviceicon?.original_image?.ext,
                            ).src
                          }
                          sx={{
                            width: "60px",
                            height: "60px",
                            objectFit: "contain",
                          }}
                          alt={value?.IconImage?.AltText}
                        />
                        {/* <ImageRender
                          originalImage={value?.serviceicon?.original_image}
                          publishedImages={value?.serviceicon?.published_images}
                          secondaryArgs={secondaryArgs}
                          imgOrder={{
                            1440: "square",
                            1280: "square",
                            1024: "square",
                            768: "square",
                            600: "square",
                            320: "square",
                          }}
                          index={0}
                          isEditing={authoringHelper?.isEditing}
                        /> */}
                      </Box>
                      <CardContent className='ServiceCard3CardContent'>
                        <Typography gutterBottom variant='p3regular'>
                          {value?.title}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Grid>
                ))}
            </Grid>
            <ContentReplace
              onReplaceClick={() => secondaryArgs?.multiSlot?.onToggleContentGallery()}
            />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default ServiceCard3;
