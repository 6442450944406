import { getCustomEventSchemaList, getRpiInstance } from "./helper";

/**
 * Sends the event details to the RPI (Real-time Processing Interface) for tracking.
 *
 * @param {Object} eventDetail - The event detail object containing event information.
 */
export const trackImpression = (eventDetail, secondaryArgs, template) => {
  if (secondaryArgs?.prelemBaseEndpoint?.allowedAnalyticsProviders?.includes("RPI"))
    if (getRpiInstance()) {
      globalThis.rpiWebClient.pushWebEvent(
        eventDetail?.eventName, // Event name
        eventDetail.stateValue, // Quantity or other relevant parameter
        eventDetail?.extraParam, // Event description
        eventDetail?.contentId, // Optional content ID or other identifier
        eventDetail?.data, // Pass the event data object
      );
      getCustomEventSchemaList(eventDetail, secondaryArgs, template);
    }
};
