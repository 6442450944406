import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    ExpiringRewardWrapper: {
      "&.ExpiringRewardWrapperSection": {
        width: "100%",
        position: "relative",
        "& .expiringTable": {
          width: "100%",
          background: theme.palette.prelemType1.BACKGROUND,
          marginBottom: "16px",
          border: `solid 1px ${theme.palette.prelemType1.LINE}`,
          borderRadius: theme?.borderRadius.value1,
          "& .tabelheading": {
            background: theme.palette.prelemType1.ACCENTS.VARIANT1.BACKGROUND,
            "& .Mui-active": {
              "& svg": {
                color: theme.palette.prelemType1.ACCENTS.VARIANT1.TITLE,
              },
            },
            "& svg": {
              color: theme.palette.prelemType1.ACCENTS.VARIANT1.PARAGRAPH,
            },
            "& .title": {
              color: theme.palette.prelemType1.ACCENTS.VARIANT1.TITLE,
            },
          },
          "& .expiringsoon": {
            color: theme.palette.prelemType1.LINK,
          },
        },
        "& .circularLoader": {
          top: 0,
          left: 0,
          minHeight: "500px",
          height: "100%",
          position: "absolute",
          background: "rgba(0, 0, 0, .04)",
          width: "100%",
          "& .circularLoaderWrapper": {
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          },
          "& .innerLoader": {
            width: "60px",
            height: "60px",
            padding: "10px",
            borderRadius: "7px",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          },
        },
        "& .paginationwrapper": {
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px",
        },
        "& .icons": {
          width: "15px",
          height: "15px",
          color: theme.palette.prelemType1.TITLE,
        },
        "& .previous": {
          "& svg": {
            marginLeft: "3px",
          },
        },
        "& .Mui-disabled": {
          "& svg": {
            opacity: 0.5,
          },
        },
        "& .pagerText": {
          marginRight: "20px",
          marginTop: "4px",
          width: "200px",
          textAlign: "right",
        },
      },
    },
  };
});
