/* eslint-disable wrap-regex */
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import {
  Analytics,
  AuthoringHelper,
  SecondaryArgs,
  formCroppedUrlString,
  handleHtmlTags,
} from "@platformx/utilities";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import TwoColumnLayout from "../../components/layouts/TwoColumns/TwoColumnLayout";
import prelemTypes from "../../globalStyle";
import { useCustomStyle } from "./CoreHighlights.style";

// ts-ignore
const CoreHighlights = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs,
}: //secondaryArgs,
CoreHighlightsProp) => {
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();

  const firstRender = useRef(true);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const defaultStructureData = () => {
    let CoreHighlightsStructureData;
    const { original_image_relative_path, ext }: any =
      content?.ImageCompound?.ImageCompound_1?.original_image || {};
    const img = formCroppedUrlString(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    ).src;
    try {
      CoreHighlightsStructureData = {
        "@context": "https://schema.org/",
        "@type": "ContactPage",
        description: handleHtmlTags(content?.description),
        image: img,
        name: content?.title,
      };
    } catch (e) {
      CoreHighlightsStructureData = {};
    }

    return CoreHighlightsStructureData;
  };

  const generateStructureData = () => {
    let CoreHighlightsStructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current === true) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        CoreHighlightsStructureData = JSON.parse(tempSD);
      } else {
        CoreHighlightsStructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      CoreHighlightsStructureData = defaultStructureData();
    }
    return CoreHighlightsStructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = generateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.description,
    content?.ImageCompound?.ImageCompound_1?.original_image,
    content?.title,
  ]);

  usePrelemImpression(analytics, inView, secondaryArgs);

  /* AnalyticsEnabled dependency added as many times we are not getting analytics provider*async call)
  1. we are first checking in publish app if analytics provider is avaiable or not
  2. if its available we are setting AnalyticsEnabled to true
  3. if its not available we are setting false
*/
  const gridVal = {
    md: [12, 12],
    em: [6, 6],
    lg: [6, 6],
  };
  const firstColumnContent = () => {
    return (
      <Box className='leftImgBox'>
        <Box className='middleBoxWp'>
          <Typography variant='h1largebold' color='secondaryLabel' id='coretitle'>
            {content.coretitle}+
          </Typography>
          <Typography variant='p3bold' color='secondaryLabel' id='coredescription'>
            {content.coredescription}
          </Typography>
        </Box>
        <Box className='widthheight100'>
          <Grid container>
            <Grid xs={6} className='spRight'>
              <Box className='ImgBox'>
                <Box className='ImgBoxinner'>
                  <ImageRender
                    originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
                    publishedImages={content?.ImageCompound?.ImageCompound_1?.published_images}
                    secondaryArgs={secondaryArgs}
                    imgOrder={{
                      1440: "landscape",
                      1280: "landscape",
                      1024: "landscape",
                      768: "landscape",
                      600: "landscape",
                      320: "landscape",
                    }}
                  />
                </Box>
                <Box className='ImgBoxinner'>
                  <ImageRender
                    originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
                    publishedImages={content?.ImageCompound?.ImageCompound_2?.published_images}
                    secondaryArgs={secondaryArgs}
                    imgOrder={{
                      1440: "square",
                      1280: "square",
                      1024: "square",
                      768: "square",
                      600: "square",
                      320: "square",
                    }}
                    sx={{ height: "calc(100% - 10px)" }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} className='spLeft'>
              <Box className='ImgBox'>
                <Box className='ImgBoxinner'>
                  <ImageRender
                    originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
                    publishedImages={content?.ImageCompound?.ImageCompound_3?.published_images}
                    secondaryArgs={secondaryArgs}
                    imgOrder={{
                      1440: "square",
                      1280: "square",
                      1024: "square",
                      768: "square",
                      600: "square",
                      320: "square",
                    }}
                    sx={{ height: "calc(100% - 10px)" }}
                  />
                </Box>
                <Box className='ImgBoxinner'>
                  <ImageRender
                    originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
                    publishedImages={content?.ImageCompound?.ImageCompound_4?.published_images}
                    secondaryArgs={secondaryArgs}
                    imgOrder={{
                      1440: "landscape",
                      1280: "landscape",
                      1024: "landscape",
                      768: "landscape",
                      600: "landscape",
                      320: "landscape",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };
  const secondColumnContent = () => {
    return (
      <Box className='rightCol'>
        <Typography
          variant='p4semibold'
          color='secondaryLabel'
          id='subtitle'
          textTransform={"uppercase"}>
          {`// ${content.subtitle}`}
        </Typography>
        <Typography variant='h2semibold' id='title'>
          {content.title}
        </Typography>
        <Typography variant='p3regular' id='subdescription' className='leftborder'>
          {content.subdescription}
        </Typography>
        <Typography variant='p3regular' id='description'>
          {content.description}
        </Typography>
        <Divider variant='fullWidth' />
        <Box className='bootomTextContent'>
          <Box className='imgBoxIcon'>
            <LocalPhoneOutlinedIcon sx={{ fontSize: "47px" }} />
          </Box>
          <Box className='rightContent'>
            <Typography variant='p2regular' id='contacttitle' m={0}>
              {content.contacttitle}
            </Typography>
            <Typography variant='h3bold' id='contactdescription' mt={0.5}>
              {content.contactdescription}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.CoreHighlightsWrapper} ${globalClasses.prelemType1} prelem prelemType1 CoreHighlightsBg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }
        ref={ref}>
        <Box className='CoreHighlights'>
          <TwoColumnLayout
            firstColumnContent={firstColumnContent()}
            secondColumnContent={secondColumnContent()}
            customClassName='CoreHighlights'
            gridVal={gridVal}
            noGap={true}
          />
        </Box>
      </Container>
    </div>
  );
};

interface CoreHighlightsProp {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Content {
  subtitle?: string;
  title?: string;
  subdescription?: string;
  description?: string;
  contacttitle?: string;
  contactdescription?: string;
  coretitle?: string;
  coredescription?: string;
  TagName?: string;
  ImageCompound: {
    ImageCompound_1: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_2: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_3: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_4: {
      published_images: Image[];
      original_image?: object;
    };
  };
}
interface Image {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

export default CoreHighlights;
