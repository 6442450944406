import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    deliveryAddressWrapper: {
      "&.deliveryAddressDetail": {
        marginBottom: "16px",
        "& .OrderShippingDetail": {
          border: `solid 1px ${theme.palette.prelemType1.LINE}`,
          padding: "16px",
          "& .billingAddress": {
            marginTop: "16px",
            borderTop: `solid 1px ${theme.palette.prelemType1.LINE}`,
            paddingTop: "16px",
          },
          "& .subheading": {
            textTransform: "none",
            paddingBottom: "4px",
          },
        },
      },
    },
  };
});
