import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    userProfilePage: {
      "&.userProfileSection": {
        background: theme.palette.prelemType1.BACKGROUND,
        "& .mainContainerSection": {
          display: "block",
          [theme.breakpoints.up("em")]: {
            display: "flex",
          },
        },
        // "& .leftSection": {
        //   paddingRight: "8px",
        // },
        // "& .rightSection": {
        //   paddingLeft: "8px",
        // },
        "& .noMargin": {
          margin: 0,
          "& p": {
            marginTop: "0px",
            marginBottom: "4px",
          },
        },
        "& .noTopMargin": {
          marginTop: 0,
        },
        "& .noBottomMargin": {
          marginBottom: 0,
        },
        "& .labelColor": {
          color: `${theme.palette.prelemType1.LABEL}`,
        },
        "& .lessGap": {
          marginBottom: "6px",
        },
        "& .gap": {
          padding: "20px",
        },
        "& .ellipsis-two-lines": {
          display: "-webkit-box" /* Required for limiting text to a specific number of lines */,
          "-webkit-line-clamp": 2 /* Limits the text to 2 lines */,
          "-webkit-box-orient": "vertical" /* Required property for line clamping */,
          overflow: "hidden" /* Ensures the overflowing text is hidden */,
          textOverflow: "ellipsis" /* Shows the ellipsis (...) for overflow */,
        },
        "& .one-line-ellipsis": {
          display: "-webkit-box" /* Required for limiting text to a specific number of lines */,
          "-webkit-line-clamp": 1 /* Limits the text to 2 lines */,
          "-webkit-box-orient": "vertical" /* Required property for line clamping */,
          overflow: "hidden" /* Ensures the overflowing text is hidden */,
          textOverflow: "ellipsis" /* Shows the ellipsis (...) for overflow */,
        },
        "& .profileDetail": {
          [theme.breakpoints.up("em")]: {
            padding: "0px 0px 0 20px",
            maxWidth: `calc(100% - 290px)`,
            paddingRight: 0,
          },

          "& .heading": {
            marginTop: 0,
            [theme.breakpoints.up("sm")]: {
              marginTop: 0,
            },
          },
        },
      },
    },
  };
});
