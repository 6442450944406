import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { StartRewardIcon } from "@platformx/shared/static-assets";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphQlEcommerceList } from "../../../../graphql/Ecommerce/fetch";
import ToastService from "./../../../../components/ToastContainer/ToastService";

const OrderRewards = ({ secondaryArgs }) => {
  const [loading, setLoading] = useState(false);
  const [coins, setCoins] = useState(0);
  const { t } = useTranslation();
  const onViewClick = () => {
    window.location.href = `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/user/profile?tab=1`;
  };

  const getTransactionalRewards = async () => {
    const url = new URL(window.location.href);
    const orderNumber = url.searchParams.get("order_number");
    try {
      setLoading(true);
      const {
        prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {},
        sitename = "",
      } = secondaryArgs;
      const { errors, data } = await graphQlEcommerceList.fetchTransactionalRewards(
        {
          documentId: orderNumber,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      const { users_getTransactionRewards = {} } = data;
      if (errors) {
        ToastService.failToast(t("errorRequest"));
      } else {
        const points = users_getTransactionRewards?.pointsList?.reduce(
          (acc, item) => acc + item.points,
          0,
        );
        if (points > 0) setCoins(points);
      }
    } catch {
      ToastService.failToast(t("errorRequest"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactionalRewards();
  }, []);

  return (
    <Box className='orderRewardColumn'>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* Left Side - Your Rewards */}
        <Typography variant='h4medium' color='tertiaryTitle' className='noTopMargin'>
          {t("your_rewards")}
        </Typography>

        {/* Right Side - Next with Arrow */}
        <Box
          display='flex'
          alignItems='center'
          className='viewAll'
          color='tertiaryAnchorLink'
          onClick={onViewClick}>
          <Typography variant='p4medium' component='span' color='tertiaryAnchorLink'>
            {t("view_all")}
          </Typography>
          <KeyboardArrowRightIcon />
        </Box>
      </Box>
      {/* Left side - Loyalty */}
      <Box sx={{ display: "flex" }}>
        <Grid item>
          <Box className='starIcon'>
            <img
              src={StartRewardIcon}
              alt='Rewards'
              width='30'
              height='30'
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </Grid>

        {/* Right side - Text */}
        <Grid item xs>
          <Box ml={2}>
            {loading ? (
              <Skeleton width='80%' height={22} />
            ) : (
              <Typography variant='p4medium' className='noMargin'>
                {coins > 0
                  ? `${coins} ${coins <= 1 ? "Coin" : "Coins"} ${t("coins_earn_with_this_purchase")},`
                  : "You didn't earn Coins with this purchase."}
              </Typography>
            )}
            {loading ? (
              <Skeleton width='80%' height={22} />
            ) : coins > 0 ? (
              <Typography variant='p4regular' className='noMargin labelColor'>
                {t("it_will_be_uploaded_after_the_delivery")}.
              </Typography>
            ) : null}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderRewards;
