import graphQlList from "../../graphql/fetch";

const getContentData = async (content, secondaryArgs) => {
  const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "" } = {}, sitename } =
    secondaryArgs;
  const { data, errors } = await graphQlList.fetchContentData(
    {
      input: {
        content: content?.selected_items,
      },
    },
    language,
    sitename,
    deliveryEndPointGq,
  );
  if (data?.publish_getContentData) {
    return data.publish_getContentData;
  } else {
    console.error("ContentAPIFailed", errors);
    return null;
  }
};

export default getContentData;
