import getGraphQlInstance from "../graphqlConfig";
import {
  GET_ORDER_DETAILS,
  GET_ORDER_HISTORY,
  GET_PRODUCT_DETAILS,
  GET_TRANSACTIONAL_REWARDS,
} from "./queries";

export const graphQlEcommerceList = {
  fetchProductDetails: async <T>(input: T, lang, host, endPoint): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: GET_PRODUCT_DETAILS,
      variables: input,
      fetchPolicy: "network-only",
    });
    return { loading, errors, data };
  },
  fetchOrderDetails: async <T>(input: T, lang, host, endPoint): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: GET_ORDER_DETAILS,
      variables: input,
      fetchPolicy: "network-only",
    });
    return { loading, errors, data };
  },
  fetchOrderHistoryData: async <T>(input: T, lang, host, endPoint): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: GET_ORDER_HISTORY,
      variables: input,
      fetchPolicy: "network-only",
    });
    return { loading, errors, data };
  },
  fetchTransactionalRewards: async <T>(input: T, lang, host, endPoint): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: GET_TRANSACTIONAL_REWARDS,
      variables: input,
      fetchPolicy: "network-only",
    });
    return { loading, errors, data };
  },
};
