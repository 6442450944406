/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import {
  getImage,
  handleHtmlTags,
  handleLogout,
  onClickCardUrlNavigate,
  PlatFormXDateTimeFormat,
} from "@platformx/utilities";
import axios from "axios";
import { useEffect, useState } from "react";
import { useClickImpression } from "../../components/ImpressionHooks/ClickImpressionHook";
import { getIcon } from "../../components/Utils/helperFns";

const BlogTilesCard2 = ({ content, secondaryArgs, analytics, cardIndex }) => {
  const { triggerClickAnalytics } = useClickImpression();
  const [modalStatus, setModalStatus] = useState(false);
  const [, setSliderData] = useState([]);
  useEffect(() => {
    if (
      content?.EditorialItemPath &&
      (content.ContentType === "ImageGallery" ||
        content.ContentType === "VideoGallery" ||
        content.ContentType === "Gallery")
    ) {
      axios
        .get(
          `${secondaryArgs?.prelemBaseEndpoint?.deliveryEndPoint}api/v1/web/en/delivery/multi-slot-content?path=${content?.EditorialItemPath}&contentType=${content.ContentType}&documentType=hclplatformx:SiteComponentImageVideoGallery`,
          {
            headers: {
              sitename: secondaryArgs?.sitename,
            },
          },
        )
        .then((res: any) => {
          if (res) {
            let gallery = [];
            if (content.ContentType === "ImageGallery") {
              gallery = res?.data?.data?.fetchMultiSlotContent?.Gallery.map((x: any) => x.Image);
            } else if (content.ContentType === "VideoGallery") {
              gallery = res?.data?.data?.fetchMultiSlotContent?.Gallery.map((x: any) => x.Video);
            } else if (content.ContentType === "Gallery") {
              gallery = res?.data?.data?.fetchMultiSlotContent?.Gallery.map((x: any) => x);
            }
            setSliderData(gallery);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("logout...from axios");
            handleLogout();
          }
        });
    }
  }, []);

  const toggleModalStatus = () => {
    if (!secondaryArgs?.editState) setModalStatus(!modalStatus);
  };

  const onClickCard = (e: any, id: string) => {
    if (secondaryArgs.editState) {
      e.preventDefault();
    } else {
      if (typeof window !== "undefined") {
        const url = onClickCardUrlNavigate(id, content, secondaryArgs);
        triggerClickAnalytics(
          url,
          cardIndex,
          analytics,
          secondaryArgs,
          content?.Title,
          content?.ContentType,
        );
        if (["ImageGallery", "VideoGallery", "Gallery"].includes(content.ContentType)) {
          toggleModalStatus();
        } else if (url) {
          window.open(url, "_self");
        }
      }
    }
  };

  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;

  return (
    <Box
      sx={{
        "&:hover": {
          ".button-name": {
            display: secondaryArgs?.editState ? "none" : "block",
          },
        },
      }}
      className='overlay-wrapper blogTilesCardWrapper blogTilesCard2Type2'>
      <Card
        className='blogTilesCard noShadow'
        onClick={(e) => onClickCard(e, content?.EditorialItemPath)}>
        <Grid container className='alignText'>
          <Grid xs={12} md={6}>
            <CardMedia
              className='blogTilesCardMedia1'
              sx={{
                backgroundColor: color ? color : "",
                height: {
                  xs: "204px",
                  sm: "382px",
                  md: "330px",
                  lg: "479px",
                },
              }}
              image={imageUrl ? imageUrl : ""}>
              <Box className='cardOverlay1'>
                <Box className='contentIcons1'>
                  <img
                    alt='BlogTilesCard2Img'
                    src={getIcon(
                      content?.ContentType,
                      content?.Content_icon,
                      secondaryArgs?.gcpUrl,
                      secondaryArgs?.bucketName,
                    )}
                    className='fullwidth'
                    width={25}
                    height={25}
                  />
                </Box>
              </Box>
            </CardMedia>
          </Grid>
          <Grid xs={12} md={6}>
            <CardContent className='blogTilesCardContent extraGap'>
              <Typography variant='h2medium' className='threeline title'>
                {content.Title}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography variant='p4bold' component='div' className='publishedBy marginZero'>
                  {content?.Author?.trim() || content?.PublishedBy}
                </Typography>
                <Typography variant='p4regular' className='publishedBy gap marginZero'>
                  {PlatFormXDateTimeFormat(content?.PublishedDate)}
                </Typography>
              </Box>
              <Typography variant='p3regular' className='fiveline'>
                {" "}
                {handleHtmlTags(content.Description)}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default BlogTilesCard2;
