import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Container, Slide, Typography } from "@mui/material";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import ContentReplace from "../../components/ContentReplace/ContentReplace";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import prelemTypes from "../../globalStyle";
import useContentData from "../../utils/DynamicSchema/useContentData";
import "./ServiceCard4.css";
import { useCustomStyle } from "./ServiceCard4.style";

const ServiceCard4 = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const [activeSlide, setActiveSlide] = useState(1);

  const { inView, ref } = useInView({
    threshold: 0,
  });
  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <KeyboardBackspaceIcon />
        <Typography variant='h5regular'>
          {activeSlide > 9 ? "" : 0}
          {activeSlide}/{content?.selected_items?.length > 9 ? "" : 0}
          {content?.selected_items?.length}
        </Typography>
      </div>
    );
  };
  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <EastIcon />
      </div>
    );
  };

  const windowSettings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current: any) => setActiveSlide(current + 1),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  usePrelemImpression(analytics, inView, secondaryArgs);
  /* AnalyticsEnabled dependency added as many times we are not getting analytics provider*async call)
  1. we are first checking in publish app if analytics provider is avaiable or not
  2. if its available we are setting AnalyticsEnabled to true
  3. if its not available we are setting false
*/
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();

  const { contentValue, loader } = useContentData(content, secondaryArgs);

  return (
    <div
      className={`${classes.ServiceCard4Wrapper} ${globalClasses.prelemType1} prelem prelemType1 ServiceCard4`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }>
        {loader ? (
          <Box className='skeletonbox' sx={{ width: "100%", height: "100%", minHeight: "500px" }}>
            <SkeletonLoader />
          </Box>
        ) : (
          <Box
            ref={authoringHelper?.innerRef}
            className='ServiceCard4Slider'
            sx={{ minHeight: "500px" }}>
            <Box
              sx={{
                position: "relative",
                "&:hover": {
                  ".add-content-overlay": {
                    display: authoringHelper?.isEditing ? "flex !important" : "none",
                  },
                },
              }}
              ref={ref}>
              {contentValue && !loader ? (
                <Slider {...windowSettings}>
                  {contentValue?.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Box className='slider-wrapper'>
                          <Box className='contentWrapper'>
                            <Slide direction='left' in={true} timeout={1500}>
                              <Typography variant='h1semibold'>{item?.title}</Typography>
                            </Slide>
                            <Slide direction='right' in={true} timeout={1500}>
                              <Typography variant='h3semibold'>{item?.subtitle}</Typography>
                            </Slide>
                            <Slide direction='right' in={true} timeout={1800}>
                              <Typography variant='p3regular' id='Description'>
                                {item?.description}
                              </Typography>
                            </Slide>
                          </Box>
                          <Slide direction='left' in={true} timeout={1500}>
                            <Box className='imageWrapper' id='Image'>
                              {/* <img alt='card4' src={item?.Image_1?.Url || ""} /> */}
                              <ImageRender
                                originalImage={item?.serviceimage?.original_image}
                                publishedImages={item?.serviceimage?.published_images}
                                secondaryArgs={secondaryArgs}
                                imgOrder={{
                                  1440: "square",
                                  1280: "square",
                                  1024: "square",
                                  768: "square",
                                  600: "square",
                                  320: "square",
                                }}
                              />
                            </Box>
                          </Slide>
                        </Box>
                      </Box>
                    );
                  })}
                </Slider>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  {content?.selected_items?.length === 0 ? (
                    <Typography variant='p3regular'>
                      Choose Service Cards to view it here.
                    </Typography>
                  ) : null}
                </Box>
              )}
              <Box className='rightBgWrapper'></Box>
              <ContentReplace
                onReplaceClick={() => secondaryArgs?.multiSlot?.onToggleContentGallery()}
              />
            </Box>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default ServiceCard4;
