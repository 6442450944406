import { Grid, Skeleton, Stack } from "@mui/material";

export default function MyStorySkeleton({ count }) {
  return (
    <Grid container spacing={2}>
      {Array.from({ length: count }).map((_, index) => (
        <Grid item xs={12} lg={4} key={index}>
          <Stack spacing={1} paddingBottom='10px'>
            <Skeleton
              variant='rectangular'
              sx={{ height: "300px", width: "100%", borderRadius: "5px" }}
            />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
