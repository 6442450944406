import { useLazyQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { fetchPageListAll } from "@platformx/authoring-apis";
import { ContentListDesktopLoader, NoSearchResult } from "@platformx/utilities";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PageList from "./PageList";

export const PageListing = ({ selectedPage, onSelectPage }) => {
  const [startIndex, setStartIndex] = useState<number>(0);
  const [rows] = useState<number>(20);
  const [isLazyLoad, setIsLazyLoad] = useState<boolean>(false);
  const [isloading, setLoading] = useState(false);
  const searchPageUrl = new URL(window.location.href);
  const [runFetchPageList] = useLazyQuery(fetchPageListAll);
  const [sortOrder] = React.useState(
    searchPageUrl.searchParams.get("sortBy")
      ? (searchPageUrl.searchParams.get("sortBy") as string)
      : "DESC",
  );
  const [items, setItem] = useState<any>([]);

  const getData = (index) => {
    runFetchPageList({
      variables: {
        obj: { start: index, rows: rows },
        type: "PUBLISHED",
        sort: sortOrder,
        searchTerm: searchPageUrl.searchParams.get("searchTerm")
          ? searchPageUrl.searchParams.get("searchTerm")
          : "",
      },
    })
      .then((resp) => {
        if (resp?.data?.authoring_pageList?.length > 0 && items !== undefined) {
          const newData = [...items, ...resp.data.authoring_pageList];
          setItem(newData);
        }
        if (resp?.data?.authoring_pageList.length === 0) {
          setIsLazyLoad(false);
        }
      })
      .catch(() => {
        setItem([]);
        setIsLazyLoad(false);
      });
  };

  const fetchMoreData = () => {
    const nextIndex = startIndex + rows;
    setStartIndex(nextIndex);
    getData(nextIndex);
  };

  useEffect(() => {
    setLoading(true);
    runFetchPageList({
      variables: {
        obj: { start: 0, rows: rows },
        type: "PUBLISHED",
        sort: sortOrder,
        searchTerm: searchPageUrl.searchParams.get("searchTerm")
          ? searchPageUrl.searchParams.get("searchTerm")
          : "",
      },
    })
      .then((resp) => {
        setItem(resp?.data?.authoring_pageList);

        setLoading(false);
        if (resp?.data?.authoring_pageList.length < 10) {
          setIsLazyLoad(false);
        } else {
          setIsLazyLoad(true);
        }
      })
      .catch(() => {
        setItem([]);
        setIsLazyLoad(true);
      });
  }, []);

  return (
    <Box
      sx={{
        paddingTop: "15px",
        height: "calc(100vh - 70px)",
        overflowY: "scroll",
        overflowX: "hidden",
        paddingBottom: { xs: "150px", sm: "0" },
      }}
      id='scrollableDiv1'>
      <Box
        sx={{
          display: { xs: "none", sm: "block", padding: "0px 110px", width: "100%" },
        }}>
        {isloading ? (
          <ContentListDesktopLoader noPadding={true} />
        ) : !isloading && items?.length === 0 ? (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <NoSearchResult />
          </Box>
        ) : (
          <InfiniteScroll
            dataLength={items !== undefined ? items.length : 0}
            next={fetchMoreData}
            hasMore={isLazyLoad}
            loader={<ContentListDesktopLoader noPadding={true} />}
            scrollableTarget='scrollableDiv1'>
            {items?.map((item, index) => (
              <PageList
                key={index}
                item={item}
                index={index}
                selectedPage={selectedPage}
                onSelectPage={onSelectPage}
              />
            ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
};
