import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    leaderBoard: {
      "&.leaderBoardSection": {
        background: theme.palette.prelemType1.BACKGROUND,
        border: `solid 1px ${theme.palette.prelemType1.LINE}`,
        [theme.breakpoints.up("lg")]: {
          minHeight: "553px",
        },
        "& .avtarName": {
          textTransform: "uppercase",
        },
        "& .dropdown-wrapper.input-control-selectbox": {
          // maxWidth: "230px",
          "& #select-small": {
            padding: "8px",
            borderRadius: theme.borderRadius.value1,
          },
        },
        "& .leaderBoardTable": {
          "& td": {
            borderBottom: `solid 1px ${theme.palette.prelemType1.LINE}`,
          },
          "& tr": {
            "&:nth-child(1)": {
              background: `-webkit-linear-gradient(270.29deg, rgba(250, 240, 209, 0) 51.26%, #FBEDC0 158.84%)`,
              "& .customChip": {
                background: `linear-gradient(to right, #D7a437 0%, #D7a437  0%, #F2D878  100%)`,
              },
              "& .points": {
                color: theme.palette.textColor,
              },
            },
            "&:nth-child(2)": {
              background: `-webkit-linear-gradient(270.29deg, rgba(217, 217, 217, 0) 51.26%, #D9D9D9 158.84%)`,
              "& .customChip": {
                background: `linear-gradient(to right, #546269 0%, #546269  0%, #B5BDC1  100%)`,
              },
              "& .points": {
                color: theme.palette.textColor,
              },
            },
            "&:nth-child(3)": {
              background: `-webkit-linear-gradient(270.18deg, rgba(199, 129, 97, 0) 62.68%, #CA8666 288.59%);`,
              "& .customChip": {
                background: `linear-gradient(to right, #B05A3B 0%, #B05A3B  0%, #D59978  100%)`,
              },
              "& .points": {
                color: theme.palette.textColor,
              },
            },
            "&:nth-child(5)": {
              "& td": {
                // borderBottom: `solid 1px ${theme.palette.prelemType1.LABEL}`,
              },
            },
            "&:nth-last-child(1)": {
              "& td": {
                borderBottom: "none",
              },
            },
            "&.lineSeprator": {
              "& td": {
                borderBottom: `solid 1px ${theme.palette.prelemType1.LABEL}`,
              },
            },
          },
        },
      },
    },
  };
});
