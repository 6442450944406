import { Box, Grid } from "@mui/material";
import ActionButtons from "./ActionsButtons/ActionButtons";
import DeliveryAddressDetail from "./DeliveryAddressDetail/DeliveryAddressDetail";
import { useCustomStyle } from "./OrderDetail.style";
import OrderSummary from "./OrderSummary/OrderSummary";
import PaymentDetail from "./PaymentDetail/PaymentDetail";
import TrackingDetail from "./TrackingDetail/TrackingDetail";

const OrderDetail = () => {
  const classes = useCustomStyle();
  return (
    <Box className={`${classes.OrderDetailWrapper} OrderDetailWrapperSection`}>
      <Grid container>
        <Grid item xs={12} lg={8} className='leftSection'>
          <OrderSummary />
          <TrackingDetail />
        </Grid>
        <Grid item xs={12} lg={4} className='rightSection'>
          <ActionButtons />
          <DeliveryAddressDetail />
          <PaymentDetail />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderDetail;
